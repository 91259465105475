export interface memberListParams {
	agentName?: string;
	email?: string;
	role?: string;
	status?: string;
	pageNo: number;
	pageSize: number;
}

export interface updateMemberParams {
	email?: string;
	id?: string;
	role?: string;
}

export interface editAgentCapacityParams {
	id: string;
	receptionLimit?: number;
}

export interface activeConfirmParams {
	confirmCode: string;
}

export interface newUserRegisterParams {
	confirmCode: string;
	firstName: string;
	lastName: string;
	password: string;
	token: string;
}

export interface tenantSwitchParams {
	tenantId: string;
}

export interface addTenantParams {
	company: string;
	token?: string;
	captchaProvider: string;
}

enum Role {
	AGENT = 'AGENT',
	AGENT_MANAGER = 'AGENT_MANAGER',
	ADMIN = 'ADMIN',
	SALE = 'SALE',
	OWNER = 'OWNER',
	DEVELOPER = 'DEVELOPER',
	OPERATOR = 'OPERATOR',
}

export const roleLabelMap = {
	[Role.AGENT]: 'Service',
	[Role.AGENT_MANAGER]: 'Inbox manager',
	[Role.ADMIN]: 'Admin',
	[Role.SALE]: 'Sale',
	[Role.OWNER]: 'Owner',
	[Role.DEVELOPER]: 'Developer',
	[Role.OPERATOR]: 'Operator',
};
