import WujieReact from 'wujie-react';
import notifySound from './dingdongdingdong.mp3';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateOnlineStatus } from 'src/features/user/userSlice';
import { useAppDispatch } from 'src/app/hooks';

const { bus } = WujieReact;

// 判断当前页面是否不可见
const isPageHidden = () => {
	return document.hidden;
};

// 播放一段通知提示音
const playSound = () => {
	try {
		const AudioContext: any = window?.AudioContext || (window as any)?.webkitAudioContext;
		const audioContext = new AudioContext();
		if (audioContext) {
			const audioElement = document.createElement('audio');
			audioElement.src = notifySound;

			const source = audioContext.createMediaElementSource(audioElement);
			source.connect(audioContext.destination);
			if (audioContext.state === 'suspended') {
				audioContext.resume();
			}
			// 控制播放
			audioElement.play();
		} else {
			const audio = new Audio(notifySound);
			audio.loop = false;
			audio.play();
		}
	} catch (err) {
		console.log(err);
	}
};

const useEvent = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const pushNotification = (title: string, body: string) => {
		console.log('pushNotification', title, body);
		// 如果当前tab页是可见的，就不通知了
		if (!isPageHidden() && location.pathname === '/app/inbox/chat') {
			return;
		}
		if (Notification) {
			const notification = new Notification(title, {
				body,
				requireInteraction: true,
			});
			notification.onclick = (event) => {
				event.preventDefault(); // prevent the browser from focusing the Notification's tab
				window.open('/console/#/app/inbox/chat');
			};
		}
	};

	const locationChange = (url: string) => {
		console.log('locationChange', url);
		window.location.href = url;
	};

	const hashChange = (path: string) => {
		console.log('hashChange', path);
		navigate(path);
	};

	const onlineStatusChange = (status: string) => {
		dispatch(updateOnlineStatus(status));
	};

	useEffect(() => {
		bus.$on('playSound', playSound);
		bus.$on('pushNotification', pushNotification);
		bus.$on('locationChange', locationChange);
		bus.$on('onlineStatusChange', onlineStatusChange);
		bus.$on('hashChange', hashChange);
		return () => {
			bus.$off('playSound', playSound);
			bus.$off('pushNotification', pushNotification);
			bus.$off('locationChange', locationChange);
			bus.$off('onlineStatusChange', onlineStatusChange);
			bus.$off('hashChange', hashChange);
		};
	}, []);
};

export default useEvent;
