import { format, startOfDay, endOfDay, addDays, addWeeks, addMonths, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addYears, formatDistanceToNow } from 'date-fns';
import { dateTimeFormat } from './transform';
import { isBoolean, isEmpty, isNumber, isString, orderBy } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
import { es, id, pt, zhCN } from 'date-fns/locale';
import Cookies from 'js-cookie';
const locale = navigator.language;
export const isLangZhCN = locale === 'zh-CN';
export const now = new Date();
export const getDateStartTime = (e: any) => {
  return startOfDay(e).getTime();
};
export const getDateEndTime = (e: any) => {
  return endOfDay(e).getTime();
};
export const nowInFormat = format(now, dateTimeFormat);
const yesterday = addDays(now, -1);
export const lastYearDay = addYears(now, -1);
export const timeZoneOffset = now.getTimezoneOffset();
export const timeZoneText = `GMT${timeZoneOffset * -1 >= 0 ? '+' : ''}${timeZoneOffset / 60 * -1}`;
export const zoneOffset = (-8 - timeZoneOffset / 60) * 3600 * 1000;
export const minDate3MonthAgo = startOfDay(addMonths(now, -3));

// // 当更新时间在今日内, 显示为具体几分钟/几小时前
// // 当更新时间在1-14天内，显示为 x 天前
// // 当更新时间超过14天，显示为具体日期 2024-03-01
// export const computeAgoTime = (updatedTime: Date) => {
// 	if (!updatedTime) {
// 		return ''
// 	}

// 	const now = new Date();
// 	const diffMilliseconds = now.getTime() - updatedTime.getTime();
// 	const diffMinutes = diffMilliseconds / 1000 / 60;
// 	const diffDays = diffMinutes / 60 / 24;

// 	if (diffMinutes < 60) {
// 		const mins = Math.round(diffMinutes);
// 		return `${Math.round(diffMinutes)} ${mins > 1 ? 'mins' : 'min'} ago`;
// 	} else if (diffMinutes < 24 * 60 && diffMinutes >= 60) {
// 		const hours = Math.round(diffMinutes / 60);
// 		return `${Math.round(diffMinutes / 60)} ${hours > 1 ? 'hours' : 'hour'} ago`;
// 	} else if (diffDays <= 14) {
// 		return formatDistanceToNow(updatedTime, { addSuffix: true });
// 	} else {
// 		return format(updatedTime, 'yyyy-MM-dd');
// 	}
// };

export const AgoTime = ({
  updatedTime
}: {
  updatedTime: Date;
}) => {
  const {
    formatMessage,
    locale
  } = useIntl();
  const now = new Date();
  const diffMilliseconds = now.getTime() - updatedTime.getTime();
  const diffMinutes = diffMilliseconds / 1000 / 60;
  const diffDays = diffMinutes / 60 / 24;
  const localeMap = {
    zh: zhCN,
    pt,
    id,
    es
  };
  if (diffMinutes < 60) {
    const mins = Math.round(diffMinutes);
    return mins > 1 ? formatMessage({
      defaultMessage: '{num} mins ago'
    }, {
      num: mins
    }) : formatMessage({
      defaultMessage: '{num} min ago'
    }, {
      num: mins
    });
  } else if (diffMinutes < 24 * 60 && diffMinutes >= 60) {
    const hours = Math.round(diffMinutes / 60);
    return hours > 1 ? formatMessage({
      defaultMessage: '{num} hours ago'
    }, {
      num: hours
    }) : formatMessage({
      defaultMessage: '{num} hour ago'
    }, {
      num: hours
    });
  } else if (diffDays <= 14) {
    return formatDistanceToNow(updatedTime, {
      addSuffix: true,
      locale: localeMap[locale as keyof typeof localeMap]
    });
  } else {
    return format(updatedTime, 'yyyy-MM-dd');
  }
};
export const todayDate = [{
  label: <FormattedMessage defaultMessage={'Today'} />,
  startDate: startOfDay(now),
  endDate: endOfDay(now)
}];
export const yesterdayDate = [{
  label: <FormattedMessage defaultMessage={'Yesterday'} />,
  startDate: startOfDay(addDays(now, -1)),
  endDate: endOfDay(addDays(now, -1))
}];
export const weekDate = [{
  label: <FormattedMessage defaultMessage={'This Week'} />,
  startDate: startOfWeek(now),
  endDate: endOfWeek(now)
}];
export const lastWeekDate = [{
  label: <FormattedMessage defaultMessage={'Last Week'} />,
  startDate: startOfWeek(addWeeks(now, -1)),
  endDate: endOfWeek(addWeeks(now, -1))
}];
export const last7Date = [{
  label: <FormattedMessage defaultMessage={'Last 7 Days'} />,
  startDate: startOfDay(addDays(now, -6)),
  endDate: endOfDay(now)
}];
export const monthDate = [{
  label: <FormattedMessage defaultMessage={'This Month'} />,
  startDate: startOfMonth(now),
  endDate: endOfMonth(now)
}];
export const lastMonthDate = [{
  label: <FormattedMessage defaultMessage={'Last Month'} />,
  startDate: startOfMonth(addMonths(now, -1)),
  endDate: endOfMonth(addMonths(now, -1))
}];
export const definedDateRanges = todayDate.concat(yesterdayDate, weekDate, last7Date, monthDate, lastMonthDate);
export const formatTime = (time: number | Date, pattern = dateTimeFormat) => {
  if (!time) {
    return '';
  }
  return format(time, pattern);
};
export const defaultLast7DateRange = {
  startDate: startOfDay(addDays(now, -6)),
  endDate: endOfDay(now)
};

//对象数组相同属性归到对应属性下list
export const classify = (arr: any[], key: string) => {
  const kind: string[] = [];
  const newArr: any[] = [];
  arr.forEach(item => {
    if (!kind.includes(item[key])) {
      kind.push(item[key]);
      newArr.push({
        [key]: item[key],
        list: [item]
      });
    } else {
      const index = kind.indexOf(item[key]);
      newArr[index].list.push(item);
    }
  });
  return newArr;
};
export const getOptionLabel = (arr: any, value: string) => {
  const item = arr.filter((i: any) => i.value === value);
  const label = item.length > 0 ? item[0].label : '';
  return label;
};

// 乘法函数， 浮点问题处理
export const accMul = (arg1: number, arg2: number) => {
  if (!arg1 || !arg2) return 0;
  let m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
};
// 保留小数，整数不补0
export const formatFloat = (num: number, n: number) => {
  if (isNaN(num)) {
    return false;
  }
  const f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
  const s = f.toString();
  // let rs = s.indexOf('.')
  // //判定如果是整数，增加小数点再补0
  // if (rs < 0) {
  //   rs = s.length
  //   s += '.'
  // }
  // while (s.length <= rs + n) {
  //   s += '0'
  // }
  return s;
};

//sms计数修正：Gsm编码格式下^€{}[]~\ 按照两个字符计算
export const smsContextLen = (text: string) => {
  let len = 0;
  if (!text || text === '') return len;
  const isGsmReg = new RegExp('^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&amp;\'()*+,\\-./:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$');
  const isGsm = isGsmReg.test(text);
  if (isGsm) {
    const reg = /[\^|€|{|}|[|\]|~|\\]/g;
    const arr: any = text.match(reg);
    const newText = text.replaceAll(reg, '');
    if (arr && arr.length > 0) {
      len += arr.length * 2;
    }
    len += newText.length;
  } else {
    len = text.length;
  }
  return len;
};
export const downloadImage = async (imageUrl: string, name = 'image.png') => {
  fetch(imageUrl).then(resp => resp.blob()).then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }).catch(() => alert('An error sorry'));
};
export const getCurrentTimeZone = () => {
  const timezoneNumber = 0 - new Date().getTimezoneOffset() / 60;
  const timezoneNumberInArr = timezoneNumber.toString().split('.').map(item => Number(item));
  const convertMin = 60 * timezoneNumberInArr[1] / (timezoneNumberInArr[1] < 10 ? 10 : 100);
  const convertHour = timezoneNumberInArr[0] < 10 ? `0${timezoneNumberInArr[0]}` : timezoneNumberInArr[0];
  const timezone = timezoneNumberInArr.length > 1 ? `${convertHour}:${convertMin}` : `${convertHour}`;
  if (timezoneNumber >= 0) {
    return `GMT+${timezone}`;
  } else {
    return `GMT${timezone}`;
  }
};
export const formatPath = (str: string) => {
  if (str.includes('/:')) {
    return str.slice(0, str.indexOf('/:'));
  }
  return str;
};
export const hasValue = (value: any) => {
  if (isBoolean(value)) {
    return true;
  } else if (isNumber(value)) {
    return !isNaN(value);
  } else if (isString(value)) {
    if (value.includes(',')) {
      const arr = value.split(',');
      return arr.every(item => !!item);
    }
    return Boolean(value);
  } else {
    return !isEmpty(value);
  }
};
export const getByteLength = (text: string) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(text).length;
};
export const getUuid = (randomLength = 10) => {
  return Number(Math.random().toString().substr(2, randomLength) + Date.now()).toString(36);
};
export const flattenArray = (arr: any[]) => {
  return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val), []);
};
export const filterFn = (data: any, columns: string[], inputValue: string = '') => {
  if (!Array.isArray(columns)) {
    return false;
  }
  return columns.some(column => data[column].toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
};
export const clearAllData = () => {
  // 清除所有 cookie
  Object.keys(Cookies.get()).forEach((cookieName: string) => {
    Cookies.remove(cookieName);
    Cookies.remove(cookieName, {
      path: '/'
    });
    Cookies.remove(cookieName, {
      path: '/',
      domain: location.hostname
    });
    Cookies.remove(cookieName, {
      path: '/',
      domain: '.' + location.hostname
    });
  });

  // 清除 localStorage
  localStorage.clear();

  // 清除 sessionStorage
  sessionStorage.clear();
  console.log('所有客户端数据已清除');
};

/**
 * 对数组进行排序
 * @param arr 要排序的数组
 * @param keys 用于排序的键（可以是单个字符串或字符串数组）
 * @param orders 排序顺序（可以是单个字符串或字符串数组，与keys对应）
 * @returns 排序后的新数组
 */
export const sortArrayByKey = <T extends Record<string, any>,>(arr: T[], keys: string | string[], orders: 'asc' | 'desc' | Array<'asc' | 'desc'> = 'asc'): T[] => {
  const sortKeys = Array.isArray(keys) ? keys : [keys];
  const sortOrders = Array.isArray(orders) ? orders : [orders];

  // 确保 sortOrders 的长度与 sortKeys 相同
  while (sortOrders.length < sortKeys.length) {
    sortOrders.push(sortOrders[sortOrders.length - 1] || 'asc');
  }
  const newArr = arr.map(item => ({
    ...item,
    ...Object.fromEntries(sortKeys.map(key => [`__sort_${key}`, typeof item[key] === 'string' ? item[key].toLowerCase() : item[key]]))
  }));
  const sortedArr = orderBy(newArr, sortKeys.map(key => `__sort_${key}`), sortOrders);

  // 移除临时排序属性
  return sortedArr.map(item => {
    const newItem = {
      ...item
    };
    sortKeys.forEach(key => {
      delete newItem[`__sort_${key}`];
    });
    return newItem;
  });
};