import { useIntl } from 'react-intl';
import { useFocused, useSelected } from 'slate-react';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
import { botVariablePattern } from '../regexp';
import { Typography, Stack } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
const varElementStyle = {
  display: 'inline-block',
  marginLeft: '4px',
  borderRadius: '16px',
  fontSize: '13px',
  height: '16px',
  lineHeight: '16px',
  padding: '4px 8px'
};
const BotVariableElement = (props: any) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();
  const {
    element,
    children,
    attributes = {}
  } = props;
  /* 变量支持插入Contact变量 */

  const {
    groups = {}
  } = new RegExp(botVariablePattern, 'g').exec(element.character) as any || {};
  const {
    namespace,
    variable,
    params
  } = groups || {};
  if (!params) {
    return <span {...attributes} contentEditable={false} style={{
      ...varElementStyle,
      color: theme.palette.secondary.main,
      background: theme.palette.secondary.activeBg,
      boxShadow: selected && focused ? `0 0 0 2px ${theme.palette.secondary.main}` : 'none'
    }}>
				{children}
				{element.character}
			</span>;
  }
  const formatStringToJson = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (err) {
      console.log(err);
      return {};
    }
  };
  const formatStruct = (v: string) => {
    const structObj = formatStringToJson(v);
    if (structObj.timezone) {
      return `Time zone: GMT${structObj.timezone > 0 ? '+' + structObj.timezone : structObj.timezone}, Show time zone: ${structObj.timezoneDisplay ? 'on' : 'off'}`;
    }
    return null;
  };
  const formatValue = (v: string) => {
    const structObj = formatStringToJson(v);
    if (structObj?.displayName) {
      return `{{${namespace}:${structObj?.displayName || variable}}}`;
    }
    return `{{${namespace}:${variable}}}`;
  };
  if (namespace === 'coupon') {
    return <CustomStyleTooltip title={<Stack>
						<Typography variant="body2" sx={{
        fontSize: '12px'
      }}>
							{formatMessage({
          defaultMessage: 'Coupon name：{name}'
        }, {
          name: formatStringToJson(params)?.name
        })}
						</Typography>
						<Typography variant="body2" sx={{
        fontSize: '12px'
      }}>
							{formatMessage({
          defaultMessage: 'Type：Code'
        })}
						</Typography>
						<Typography variant="body2" sx={{
        fontSize: '12px'
      }}>
							{formatMessage({
          defaultMessage: 'Limit: 1 per person'
        })}
						</Typography>
					</Stack>}>
				<span {...attributes} contentEditable={false} style={{
        ...varElementStyle,
        color: '#FD4C7D',
        background: '#FFF0F1',
        boxShadow: selected && focused ? `0 0 0 2px ${theme.palette.secondary.main}` : 'none'
      }}>
					{children}
					{`{{coupon}}`}
				</span>
			</CustomStyleTooltip>;
  }
  return <CustomStyleTooltip showTooltip={Boolean(formatStruct(params))} title={formatStruct(params)}>
			<span {...attributes} contentEditable={false} style={{
      ...varElementStyle,
      color: theme.palette.secondary.main,
      background: theme.palette.secondary.activeBg,
      boxShadow: selected && focused ? `0 0 0 2px ${theme.palette.secondary.main}` : 'none'
    }}>
				{children}
				{formatValue(params)}
			</span>
		</CustomStyleTooltip>;
};
export default BotVariableElement;