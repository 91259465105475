import { defineMessages, useIntl } from 'react-intl';
import { TextField, InputAdornment, Typography, Box, MenuItem, Divider, ListSubheader, Stack, Collapse } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { VariableNamespace } from 'src/pages/bot/const';
import { selectVariables } from 'src/features/bot/botHistorySlice';
import { isEmpty, orderBy } from 'lodash-es';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { ChatBotFlowContext } from 'src/pages/bot/detail/ChatBotFlowProvider';
import { systemAttributeDisplayNameMap } from 'src/pages/contact/contactList';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDeepCompareEffect } from 'ahooks';
export const botPresetVariableLabelMap = defineMessages({
  name: {
    defaultMessage: 'name',
    description: 'Bot preset variable name'
  },
  phone_number: {
    defaultMessage: 'phone_number',
    description: 'Bot preset variable name'
  },
  country: {
    defaultMessage: 'country',
    description: 'Bot preset variable name'
  }
});
const AddFilter: FunctionComponent<{
  onAdd: (e: any) => void;
  onClose: () => void;
}> = ({
  onAdd
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  const {
    filterList
  } = useContext(ChatBotFlowContext);
  const botVariables = useAppSelector(selectVariables);
  const [inputValue, setInputValue] = useState<any>('');
  const [actives, setActives] = useState<string[]>([VariableNamespace.BOT, VariableNamespace.PRESET, VariableNamespace.CONTACT]);
  const variables = useMemo(() => {
    return [{
      namespace: VariableNamespace.BOT,
      groupName: formatMessage({
        defaultMessage: 'Bot custom variables'
      }),
      children: orderBy(botVariables.map((item: any) => ({
        column: item.name,
        displayName: item.name,
        type: 'Text'
      })) || [], ['column'])
    }, {
      namespace: VariableNamespace.PRESET,
      groupName: formatMessage({
        defaultMessage: 'Bot preset variables'
      }),
      children: orderBy(['name', 'phone_number', 'country'].map((item: string) => ({
        column: item,
        displayName: formatMessage(botPresetVariableLabelMap[item as keyof typeof botPresetVariableLabelMap]),
        type: 'Text'
      })), ['column']),
      help: formatMessage({
        defaultMessage: 'Bot automatically obtains relevant user data entering the process and stores it in the corresponding variables'
      })
    }, {
      namespace: VariableNamespace.CONTACT,
      groupName: formatMessage({
        defaultMessage: 'Contact attributes'
      }),
      children: orderBy(filterList || [], ['isCustomAttr', 'column'])
    }].filter((item: any) => !isEmpty(item.children));
  }, [botVariables, filterList]);
  const isExpandOrCollapse = variables.length > 1;
  const changeCollapse = (v: string) => {
    if (!isExpandOrCollapse) {
      return;
    }
    setActives(prev => {
      if (prev.includes(v)) {
        return prev.filter(item => item !== v);
      } else {
        return [...prev, v];
      }
    });
  };
  const filterResult = useMemo(() => {
    return variables.filter((item: any) => item.children.some((child: any) => child.column.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())));
  }, [inputValue, variables]);
  const handleInput = (e: any) => {
    setInputValue(e.target.value);
  };
  const handleChange = (myValue: any) => {
    if (myValue) {
      onAdd(myValue);
    }
  };
  const hasFilterList = useMemo(() => {
    return filterResult.some((item: any) => {
      return (item.children || []).some((child: any) => child.column.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
    });
  }, [filterResult, inputValue]);
  useDeepCompareEffect(() => {
    setActives(filterResult.map((item: any) => item.namespace));
  }, [filterResult]);
  return <Box sx={{
    width: '381px',
    boxSizing: 'border-box'
  }}>
			<TextField autoFocus={true} placeholder={formatMessage({
      defaultMessage: 'Search'
    })} InputProps={{
      startAdornment: <InputAdornment position="start">
							<SearchRoundedIcon sx={{
          color: 'primary.main'
        }} />
						</InputAdornment>,
      ...(inputValue !== '' && {
        endAdornment: <InputAdornment position="end">
								<ClearRoundedIcon onClick={() => setInputValue('')} sx={{
            color: 'primary.dark',
            cursor: 'pointer'
          }} />
							</InputAdornment>
      })
    }} value={inputValue} onChange={handleInput} sx={{
      width: '100%',
      '& .MuiInputBase-root': {
        px: '12px!important',
        borderRadius: '4px 4px 0px 0px',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        '& input': {
          height: '38px',
          px: 0,
          py: 1,
          color: '#1A1E22'
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none'
      }
    }} />
			<>
				{hasFilterList ? filterResult.map((item: any, index: number) => {
        return <Box key={index}>
								{index !== 0 && <Divider />}
								<ListSubheader onClick={() => changeCollapse(item.namespace)}>
									<Stack direction={'row'} sx={{
              alignItems: 'center',
              cursor: 'pointer',
              color: '#1A1E22'
            }}>
										<Typography variant="body1" sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '40px',
                height: '40px',
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }}>
											{item.groupName}
											{item?.help && <CustomStyleTooltip title={item.help} PopperProps={{
                  style: {
                    zIndex: 2000
                  }
                }}>
													<HelpRoundedIcon sx={{
                    color: 'rgba(26, 30, 34, 0.3)',
                    fontSize: '14px',
                    ml: 1,
                    verticalAlign: 'text-top',
                    cursor: 'pointer'
                  }}></HelpRoundedIcon>
												</CustomStyleTooltip>}
										</Typography>

										{isExpandOrCollapse && <>{actives.includes(item.namespace) ? <ExpandLess /> : <ExpandMore />}</>}
									</Stack>
								</ListSubheader>
								<Collapse key={item.namespace} in={actives.includes(item.namespace)} timeout="auto" unmountOnExit>
									{(item.children || []).filter((child: any) => child.column.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map((variableItem: any, variableIndex: number) => {
              return <MenuItem key={variableIndex} onClick={() => handleChange({
                ...variableItem,
                namespace: item.namespace
              })}>
													<Box sx={{
                  wordBreak: 'noWrap',
                  width: '100%',
                  whiteSpace: 'break-spaces',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
														{safetyFormatMessage(systemAttributeDisplayNameMap[variableItem?.displayName as keyof typeof systemAttributeDisplayNameMap], variableItem?.displayName)}
													</Box>
												</MenuItem>;
            })}
								</Collapse>
							</Box>;
      }) : <Typography sx={{
        textAlign: 'center',
        py: '30px'
      }}>
						{formatMessage({
          defaultMessage: 'No results found'
        })}
					</Typography>}
			</>
		</Box>;
};
export default AddFilter;