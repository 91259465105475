import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { FunctionComponent, ReactNode, Children, useState, useEffect } from 'react';
import Loading from 'src/app/components/loading';
import { ShowSideBarIcon, SideBarIcon } from '../../icons';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { getIsExpand } from 'src/features/integration/integrationSlice';
import { changeExpand } from 'src/features/integration/integrationSlice';
type Path = {
  name: string | ReactNode;
  path: string;
};
type Paths = Array<Path>;
const computeTabIndex = (currentPath: string, paths: Paths) => {
  for (let i = 0; i < paths.length; i++) {
    if (paths[i].path === currentPath) {
      return i;
    }
  }
  return 0;
};
const MainPage: FunctionComponent<{
  title: string | ReactNode;
  subTitle?: string | string[] | ReactNode;
  children?: ReactNode;
  boxStyle?: any;
  isLoading?: boolean;
  hasExpand?: boolean;
  fullScreen?: boolean;
  extra?: ReactNode;
  backPath?: string;
  paths?: Paths;
  titleVariant?: 'subtitle2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit';
}> = ({
  title,
  subTitle,
  children,
  boxStyle,
  isLoading = false,
  fullScreen = false,
  extra,
  backPath = '',
  paths = [],
  hasExpand = false,
  titleVariant = 'h1'
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isExpand = useAppSelector(getIsExpand);
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const handleBack = () => {
    navigate(backPath);
  };
  const handleChange = (e: any, num: number) => {
    setCurrentTab(num);
    navigate(paths[num].path);
  };
  useEffect(() => {
    setCurrentTab(computeTabIndex(location.pathname, paths));
  }, [location.pathname]);
  return <Box sx={{
    width: '100%',
    height: '100%',
    padding: fullScreen ? '32px 24px' : '24px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    ...boxStyle
  }}>
			<Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 1
    }}>
				<Box sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px'
      }}>
					{hasExpand && <IconButton color="primary" sx={{
          mr: '10px'
        }} onClick={() => dispatch(changeExpand(!isExpand))}>
							{isExpand ? <SideBarIcon /> : <ShowSideBarIcon />}
						</IconButton>}

					{backPath && <IconButton color="primary" sx={{
          mr: '10px'
        }} onClick={handleBack}>
							<ArrowBackRoundedIcon />
						</IconButton>}

					{title && <Typography variant={titleVariant} sx={titleVariant === 'h1' ? {
          fontSize: '34px',
          fontWeight: 700,
          lineHeight: '52px',
          letterSpacing: '0.6px',
          color: '#1A1E22',
          fontFamily: 'Roboto'
        } : {
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '36px',
          color: '#1A1E22',
          fontFamily: 'Roboto'
        }}>
							{title}
						</Typography>}
				</Box>
				<Box sx={{
        display: 'flex',
        alignItems: 'center'
      }}>{extra}</Box>
			</Box>
			{!isEmpty(subTitle) && <Box sx={{
      mb: 3
    }}>
					{Array.isArray(subTitle) ? Children.map(subTitle, (child: any, index) => {
        return <Typography variant="body1" color="text.secondary" component="div" key={index}>
									{child}
								</Typography>;
      }) : <Typography variant="body1" color="text.secondary" component="div">
							{subTitle}
						</Typography>}
				</Box>}

			{isEmpty(paths) ? children : <>
					<Tabs value={currentTab} onChange={handleChange}>
						{paths.map((path: Path, index: number) => {
          return <Tab label={path.name} key={index} />;
        })}
					</Tabs>
					<Box sx={{
        paddingTop: 3
      }}>
						<Outlet />
					</Box>
				</>}
			{isLoading && <Loading open={isLoading} />}
		</Box>;
};
export default MainPage;