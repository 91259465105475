import { Locale } from 'src/features/intl/type';

const STTIPE_KEY = {
	TEST: '6LePTnkeAAAAAGj1pe9kAfm8cwaGGzxd65D_XtiF',
	ONLINE: '6Ldj-nweAAAAAH9bpOTYz5iHbOdaIw_EnEqQA_MV',
};

const HCAPTCHA_SITE_KEY = {
	TEST: 'ae5cabe4-ff90-448a-aa1a-85841eea2fa8',
	ONLINE: '2aac3b2e-841e-4a32-9cc2-b2ca8cb26256',
};

const GLOBAL_ENV = {
	DEV: 'dev',
	TEST: 'test',
	PREPARE: 'prepare',
	ONLINE: 'online',
};

export const TEMPLATE_SELECT_PAGE_DEFAULT = {
	PAGE_NO: 1,
	PAGE_SIZE: 100,
};

const ENV = (typeof process !== 'undefined' && process.env.PUBLIC_APP_ENV) || '';

export const hostnameAndPrev = `${window.location.origin}/console/#`;

export const DEFAULT_STTIPE_KEY = [GLOBAL_ENV.DEV, GLOBAL_ENV.TEST, ''].includes(ENV) ? STTIPE_KEY.TEST : STTIPE_KEY.ONLINE;

export const DEFAULT_HCAPTCHA_SITE_KEY = [GLOBAL_ENV.DEV, GLOBAL_ENV.TEST, ''].includes(ENV) ? HCAPTCHA_SITE_KEY.TEST : HCAPTCHA_SITE_KEY.ONLINE;

export const isMock = false;

export const LANG_OPTIONS = [
	{
		value: Locale.Zh,
		label: '中文',
	},
	{
		value: Locale.Es,
		label: 'español',
	},
	{
		value: Locale.Pt,
		label: 'Português',
	},
	{
		value: Locale.Id,
		label: 'Bahasa Indonesia',
	},
	{
		value: Locale.En,
		label: 'English',
	},
];
