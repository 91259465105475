import { isEmpty } from 'lodash-es';
import { CustomerType } from 'src/features/user/type';
export const screenMenusByRole = (menus: any[], role: string, blacklist: string[] = [], customerType?: CustomerType) => {
  const res = menus.filter(menu => {
    const {
      roles: canIn,
      unRoles: cantIn,
      hide,
      alignName,
      partnerTypes,
      unPartnerTypes
    } = menu;
    if (hide) {
      return false;
    }
    if (partnerTypes && !partnerTypes.includes(customerType) || unPartnerTypes && unPartnerTypes.includes(customerType)) {
      return false;
    }
    if (!isEmpty(canIn) || !isEmpty(cantIn)) {
      if (Array.isArray(cantIn) && cantIn.includes(role)) {
        return false;
      }
      if (Array.isArray(canIn) && !canIn.includes(role)) {
        return false;
      }
    }
    if (blacklist.includes(alignName)) {
      return false;
    }
    if (!menu.type) {
      menu.type = 'item';
    }
    if (isEmpty(menu.children)) {
      return true;
    }
    menu.children = screenMenusByRole(menu.children || [], role, blacklist, customerType);
    return true;
  });
  return res;
};