import axios from 'axios';
import { CODE } from 'src/app/const/code';
import { showMessage } from 'src/utils/message';

import type { InternalAxiosRequestConfig, AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { hostnameAndPrev } from 'src/app/const';

interface IOptions {
	showSuccessMessage: boolean; // 是否展示成功消息
	successMessage: string; // 成功消息
	showErrorMessage: boolean; // 是否展示失败消息
	errorMessage: string; // 失败消息
	cancelRepeatRequest: boolean; // 是否取消重复请求
	returnData?: boolean;
}

const defaultOptions = {
	showSuccessMessage: false,
	successMessage: undefined,
	showErrorMessage: true,
	errorMessage: undefined,
	cancelRepeatRequest: false,
	returnData: true,
};

declare module 'axios' {
	export interface AxiosRequestConfig {
		options?: Partial<IOptions>;
	}
}

interface IRequestConfig<T> {
	url: string;
	params: T;
	axiosConfig?: Partial<AxiosRequestConfig>;
	options?: Partial<IOptions>;
}

interface ResponseModel<T> {
	data: T;
	code: number;
	timestamp: number;
	msg: string;
}

export class RequestCanceler {
	// 存储每个请求的标志和取消函数
	pendingRequest: Map<string, AbortController>;

	constructor() {
		this.pendingRequest = new Map<string, AbortController>();
	}

	generateReqKey(config: AxiosRequestConfig): string {
		const { method, url } = config;
		return `${method}&${url}`;
	}

	addPendingRequest(config: AxiosRequestConfig) {
		const requestKey: string = this.generateReqKey(config);
		if (!this.pendingRequest.has(requestKey)) {
			const controller = new AbortController();
			// 给config挂载signal
			config.signal = controller.signal;
			this.pendingRequest.set(requestKey, controller);
		} else {
			// 如果requestKey已经存在，则获取之前设置的controller，并挂载signal
			config.signal = (this.pendingRequest.get(requestKey) as AbortController).signal;
		}
	}

	removePendingRequest(config: AxiosRequestConfig, isCancel = true) {
		const requestKey = this.generateReqKey(config);
		if (this.pendingRequest.has(requestKey)) {
			// 取消请求
			if (isCancel) {
				(this.pendingRequest.get(requestKey) as AbortController).abort();
			}
			// 从pendingRequest中删掉
			this.pendingRequest.delete(requestKey);
		}
	}

	cancelAllPendingRequest() {
		for (const [, controller] of this.pendingRequest) {
			controller.abort();
		}
		this.pendingRequest.clear();
	}

	cancelRequest(configs: AxiosRequestConfig | AxiosRequestConfig[]) {
		(Array.isArray(configs) ? configs : [configs]).forEach((config) => {
			this.removePendingRequest(config, true);
		});
	}
}

const canceler = new RequestCanceler();

class HttpRequest {
	private service: AxiosInstance;
	private preCode: number | undefined;

	constructor(config?: AxiosRequestConfig) {
		this.service = axios.create({
			// baseURL: '',
			...config,
		});

		this.preCode = undefined;

		this.service.interceptors.request.use(
			(config: InternalAxiosRequestConfig) => {
				config.options = {
					...defaultOptions,
					...config?.options,
				};

				if (config?.options?.cancelRepeatRequest) {
					// 检查是否存在重复请求，若存在则取消已发的请求
					canceler.removePendingRequest(config, true);
				}
				// 把当前的请求信息添加到pendingRequest
				canceler.addPendingRequest(config);
				return config;
			},
			(error: AxiosError) => {
				return Promise.reject(error);
			},
		);

		this.service.interceptors.response.use(
			(response: AxiosResponse<any>): AxiosResponse['data'] => {
				canceler.removePendingRequest(response.config, false);
				this.responseHandler(response);
				this.preCode = response.data.code;
				return response;
			},
			(errorResponse: any) => {
				canceler.removePendingRequest(errorResponse.config, false);
				if (!axios.isCancel(errorResponse) && errorResponse.config?.options?.showErrorMessage) {
					// 响应错误，这里可以用全局提示框进行提示
					showMessage({
						message: errorResponse.message || 'ERROR',
						type: 'error',
					});
				}
				return Promise.reject(errorResponse);
			},
		);
	}

	responseHandler(response: AxiosResponse<any>) {
		const { config, data } = response;
		if (data.code === CODE.OK) {
			if (config?.options?.showSuccessMessage) {
				showMessage({
					message: config?.options.successMessage || data.msg || 'SUCCESS',
					type: 'success',
				});
			}
		} else if (data.code === CODE.NEED_LOGIN) {
			if (!(['/api/user/get', '/api/user/needUpdatePassword'].includes(config.url!) && !config?.options?.showErrorMessage)) {
				if (this.preCode !== CODE.NEED_LOGIN) {
					showMessage({
						message: 'Looks like your session expired. Please login again.',
						type: 'error',
					});
				}
				if (!window.location.href.includes('/entry/login')) {
					const lastPath = encodeURIComponent(window.location.href);
					const brandLoginUrl = localStorage.getItem('brandLoginUrl');
					if (brandLoginUrl) {
						window.location.href = `${brandLoginUrl}?redirectUri=${lastPath}`;
					} else {
						window.location.href = `${hostnameAndPrev}/entry/login?lastPath=${lastPath}`;
					}
				}
			}
		} else {
			if (config?.options?.showErrorMessage && config?.responseType !== 'blob') {
				showMessage({
					message: config?.options.errorMessage || data.msg || 'ERROR',
					type: 'error',
				});
			}
		}
	}

	request<T = any>(config: AxiosRequestConfig): Promise<ResponseModel<T>> {
		return new Promise((resolve, reject) => {
			try {
				this.service
					.request<ResponseModel<T>>(config)
					.then((res: AxiosResponse['data']) => {
						resolve(res as ResponseModel<T>);
					})
					.catch((err) => {
						reject(err);
					});
			} catch (err) {
				return Promise.reject(err);
			}
		});
	}

	get<T = any, P = any>(config: IRequestConfig<T>): Promise<[any, ResponseModel<P> | P | undefined]> {
		const { url, params, axiosConfig, options = {} } = config;

		return new Promise((resolve) => {
			this.service
				.get(url, {
					params,
					...axiosConfig,
					options,
				})
				.then((response) => {
					const result = response.data as ResponseModel<P>;
					if (result.code === CODE.OK) {
						resolve([undefined, options?.returnData ? result.data : result]);
					}
					resolve([result, undefined]);
				})
				.catch((err) => {
					resolve([err, undefined]);
				});
		});
	}

	post<T = any, P = any>(config: IRequestConfig<T>): Promise<[any, ResponseModel<P> | P | undefined]> {
		const { url, params, axiosConfig, options } = config;

		return new Promise((resolve) => {
			this.service
				.post(url, params, {
					...axiosConfig,
					options,
				})
				.then((response) => {
					const result = response.data as ResponseModel<P>;
					if (result.code === CODE.OK) {
						resolve([undefined, options?.returnData ? result.data : result]);
					}
					resolve([result, undefined]);
				})
				.catch((err) => {
					resolve([err, undefined]);
				});
		});
	}

	delete<T = any, P = any>(config: IRequestConfig<T>): Promise<[any, ResponseModel<P> | P | undefined]> {
		const { url, params, axiosConfig, options } = config;

		return new Promise((resolve) => {
			this.service
				.delete(url, {
					params,
					...axiosConfig,
					options,
				})
				.then((response) => {
					const result = response.data as ResponseModel<P>;
					if (result.code === CODE.OK) {
						resolve([undefined, options?.returnData ? result.data : result]);
					}
					resolve([result, undefined]);
				})
				.catch((err) => {
					resolve([err, undefined]);
				});
		});
	}

	put<T = any, P = any>(config: IRequestConfig<T>): Promise<[any, ResponseModel<P> | P | undefined]> {
		const { url, params, axiosConfig, options } = config;

		return new Promise((resolve) => {
			this.service
				.put(url, params, {
					...axiosConfig,
					options,
				})
				.then((response) => {
					const result = response.data as ResponseModel<P>;
					if (result.code === CODE.OK) {
						resolve([undefined, options?.returnData ? result.data : result]);
					}
					resolve([result, undefined]);
				})
				.catch((err) => {
					resolve([err, undefined]);
				});
		});
	}

	upload<T = any, P = any>(config: IRequestConfig<T>): Promise<[any, ResponseModel<P> | P | undefined]> {
		const { url, params, axiosConfig, options } = config;

		return new Promise((resolve) => {
			this.service
				.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						...axiosConfig?.headers,
					},
					...axiosConfig,
					options,
				})
				.then((response) => {
					const result = response.data as ResponseModel<P>;
					if (result.code === CODE.OK) {
						resolve([undefined, options?.returnData ? result.data : result]);
					}
					resolve([result, undefined]);
				})
				.catch((err) => {
					resolve([err, undefined]);
				});
		});
	}

	async download<T = any>(config: IRequestConfig<{ filename: string } & T>): Promise<any> {
		const { url, params, axiosConfig, options } = config;

		const response: any = await this.service.get(url, {
			params,
			responseType: 'blob',
			...axiosConfig,
			options,
		});
		const href = URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.href = href;
		link.setAttribute('download', params?.filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	}
}

const httpRequest = new HttpRequest();

export default httpRequest;
