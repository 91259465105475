/*
 * @Author: 田建军
 * @Date: 2024-09-04 14:34:08
 * @LastEditors: 田建军 tianjianjun@qipeng.com
 * @LastEditTime: 2024-09-04 14:35:30
 * @Description:
 * @FilePath: /ycloud-dashboard/src/routes/modules/orders.tsx
 */
import loadComponent from '../loadComponent';
const ordersRouter = [{
  path: 'introduce',
  element: loadComponent('orders/introduce')
}, {
  path: 'list',
  element: loadComponent('orders/list')
}, {
  path: 'detail/:id/:referenceId',
  element: loadComponent('orders/detail')
}];
export default ordersRouter;