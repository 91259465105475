import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { getEmailDomain, sendEmailTest } from './emailAPI';
import { SendEmailTestParams } from './type';

export interface EmailState {
	domainList: any[];
}

const initialState: EmailState = {
	domainList: [],
};

export const getEmailDomainAsync = createAsyncThunk('email/getDomain', async () => {
	const res = await getEmailDomain();
	return res;
});

export const sendEmailTestAsync = createAsyncThunk('email/sendTest', async (params: SendEmailTestParams) => {
	const res = await sendEmailTest(params);
	return res;
});

export const selectCanDomainList = (state: RootState) => state.email.domainList.filter((i: any) => i.domainStatus === 'VERIFIED');

export const emailSlice = createSlice({
	name: 'email',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getEmailDomainAsync.fulfilled, (state, action) => {
			state.domainList = action.payload.data;
		});
	},
});

export default emailSlice.reducer;
