import { showMessage } from 'src/utils/message';

export const fbJsdkForBrand = (appId: string) => {
	(function (d: any, s: any, id: any) {
		let js;
		const fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.setAttribute('defer', true);
		(js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
		(fjs as any).parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');
	(window as any).fbAsyncInit = function () {
		// @ts-ignore：

		FB.init({
			appId, // 应用编号
			cookie: true,
			xfbml: true,
			version: 'v18.0', //Graph API version
		});
	};
};

export const handleSignUpForBrand = (event: (e: any) => void, config_id: string, solutionID: string) => {
	// Launch Facebook login
	// @ts-ignore：

	FB.login(
		function (response: any) {
			if (response.status === 'connected' && response.authResponse) {
				event(response);
			} else {
				showMessage({
					type: 'error',
					message: 'Failed to sign up for WhatsApp Business API',
				});
			}
		},
		{
			config_id,
			response_type: 'code', // must be set to 'code' for System User access token
			override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
			extras: {
				sessionInfoVersion: 2,
				setup: {
					solutionID,
				},
			},
		},
	);
};
