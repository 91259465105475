/*
 * @Author: 田建军 tianjianjun@qipeng.com
 * @Date: 2024-09-29 10:24:40
 * @LastEditors: 田建军 tianjianjun@qipeng.com
 * @LastEditTime: 2024-09-29 10:43:11
 * @FilePath: /ycloud-dashboard/src/app/components/contrySelect/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Autocomplete, Box, Popper, FormHelperText, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { forwardRef, useCallback } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { selectCountryCodes } from 'src/features/user/userSlice';
import { outlinedInputErrorStyle } from 'src/app/components/commonStyle';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { useIntl } from 'react-intl';
interface IProps {
  size?: 'small' | 'medium';
  disableClearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  value: any;
  getOptionLabel?: ((option: any) => string) | undefined;
  onChange: (...event: any[]) => void;
  renderInput?: any;
  autoFocus?: boolean;
  cssStyle?: any;
  error?: boolean;
  helperText?: any;
  popWidth?: string;
}
export type Ref = any;
const CountrySelect = forwardRef<Ref, IProps>(({
  size = 'small',
  disableClearable = false,
  disabled = false,
  placeholder,
  value,
  getOptionLabel = (option: any) => option?.countryName,
  onChange,
  renderInput,
  autoFocus = false,
  cssStyle,
  error = false,
  helperText,
  popWidth = '300px'
}, ref) => {
  const countryCodes = useAppSelector(selectCountryCodes);
  const {
    formatMessage
  } = useIntl();
  if (placeholder === undefined) {
    placeholder = formatMessage({
      defaultMessage: 'Country/Region'
    });
  }
  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.countryName}+${option.callingCode}+${option.countryCode}`
  });
  const PopperMy = useCallback((props: any) => {
    return <Popper {...props} style={{
      width: popWidth
    }} placement="bottom-start" />;
  }, [popWidth]);
  return <>
			<Autocomplete componentsProps={{
      popper: {
        sx: {
          zIndex: '3000!important'
        }
      }
    }} ref={ref} size={size} disableClearable={disableClearable} disabled={disabled} value={value} options={countryCodes} filterOptions={filterOptions} getOptionLabel={getOptionLabel} isOptionEqualToValue={(option: any, myValue: any) => {
      return option.callingCode === myValue.callingCode && option.countryCode === myValue.countryCode;
    }} renderOption={(props: any, option: any) => <Box {...props} key={option.countryFlag + option.countryCode}>
						{option.countryFlag ? option.countryFlag : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
						&nbsp;&nbsp;+{option.callingCode}&nbsp;&nbsp;{option.countryName}
					</Box>} onChange={onChange} renderInput={renderInput ? renderInput : params => {
      return <TextField {...params} placeholder={placeholder} autoFocus={autoFocus} size={size} />;
    }} PopperComponent={PopperMy} sx={{
      ...(error && outlinedInputErrorStyle),
      ...cssStyle
    }} />
			{error && <FormHelperText error={error}>
					<ErrorHelperText>{helperText}</ErrorHelperText>
				</FormHelperText>}
		</>;
});
export default CountrySelect;