import { showMessage } from 'src/utils/message';

export const fbJsdk = () => {
	(function (d: any, s: any, id: any) {
		let js;
		const fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.setAttribute('defer', true);
		(js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
		(fjs as any).parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');
	(window as any).fbAsyncInit = function () {
		// @ts-ignore：

		FB.init({
			appId: '2892949377516980', // 应用编号
			cookie: true,
			xfbml: true,
			version: 'v17.0',
		});
	};
};

export const handleSignUp = (event: (e: any) => void) => {
	// @ts-ignore：
	if (window.fbq) {
		// @ts-ignore
		window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '2892949377516980', feature: 'whatsapp_embedded_signup' });
	}
	// @ts-ignore：

	FB.login(
		function (response: any) {
			if (response.status === 'connected' && response.authResponse) {
				event(response);
			} else {
				showMessage({
					type: 'error',
					message: 'Failed to sign up for WhatsApp Business API',
				});
			}
		},
		{
			scope: 'whatsapp_business_management',
			extras: {
				feature: 'whatsapp_embedded_signup',
				version: 2,
				sessionInfoVersion: 2,
			},
		},
	);
};
