import { FormattedMessage, useIntl } from 'react-intl';
import { Drawer, Box, Typography, Button } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FunctionComponent, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { selectCountryCodes } from 'src/features/user/userSlice';
import { selectAllTotal } from 'src/features/contact/contactList/contactListSlice';
import { selectAllCustomAttr, addContactAsync, updateContactAsync, selectTagColorMapping, addBlocklistAsync, deleteBlocklistAsync } from 'src/features/contact/contactList/contactListSlice';
import { transformPhoneNumber } from 'src/utils/transform';
import UserForm from './components/UserForm';
import UserInfo from './components/UserInfo';
import { isEmpty } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import ConfirmTitle from 'src/app/components/confirm/ConfirmTitle';
import { store } from 'src/app/store';
import { setAllTotal } from 'src/features/contact/contactList/contactListSlice';
const getEmailFromOwner = (owner: string) => {
  if (!owner) {
    return '';
  }
  const pattern = /([_\w-.]+@([\w-]+\.)+[\w-]{2,4})/;
  const match = owner.match(pattern);
  if (match && match[0]) {
    return match[0];
  }
  return '';
};
const AddEditUser: FunctionComponent<{
  type: string;
  open: boolean;
  item: any;
  curSeg?: string;
  onClose: () => void;
  onSubmit: () => void;
}> = ({
  type,
  open,
  item,
  curSeg,
  onClose,
  onSubmit
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const allCountryCodes = useAppSelector(selectCountryCodes);
  const tagColorMapping = useAppSelector(selectTagColorMapping);
  const allCustomAttr = useAppSelector(selectAllCustomAttr);
  const allTotal = useAppSelector(selectAllTotal);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const contactId = item?.contactId || '';
  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      nickName: '',
      areaNo: '',
      owner: '',
      flag: '',
      mobile: '',
      email: '',
      tag: [],
      customAttrs: []
    }
  });
  const {
    reset,
    watch,
    getValues,
    handleSubmit
  } = form;
  const flag = watch('flag');
  const tag = watch('tag');
  const customAttrs = watch('customAttrs');
  const handleClose = () => {
    setIsEdit(false);
    onClose();
  };
  const handleCancel = () => {
    if (type === 'add' || type === 'info' && !isEdit) {
      handleClose();
    } else {
      setIsEdit(false);
      reset();
    }
  };
  const handleAddBlock = () => {
    confirm({
      title: <ConfirmTitle color="error" title={<FormattedMessage defaultMessage="Are you sure to Block this Contact?" />} />,
      content: <Typography variant="body1">
					<FormattedMessage defaultMessage="{name} will be moved to the Blocklist and you will no longer receive WhatsApp messages from this user in Chatbot and Inbox." values={{
          name: item['Nickname'] || item['Phone number']
        }} />
				</Typography>,
      confirmationText: <FormattedMessage defaultMessage="Block" />,
      cancellationText: <FormattedMessage defaultMessage="Cancel" />,
      confirmationButtonProps: {
        color: 'error'
      }
    }).then(async () => {
      const res = await dispatch(addBlocklistAsync({
        phoneNumber: item['Phone number']
      })).unwrap();
      if (res.code === CODE.OK) {
        onSubmit();
        store.dispatch(setAllTotal(allTotal - 1));
      }
    });
  };
  const handleDeleteBlock = async () => {
    const res = await dispatch(deleteBlocklistAsync({
      phoneNumber: item['Phone number']
    })).unwrap();
    if (res.code === CODE.OK) {
      onSubmit();
      store.dispatch(setAllTotal(allTotal + 1));
    }
  };
  const confirm = useConfirm();
  const submitData = async () => {
    const params: any = getValues();
    /* Tag ID 改造 */
    const curTag = !isEmpty(params.tag) ? params.tag.map((i: any) => i.id) : [];
    const curAttr: Array<any> = [];
    if (params.customAttrs && params.customAttrs.length > 0) {
      params.customAttrs.forEach((e: any) => {
        if (e.value !== '') {
          curAttr.push({
            displayName: e.displayName,
            value: e.value
          });
        }
      });
    }
    const addEditParams: any = {
      nickName: params.nickName,
      phoneNumber: transformPhoneNumber(params.mobile),
      email: params.email,
      tags: curTag,
      ownerEmail: getEmailFromOwner(params.owner),
      customAttrs: curAttr,
      ...(type === 'info' && isEdit && {
        contactId: item.contactId
      })
    };
    const res: any = type === 'add' ? await dispatch(addContactAsync(addEditParams)).unwrap() : await dispatch(updateContactAsync(addEditParams)).unwrap();
    if (res.code === CODE.OK) {
      setIsEdit(false);
      onSubmit();
    }
  };
  const onConfirm = () => {
    const params: any = getValues();
    if (isEdit && (item.Owner && params.owner && item.Owner.indexOf(params.owner) < 0 || !item.Owner && params.owner)) {
      confirm({
        title: <ConfirmTitle color="info" title={formatMessage({
          defaultMessage: 'Are you sure to submit？'
        })} />,
        content: formatMessage({
          defaultMessage: 'After the owner is modified, the conversation currently in progress will immediately assign to the new Owner. Please press confirm to continue.'
        }),
        confirmationText: formatMessage({
          defaultMessage: 'OK'
        }),
        confirmationButtonProps: {
          color: 'primary'
        }
      }).then(() => {
        submitData();
      }).catch(() => {
        console.log('cancel');
      });
    } else {
      submitData();
    }
  };
  const initCusAttr = (i: any) => {
    const curAttr: Array<any> = allCustomAttr.filter(e => e.isCustomAttr);
    const itemAttr: Array<any> = [];
    curAttr.forEach((e: any) => {
      let value = i && i[e.displayName] ? i[e.displayName] : e.displayType === 'Array' ? [] : '';
      if (i && i[e.displayName] === 0) {
        // 适配value = 0的情况
        value = 0;
      }
      itemAttr.push({
        ...e,
        value
      });
    });
    return itemAttr;
  };
  useEffect(() => {
    if (type === 'add') {
      reset({
        nickName: '',
        areaNo: '',
        flag: '',
        mobile: '',
        owner: '',
        email: '',
        tag: [],
        customAttrs: initCusAttr(undefined)
      });
    } else {
      if (item) {
        const phoneNumber = item['Phone number'] || '';
        const mobileInfo = parsePhoneNumber(phoneNumber);
        const country = mobileInfo?.country;
        const target = country ? allCountryCodes.filter((e: any) => e.countryCode === country && String(e.callingCode) === mobileInfo?.countryCallingCode) : allCountryCodes.filter((e: any) => e.callingCode === mobileInfo?.countryCallingCode);
        const mobileArr = phoneNumber.split('+' + mobileInfo?.countryCallingCode);
        const mobileNum = mobileArr[1] ? mobileArr[1] : mobileInfo?.nationalNumber;
        let countryCode = '';
        let countryFlag = '';
        if (target && target.length > 0) {
          countryCode = target[0].countryCode;
          countryFlag = target[0].countryFlag;
        }
        /* Tag ID 改造 */
        const itemAttr: Array<any> = initCusAttr(item);
        reset({
          nickName: item.Nickname,
          areaNo: countryCode,
          flag: countryFlag,
          owner: item.Owner,
          mobile: mobileInfo?.countryCallingCode + ':' + mobileNum,
          email: item.Email,
          tag: (item?.Tag || []).map((i: any) => tagColorMapping[i]).filter(Boolean),
          customAttrs: itemAttr
        });
      }
    }
  }, [type, item, allCountryCodes, reset]);
  return <Drawer anchor="right" open={open} onClose={handleClose} sx={{
    '& .MuiPaper-root': {
      boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
    }
  }}>
			<Box sx={{
      width: '480px'
    }}>
				<Box sx={{
        pl: 4,
        py: 3,
        pr: 3,
        position: 'relative'
      }}>
					<Typography variant="h3" color="rgba(0, 0, 0, 0.87)">
						{type === 'add' ? formatMessage({
            defaultMessage: 'Add a new contact'
          }) : type === 'info' && !isEdit ? curSeg === 'all' ? formatMessage({
            defaultMessage: 'Contact information'
          }) : formatMessage({
            defaultMessage: 'User information'
          }) : formatMessage({
            defaultMessage: 'Edit the information'
          })}
					</Typography>
					<CloseRoundedIcon onClick={handleClose} sx={{
          fontSize: '24px',
          position: 'absolute',
          top: '24px',
          right: '24px',
          cursor: 'pointer'
        }} />
				</Box>
				<Box sx={{
        boxSizing: 'border-box',
        height: 'calc(100vh - 150px)',
        pl: 4,
        pr: 6,
        pb: 2,
        overflowY: 'auto'
      }}>
					{type === 'add' || type === 'info' && isEdit ? <FormProvider {...form}>
							<UserForm contactId={contactId} />
						</FormProvider> : type === 'info' && !isEdit && <UserInfo info={item} flag={flag} tag={tag} customAttrs={customAttrs} />}
				</Box>
				<Box sx={{
        width: '100%',
        pl: 4,
        py: 2,
        position: 'fixed',
        bottom: 0,
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)'
      }}>
					{type === 'add' || type === 'info' && isEdit ? <>
							<Button variant="contained" sx={{
            mr: 2
          }} onClick={handleSubmit(onConfirm)}>
								<FormattedMessage defaultMessage={'Submit'} />
							</Button>
							<Button variant="outlined" onClick={handleCancel}>
								<FormattedMessage defaultMessage={'Cancel'} />
							</Button>
						</> : type === 'info' && !isEdit && (curSeg === 'blocklist' ? <Button variant="outlined" sx={{
          ml: 2
        }} onClick={handleDeleteBlock}>
								<FormattedMessage defaultMessage={'Remove from blocklist'} />
							</Button> : <>
								<Button variant="contained" onClick={() => setIsEdit(true)}>
									<FormattedMessage defaultMessage={'Edit'} />
								</Button>
								<Button variant="text" sx={{
            ml: 2,
            color: '#F5222D'
          }} onClick={handleAddBlock}>
									<FormattedMessage defaultMessage={'Block'} />
								</Button>
							</>)}
				</Box>
			</Box>
		</Drawer>;
};
export default AddEditUser;