import React, { useRef, FC, useState, useLayoutEffect } from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import copyFn from 'copy-to-clipboard';
import { showMessage } from 'src/utils/message';
import { useIntl } from 'react-intl';
interface IProps {
  text: string;
  sx?: any;
  renderHtml?: boolean;
  showCopyIcon?: boolean;
  copyTrigger?: 'icon' | 'text';
  copyText?: string;
  tip?: string;
}
const LongTextEllipsis: FC<IProps> = ({
  text,
  sx = {},
  renderHtml,
  showCopyIcon,
  copyTrigger,
  copyText,
  tip
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const textRef: any = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useLayoutEffect(() => {
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);
  const handleCopy = (e: any) => {
    e.stopPropagation();
    copyFn(text);
    showMessage({
      type: 'success',
      message: copyText || formatMessage({
        defaultMessage: 'Copy Success'
      })
    });
  };
  const style = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ...(isOverflowing && {
      textOverflow: 'ellipsis'
    })
  };
  if (renderHtml) {
    return <Tooltip slotProps={{
      popper: {
        sx: {
          zIndex: 3001
        }
      }
    }} componentsProps={{
      popper: {
        sx: {
          zIndex: 3001
        }
      }
    }} placement="top" arrow title={isOverflowing ? renderHtml ? <Box dangerouslySetInnerHTML={{
      __html: text
    }} /> : text : null}>
				<Box ref={textRef} sx={{
        ...style,
        ...sx
      }} dangerouslySetInnerHTML={{
        __html: text
      }} />
			</Tooltip>;
  }
  return <Tooltip slotProps={{
    popper: {
      sx: {
        zIndex: 3001
      }
    }
  }} componentsProps={{
    popper: {
      sx: {
        zIndex: 3001
      }
    }
  }} placement="top" arrow title={tip || (isOverflowing ? text : null)}>
			<Box sx={{
      display: 'flex',
      width: '100%',
      ...sx
    }} {...copyTrigger === 'text' && {
      onClick: handleCopy
    }}>
				<span ref={textRef} style={style}>
					{text}
				</span>
				{showCopyIcon && text && <IconButton {...copyTrigger === 'icon' && {
        onClick: handleCopy
      }} sx={{
        p: 0,
        px: 1
      }}>
						<FileCopyOutlinedIcon sx={{
          ...sx,
          cursor: 'pointer'
        }} />
					</IconButton>}
			</Box>
		</Tooltip>;
};
export const MultiLineTextEllipsis: FC<IProps & {
  lineClamp?: number;
  lineHeight?: number;
}> = ({
  text,
  sx = {},
  lineClamp = 3,
  lineHeight = 22,
  renderHtml
}) => {
  const textRef: any = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useLayoutEffect(() => {
    if (text && textRef.current) {
      setIsOverflowing(textRef.current.getBoundingClientRect()?.height > lineClamp * lineHeight);
    }
  }, [text]);
  const style = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    ...sx,
    ...(isOverflowing && {
      WebkitLineClamp: lineClamp,
      textOverflow: 'ellipsis'
    })
  };
  if (renderHtml) {
    return <Tooltip slotProps={{
      popper: {
        sx: {
          zIndex: 3001
        }
      }
    }} componentsProps={{
      popper: {
        sx: {
          zIndex: 3001
        }
      }
    }} placement="top" arrow title={isOverflowing ? renderHtml ? <Box dangerouslySetInnerHTML={{
      __html: text
    }} /> : text : null}>
				<Box ref={textRef} sx={style} dangerouslySetInnerHTML={{
        __html: text
      }} />
			</Tooltip>;
  }
  return <Tooltip slotProps={{
    popper: {
      sx: {
        zIndex: 3001
      }
    }
  }} componentsProps={{
    popper: {
      sx: {
        zIndex: 3001
      }
    }
  }} placement="top" arrow title={isOverflowing ? text : null}>
			<Box ref={textRef} sx={style}>
				{text}
			</Box>
		</Tooltip>;
};
export default LongTextEllipsis;