import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { jumpToSubSystem, uploadFile, downloadImage } from './commonAPI';
import { JumpToSubSystemParams } from './type';

export interface CommonState {
	isSearch: boolean;
	subSystemUrls: {
		[index: string]: {
			url: string;
			message: string;
		};
	};
}

const initialState: CommonState = {
	isSearch: false,
	subSystemUrls: {},
};

export const jumpToSubSystemAsync = createAsyncThunk('common/jumpToSubSystem', async (params: JumpToSubSystemParams) => {
	const res = await jumpToSubSystem(params);
	return res;
});

export const downloadImageAsync = createAsyncThunk('common/downloadImage', async (params: any) => {
	const res = await downloadImage(params);
	return res;
});

export const uploadFileAsync = createAsyncThunk('common/uploadFile', async (params: any) => {
	const res = await uploadFile(params);
	return res;
});

export const selectIsSearch = (state: RootState) => state.common.isSearch;
export const selectSubSystemUrls = (state: RootState) => state.common.subSystemUrls;

export const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setIsSearchState: (state, action) => {
			state.isSearch = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(jumpToSubSystemAsync.fulfilled, (state, action) => {
				state.subSystemUrls[action.meta.arg.targetSystem] = {
					url: action.payload.data.targetUrl,
					message: '',
				};
			})
			.addCase(jumpToSubSystemAsync.rejected, (state, action) => {
				state.subSystemUrls[action.meta.arg.targetSystem] = {
					url: '',
					message: action.error.message || 'Error',
				};
			});
	},
});

export const { setIsSearchState } = commonSlice.actions;

export default commonSlice.reducer;
