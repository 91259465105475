import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { verifyGuideSend, verifyGuideCode, getVerifyDesign, uploadBrandIcon, updateVerifyDesign, getVerifySecurity, updateVerifySecurity, getVerifyBlackList, addVerifyBlackList, delVerifyBlackList, getVerifyMessage, getVerifyStatistics } from './verifyAPI';
import { VerifyGuideSendParams, VerifyGuideCodeParams, VerifyDesignParams, VerifySettingParams, VerifySecurityParams, BlackListParams, AddBlackListParams, DelBlackListParams, VerifyMessageParams } from './type';
import { RootState } from 'src/app/store';
import { CODE } from 'src/app/const/code';

export interface VerifyState {
	securitySetting: VerifySettingParams;
	securityConfig: VerifySecurityParams;
}

const initialState: VerifyState = {
	securitySetting: {
		id: 0,
		tenantId: '',
		brand: 'string',
		brandColor: '',
		brandIcon: '',
		codeLen: '6',
		validityPeriod: '5',
		remindNotToShare: true,
		remindValidityPeriod: true,
		sender: '',
		channel: '',
	},
	securityConfig: {
		id: '',
		userId: '',
		filterLandlineNumber: false,
		filterVirtualNumber: false,
		enableBlacklist: false,
		filterRegions: [],
	},
};

export const verifyGuideSendAsync = createAsyncThunk('verify/guideSend', async (params: VerifyGuideSendParams) => {
	const res = await verifyGuideSend(params);
	return res;
});

export const verifyGuideCodeAsync = createAsyncThunk('verify/guideCode', async (params: VerifyGuideCodeParams) => {
	const res = await verifyGuideCode(params);
	return res;
});

export const getVerifyDesignAsync = createAsyncThunk('verify/getDesign', async (params: VerifyDesignParams) => {
	const res = await getVerifyDesign(params);
	return res;
});

export const uploadBrandIconAsync = createAsyncThunk<
	any,
	any,
	{
		state: RootState;
	}
>('verify/uploadBrandIcon', async (params: any, { getState }) => {
	const { channel } = getState().verify.securitySetting;
	const res = await uploadBrandIcon(params, {
		channel: channel,
	});
	return res;
});

export const updateVerifyDesignAsync = createAsyncThunk('verify/updateDesign', async (params: VerifySettingParams, { rejectWithValue }) => {
	const res = await updateVerifyDesign(params);
	if (res.code === CODE.OK) {
		return Object.assign({}, res, { data: params });
	} else {
		return rejectWithValue(res.msg);
	}
});

export const getVerifySecurityAsync = createAsyncThunk('verify/getSecurity', async () => {
	const res = await getVerifySecurity();
	return res;
});

export const updateVerifySecurityAsync = createAsyncThunk('verify/updateSecurity', async (params: VerifySecurityParams, { rejectWithValue }) => {
	const res = await updateVerifySecurity(params);
	if (res.code === CODE.OK) {
		return Object.assign({}, res, { data: params });
	} else {
		return rejectWithValue(res.msg);
	}
});

export const getVerifyBlackListAsync = createAsyncThunk('verify/getBlackList', async (params: BlackListParams) => {
	const res = await getVerifyBlackList(params);
	return res;
});

export const addVerifyBlackListAsync = createAsyncThunk('verify/addBlackList', async (params: AddBlackListParams) => {
	const res = await addVerifyBlackList(params);
	return res;
});

export const delVerifyBlackListAsync = createAsyncThunk('verify/delBlackList', async (params: DelBlackListParams) => {
	const res = await delVerifyBlackList(params);
	return res;
});

export const getVerifyMessageAsync = createAsyncThunk('verify/getMessage', async (params: VerifyMessageParams) => {
	const res = await getVerifyMessage(params);
	return res;
});

export const getVerifyStatisticsAsync = createAsyncThunk('verify/getStatistics', async (params: VerifyMessageParams) => {
	const res = await getVerifyStatistics(params);
	return res;
});

export const selectSecuritySetting = (state: RootState) => state.verify.securitySetting;
export const selectSecurityConfig = (state: RootState) => state.verify.securityConfig;
export const selectFilterRegions = (state: RootState) => (state.verify.securityConfig.filterRegions ? state.verify.securityConfig.filterRegions : []);

export const verifySlice = createSlice({
	name: 'verify',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getVerifyDesignAsync.fulfilled, (state, action) => {
				state.securitySetting = action.payload.data;
			})
			.addCase(updateVerifyDesignAsync.fulfilled, (state, action) => {
				state.securitySetting = action.payload.data;
			})
			.addCase(getVerifySecurityAsync.fulfilled, (state, action) => {
				state.securityConfig = action.payload.data;
			})
			.addCase(updateVerifySecurityAsync.fulfilled, (state, action) => {
				state.securityConfig = action.payload.data;
			});
	},
});

export default verifySlice.reducer;
