import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, TextField, Stack, Switch, Alert, FormHelperText } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { CustomLabel } from 'src/app/components/form';
import { Box } from '@mui/material';
import { useState, FunctionComponent, useEffect } from 'react';
import CustomAccordion, { Size } from 'src/app/components/customAccordion';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectMaxNodeHandleNumber, updateMaxNodeHandleNumberReducer } from 'src/features/bot/botHistorySlice';
import { AskQuestionType } from 'src/features/bot/type';
import RichTextInput, { getNoParamsText } from 'src/app/components/form/RichTextInput';
import Drag from './components/drag';
import ListDrag from './components/listDrag';
import AskQuestionTypeSelector from './components/askQuestionTypeSelector';
import VariableInput from 'src/pages/bot/detail/components/VariableInput';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { get } from 'lodash-es';
import CustomTooltip from 'src/app/components/form/customTooltip';
import TextCountInput from 'src/app/components/form/textCountInput';
import WabaSelector from 'src/pages/dashboard/templates/components/WabaSelector';
import WhatsAppFlowSelector from 'src/pages/dashboard/whatsappFlows/components/WhatsAppFlowSelector';
import useWhatsAppFlowScreen from 'src/pages/dashboard/templates/components/setting/renderItem/useWhatsAppFlowScreen';
import { selectNodes } from 'src/features/bot/botHistorySlice';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

// 如果trigger是template button的，wabaId的初始值是从trigger里面获取的，且不可更改
export const getWabaIdFromTrigger = (triggerNode: any) => {
  const components = triggerNode?.data?.config?.components || [];
  const templateButton = components?.find((component: any) => component.type === 'templateButton');
  if (templateButton) {
    const wabaId = templateButton?.templateButton?.wabaId || '';
    return wabaId;
  }
  return '';
};
export const getWabaIdFromAskQuestion = (node: any) => {
  const wabaId = node?.data?.config?.message?.interactive?.action?.parameters?.wabaId || '';
  return wabaId;
};
const AskQuestionConfigEditor: FunctionComponent<{
  nodeId?: string;
}> = ({
  nodeId
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const {
    register,
    control,
    watch,
    formState,
    setValue,
    getValues
  } = useFormContext();
  const errors: any = formState.errors;
  const maxNodeHandleNumber = useAppSelector((state: any) => selectMaxNodeHandleNumber(state, nodeId || ''));
  const [expand, setExpand] = useState<Array<string>>(['question', 'answer']);
  const handleExpandChange = (type: string) => {
    const newExpand = [...expand];
    if (newExpand.includes(type)) {
      const index = newExpand.indexOf(type);
      if (index !== -1) {
        newExpand.splice(index, 1);
      }
    } else {
      newExpand.push(type);
    }
    setExpand(newExpand);
  };
  const dispatch = useAppDispatch();
  const watchType = watch('message.interactive.type');
  const watchMessageType = watch('message.type');
  const updateMaxNodeHandleNumber = () => {
    dispatch(updateMaxNodeHandleNumberReducer(nodeId));
  };
  const step = watchMessageType === undefined ? 0 : 1;
  const handleTypeSelect = (newType: AskQuestionType) => {
    if (newType !== AskQuestionType.TEXT) {
      setValue('message.interactive.type', newType);
      setValue('message.type', 'interactive');
      setValue('triggerOnce', false);
    } else {
      setValue('message.type', 'text');
    }
  };
  const isWhatsAppFlowQuestion = watchType === AskQuestionType.FLOW;
  const [showWhatsAppFlowQuestionTip, setShowWhatsAppFlowQuestionTip] = useState(true);
  const selectedWabaId = watch('message.interactive.action.parameters.wabaId');
  const watchFlowId = watch('message.interactive.action.parameters.flow_id');
  const {
    screenId
  } = useWhatsAppFlowScreen(watchFlowId);
  const nodes = useAppSelector(selectNodes);
  useEffect(() => {
    if (screenId) {
      setValue('message.interactive.action.parameters.flow_action_payload', {
        screen: screenId
      });
      setValue('message.interactive.action.name', 'flow');
      setValue('message.interactive.action.parameters.flow_action', 'navigate');
    } else {
      if (!getValues('message.interactive.action.parameters.flow_action_payload.screen')) {
        setValue('message.interactive.action.parameters', undefined);
        setValue('message.interactive.action.name', undefined);
      }
    }
  }, [screenId]);
  const [wabaSelectDisabled, setWabaSelectDisabled] = useState(false);
  useEffect(() => {
    if (isWhatsAppFlowQuestion) {
      if (!selectedWabaId) {
        const triggerWabaId = getWabaIdFromTrigger(nodes[0]);
        setValue('message.interactive.action.parameters.wabaId', triggerWabaId);
        setWabaSelectDisabled(Boolean(triggerWabaId));
      } else {
        const triggerWabaId = getWabaIdFromTrigger(nodes[0]);
        if (selectedWabaId === triggerWabaId) {
          setWabaSelectDisabled(true);
        }
      }
    }
  }, [selectedWabaId, isWhatsAppFlowQuestion]);
  return <>
			{step === 0 ? <FormControl sx={{
      p: 3,
      boxSizing: 'border-box'
    }} fullWidth>
					<Controller name="message.interactive.type" control={control} rules={{
        required: formatMessage({
          defaultMessage: 'Required'
        })
      }} render={({
        field
      }) => {
        return <AskQuestionTypeSelector {...field} error={Boolean(errors?.message?.interactive?.type?.type)} onChange={handleTypeSelect} />;
      }} />
				</FormControl> : <>
					<CustomAccordion title={formatMessage({
        defaultMessage: 'Question'
      })} size={Size.MEDIUM} expanded={expand.includes('question')} onChange={() => handleExpandChange('question')}>
						<Stack spacing={2} sx={{
          padding: '8px 24px'
        }}>
							{isWhatsAppFlowQuestion && showWhatsAppFlowQuestionTip && <Alert severity="info" onClose={() => setShowWhatsAppFlowQuestionTip(false)}>
									<FormattedMessage defaultMessage={'If the user does not complete the WhatsApp flow, the process will be halted at this step and will not automatically proceed to the subsequent steps.'} />
								</Alert>}
							{watchMessageType === 'interactive' && <FormControl fullWidth>
									<CustomLabel>
										{formatMessage({
                defaultMessage: 'Text'
              })}
									</CustomLabel>
									<Controller name="message.interactive.body.text" control={control} rules={{
              required: formatMessage({
                defaultMessage: 'Required'
              }),
              validate: {
                isValid: (value: string) => {
                  if (getNoParamsText(value, 'bot').length > 1024) {
                    return 'Please enter no more than 1024 characters';
                  }
                  return true;
                }
              }
            }} render={({
              field
            }) => {
              return <RichTextInput {...field} defaultHeight={200} error={Boolean(errors.message?.interactive?.body?.text?.type)} helperText={errors.message?.interactive?.body?.text?.message} />;
            }} />
								</FormControl>}
							{watchMessageType === 'text' && <FormControl fullWidth>
									<CustomLabel>
										{formatMessage({
                defaultMessage: 'Text'
              })}
									</CustomLabel>
									<Controller name="message.text.body" control={control} rules={{
              required: formatMessage({
                defaultMessage: 'Required'
              }),
              validate: {
                isValid: (value: string) => {
                  if (getNoParamsText(value, 'bot').length > 1024) {
                    return 'Please enter no more than 1024 characters';
                  }
                  return true;
                }
              }
            }} render={({
              field
            }) => {
              return <RichTextInput {...field} defaultHeight={200} error={Boolean(errors.message?.text?.body?.type)} helperText={errors.message?.text?.body?.message} />;
            }} />
								</FormControl>}
							<Box>
								{watchType === AskQuestionType.BUTTON && <>
										<FormControl fullWidth>
											<CustomLabel>
												{formatMessage({
                    defaultMessage: 'Buttons'
                  })}
											</CustomLabel>
											<Controller name="message.interactive.action.buttons" rules={{
                  validate: {
                    required: (value: any) => {
                      if (!value?.length) {
                        return '';
                      } else {
                        const emptys = [];
                        for (let i = 0; i < value.length; i++) {
                          if (!value[i].reply.title) {
                            emptys.push(i);
                          }
                        }
                        return emptys.length ? emptys.join('-') : true;
                      }
                    },
                    duplicate: (value: any) => {
                      const list: Array<any> = [];
                      let duplicateIndex;
                      for (let i = 0; i < value.length; i++) {
                        if (!list.includes(value[i].reply.title)) {
                          list.push(value[i].reply.title);
                        } else {
                          duplicateIndex = i;
                          break;
                        }
                      }
                      return duplicateIndex === undefined ? true : `${duplicateIndex}`;
                    }
                  }
                }} control={control} render={({
                  field
                }) => {
                  return <Drag helperText={errors?.message?.interactive?.action?.buttons?.message} error={errors?.message?.interactive?.action?.buttons?.type} {...field} maxNodeHandleNumber={maxNodeHandleNumber} onAddButton={updateMaxNodeHandleNumber} />;
                }} />
										</FormControl>
									</>}
								{watchType === AskQuestionType.LIST && <Stack spacing={2}>
										<FormControl fullWidth>
											<CustomLabel>
												{formatMessage({
                    defaultMessage: 'Button text'
                  })}
											</CustomLabel>
											<TextField placeholder={formatMessage({
                  defaultMessage: 'Please enter'
                })} error={Boolean(errors?.message?.interactive?.action?.button?.type)} helperText={<>{errors?.message?.interactive?.action?.button?.message}</>} {...register('message.interactive.action.button', {
                  required: formatMessage({
                    defaultMessage: 'Required'
                  }),
                  maxLength: {
                    value: 20,
                    message: formatMessage({
                      defaultMessage: 'Please enter no more than 20 characters'
                    })
                  }
                })} />
										</FormControl>
										<FormControl fullWidth>
											<Controller name="message.interactive.action.sections" control={control} rules={{
                  validate: {
                    required: (value: any) => {
                      if (!value?.length) {
                        return '';
                      } else {
                        const emptys = [];
                        for (let i = 0; i < value.length; i++) {
                          const rows = value[i].rows;
                          for (let j = 0; j < rows.length; j++) {
                            if (!rows[j]?.title) {
                              emptys.push(`${i}_${j}`);
                            }
                          }
                        }
                        return emptys.length ? emptys.join('-') : true;
                      }
                    },
                    rowRequired: (value: any) => {
                      const rowEmpty = [];
                      for (let i = 0; i < value.length; i++) {
                        if (!value[i]?.rows?.length) {
                          rowEmpty.push(i);
                        }
                      }
                      return rowEmpty.length ? rowEmpty.join('-') : true;
                    },
                    titleRequired: (value: any) => {
                      if (value?.length > 1) {
                        const emptyTitles = [];
                        for (let i = 0; i < value.length; i++) {
                          if (!value[i].title) {
                            emptyTitles.push(i);
                          }
                        }
                        return emptyTitles.length ? emptyTitles.join('-') : true;
                      } else {
                        return true;
                      }
                    }
                  }
                }} render={({
                  field
                }) => {
                  return <ListDrag {...field} error={errors?.message?.interactive?.action?.sections?.type} helperText={errors?.message?.interactive?.action?.sections?.message} maxNodeHandleNumber={maxNodeHandleNumber} onAddButton={updateMaxNodeHandleNumber} />;
                }} />
										</FormControl>
									</Stack>}
								{isWhatsAppFlowQuestion && <Stack spacing={2}>
										<FormControl fullWidth>
											<CustomLabel>
												{formatMessage({
                    defaultMessage: 'Button text'
                  })}
											</CustomLabel>
											<Controller name={'message.interactive.action.parameters.flow_cta'} control={control} rules={{
                  required: formatMessage({
                    defaultMessage: 'Required'
                  }),
                  maxLength: {
                    value: 20,
                    message: formatMessage({
                      defaultMessage: 'Please enter no more than 20 characters'
                    })
                  }
                }} render={({
                  field
                }) => <TextCountInput {...field} placeholder={formatMessage({
                  defaultMessage: 'Please enter'
                })} maxLen="20" error={Boolean(get(errors, 'message.interactive.action.parameters.flow_cta.type'))} helperText={<>{get(errors, 'message.interactive.action.parameters.flow_cta.message')}</>} pRight="60px" />} />
										</FormControl>
										<FormControl>
											<CustomLabel>
												{formatMessage({
                    defaultMessage: 'Select Waba'
                  })}
											</CustomLabel>
											<Controller name="message.interactive.action.parameters.wabaId" control={control} rules={{
                  required: formatMessage({
                    defaultMessage: 'Required'
                  })
                }} render={({
                  field
                }) => <WabaSelector {...field} onChange={e => {
                  setValue('message.interactive.action.parameters.wabaId', e.target.value, {
                    shouldValidate: true
                  });
                  if (watchFlowId) {
                    setValue('message.interactive.action.parameters.flow_id', '');
                  }
                }} disabled={wabaSelectDisabled} helperText={get(errors, 'message.interactive.action.parameters.wabaId.message')} error={Boolean(get(errors, 'message.interactive.action.parameters.wabaId.type'))} />} />
											{wabaSelectDisabled && <Stack direction="row" spacing={0.5}>
													<InfoRoundedIcon sx={{
                    fontSize: '16px',
                    color: 'text.secondary'
                  }} />
													<FormHelperText>
														<FormattedMessage defaultMessage={'The WABA for the flow must be consistent with the WABA to which the templates in the trigger belong.'} />
													</FormHelperText>
												</Stack>}
										</FormControl>
										{selectedWabaId && <FormControl>
												<CustomLabel>
													{formatMessage({
                    defaultMessage: 'Select WhatsApp Flow'
                  })}
												</CustomLabel>
												<Controller name="message.interactive.action.parameters.flow_id" control={control} rules={{
                  required: formatMessage({
                    defaultMessage: 'Required'
                  })
                }} render={({
                  field
                }) => <WhatsAppFlowSelector wabaId={selectedWabaId} {...field} error={Boolean(get(errors, 'message.interactive.action.parameters.flow_id.type'))} helperText={get(errors, 'message.interactive.action.parameters.flow_id.message')} />} />
											</FormControl>}
									</Stack>}
							</Box>
						</Stack>
					</CustomAccordion>
					{!isWhatsAppFlowQuestion && <CustomAccordion size={Size.MEDIUM} expanded={expand.includes('answer')} title={formatMessage({
        defaultMessage: 'Answer'
      })} onChange={() => handleExpandChange('answer')}>
							{(watchType === AskQuestionType.BUTTON || watchType === AskQuestionType.LIST) && <Stack direction="row" spacing={1} sx={{
          px: 3,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
									<CustomLabel>
										<FormattedMessage defaultMessage={'Limit user to answer once only'} />
										<CustomTooltip tip={formatMessage({
              defaultMessage: 'Once enable this feature, customer can only choose one answer, the second choice will be ignored.'
            })} />
									</CustomLabel>
									<FormControl>
										<Controller name="triggerOnce" control={control} render={({
              field
            }) => <Switch {...field} checked={field.value} />} />
									</FormControl>
								</Stack>}

							<Box sx={{
          px: 3,
          py: 1,
          display: 'flex',
          columnGap: '8px'
        }}>
								<FormControl sx={{
            flex: 1
          }}>
									<CustomLabel>
										{formatMessage({
                defaultMessage: 'Save answer to'
              })}
									</CustomLabel>
									<Controller rules={{
              required: watchMessageType === 'text'
            }} name="saveAnswer.toVariable" control={control} render={({
              field
            }) => {
              return <>
													<VariableInput {...field} creatable ignoreVariables={['contact', 'preset']} />
													{get(errors, 'saveAnswer.toVariable') && <ErrorHelperText>
															{formatMessage({
                    defaultMessage: 'Required'
                  })}
														</ErrorHelperText>}
												</>;
            }} />
								</FormControl>
								<FormControl>
									<CustomLabel>
										{formatMessage({
                defaultMessage: 'Type'
              })}
									</CustomLabel>
									<TextField disabled value={formatMessage({
              defaultMessage: 'Text'
            })} />
								</FormControl>
							</Box>
						</CustomAccordion>}
				</>}
		</>;
};
export default AskQuestionConfigEditor;