import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Box, IconButton, Menu, MenuItem, Popper, SxProps, Tooltip } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useState, useEffect, FunctionComponent, ReactNode } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import SwitchAccount from 'src/app/template/setting/switchAccount';
import TwoFactorAuthDialog from 'src/app/template/setting/twoFactorAuthDialog';
import { SettingBlack, Check, SwitchIcon } from 'src/app/icons';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { CODE } from 'src/app/const/code';
import { logoutAsync } from 'src/features/login/loginSlice';
import { getUserAsync, selectUser, updateUserProfileAsync } from 'src/features/user/userSlice';
import { tenantSwitchAsync } from 'src/features/member/memberSlice';
import { showMessage } from 'src/utils/message';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
import { getIsExpand } from 'src/features/setting/settingSlice';
import LongTextEllipsis from 'src/app/components/LongTextEllipsis';
import { updateJourneyHasUnsavedChange } from 'src/features/journey/journeySlice';
import { updateBotHasUnsavedChange } from 'src/features/bot/botHistorySlice';
import { useDocsAddress } from 'src/hooks/useDocsPath';
import { LANG_OPTIONS } from 'src/app/const';
import { selectLocale, setLang } from 'src/features/intl/intlSlice';
import WujieReact from 'wujie-react';
import { Locale } from 'src/features/intl/type';
import Loading from 'src/app/components/loading';
const {
  bus
} = WujieReact;
import { clearAllData } from 'src/utils/tools';
export enum AgentOnlineStatus {
  ONLINE = 'ONLINE',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE',
}
export const onlineStatusColorMap = {
  [AgentOnlineStatus.ONLINE]: '#11BB82',
  [AgentOnlineStatus.BUSY]: '#FFC107'
};
export const AvatarComponent: FunctionComponent<{
  title: string;
  avatar?: string;
  sx?: any;
  boxSx?: any;
  status?: AgentOnlineStatus;
  showStatus?: boolean;
  hasFilter?: boolean;
}> = ({
  title,
  sx,
  avatar,
  status,
  showStatus = true,
  hasFilter = true,
  boxSx
}) => {
  const hasStatus = showStatus && status && onlineStatusColorMap[status as keyof typeof onlineStatusColorMap];
  return <Box sx={{
    position: 'relative',
    ...boxSx
  }}>
			<Avatar {...avatar ? {
      src: avatar
    } : {}} sx={{
      width: '32px',
      height: '32px',
      backgroundColor: 'secondary.bg',
      color: 'secondary.main',
      fontSize: '14px',
      fontWeight: 400,
      textAlign: 'center',
      ...(avatar && status === AgentOnlineStatus.OFFLINE && hasFilter && {
        '& img': {
          filter: 'grayscale(100%)!important'
        }
      }),
      ...(sx && sx)
    }}>
				{avatar ? null : title}
			</Avatar>
			{hasStatus && <Box sx={{
      display: 'inline-block',
      width: '6px',
      height: '6px',
      background: onlineStatusColorMap[status as keyof typeof onlineStatusColorMap],
      borderRadius: '50%',
      position: 'absolute',
      bottom: '1px',
      right: '1px'
    }} />}
		</Box>;
};
export const getFirstLetter = (name: string) => {
  if (!name) {
    return '';
  } else {
    return name[0].toUpperCase();
  }
};
const MenuItemComponent: FunctionComponent<{
  showArrow?: boolean;
  icon?: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}> = ({
  showArrow,
  children,
  sx,
  icon
}) => {
  return <Box sx={{
    padding: '12px 24px',
    fontSize: '16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...sx
  }}>
			{children}
			{showArrow && (icon ? icon : <ArrowForwardIosRoundedIcon sx={{
      fontSize: '14px'
    }} />)}
		</Box>;
};
const CustomAvatar = () => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const isExpand = useAppSelector(getIsExpand);
  const locale = useAppSelector(selectLocale);
  const user = useAppSelector(selectUser);
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [helpCenterAnchorEl, setHelpCenterAnchorEl] = useState<null | HTMLElement>(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);
  const [loginUser, setLoginUser] = useState<string>('');
  const [isSwitch, setIsSwitch] = useState<boolean>(false);
  const [FAOpen, setFAOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleLogout = async () => {
    const res = await dispatch(logoutAsync());
    if (res.payload) {
      const lastPath = encodeURIComponent(window.location.href);
      clearAllData();
      navigate(`/entry/login?lastPath=${lastPath}`);
    }
  };
  const changeLang = async (value: Locale) => {
    setIsLoading(true);
    try {
      dispatch(updateUserProfileAsync({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        avatar: user?.avatar || '',
        dashboardLanguage: value
      })).unwrap().then(async (res: any) => {
        if (res.code === CODE.OK) {
          bus.$emit('langChange', value);
          dispatch(setLang(value));
          return await dispatch(getUserAsync(false));
        }
      }).finally(() => {
        setIsLoading(false);
      });
    } catch {
      setIsLoading(false);
    }
  };
  const open = Boolean(anchorEl);
  const openHelpCenter = Boolean(helpCenterAnchorEl);
  const openLanguageAnchorEl = Boolean(languageAnchorEl);
  const handleClose = () => {
    setHelpCenterAnchorEl(null);
    setLanguageAnchorEl(null);
    setAnchorEl(null);
  };
  const handleNavigateAndRemember = (path: string) => {
    handleClose();
    const currentLocation = location.pathname;
    sessionStorage.setItem('subMenuBackPath', currentLocation);
    navigate(path);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const helpCenterItemClick = (url: string, e: any) => {
    setHelpCenterAnchorEl(null);
    e.stopPropagation();
    window.open(url);
  };
  const handleSwitchClose = () => {
    handleClose();
    setIsSwitch(false);
  };
  const avatarTitle = getFirstLetter(user?.firstName);
  const handleChangeLoginUser = async (e: any) => {
    if (!e) {
      return;
    }
    const tenantId = e.tenantId;
    const res: any = await dispatch(tenantSwitchAsync({
      tenantId
    }));
    if (res.payload.code === CODE.OK) {
      dispatch(updateJourneyHasUnsavedChange(false));
      dispatch(updateBotHasUnsavedChange(false));
      setLoginUser(tenantId);
      handleSwitchClose();
      setTimeout(() => {
        const redirectPath = params.get('redirectPath');
        if (redirectPath) {
          navigate(decodeURIComponent(redirectPath));
        }
        if (window.BroadcastChannel) {
          const broadcastChannel = new BroadcastChannel('broadcastChannelEvent');
          broadcastChannel.postMessage({
            type: 'userChange'
          });
        }
      }, 300);
    } else if (res.payload.code === CODE.LOGIN_NO_ACCOUNT) {
      navigate('/entry/createCompany', {
        replace: true
      });
    } else if (res.payload.code === CODE.TWO_FACTOR_AUTH) {
      setFAOpen(true);
    } else {
      showMessage({
        message: res.payload.msg,
        type: 'error'
      });
    }
  };
  const onTFAOk = () => {
    handleSwitchClose();
    navigate(0);
  };
  useEffect(() => {
    setLoginUser(user?.accountId);
  }, [user]);
  const {
    docsAddressPrev
  } = useDocsAddress();
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }}>
			<Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: isExpand ? 'space-between' : 'center',
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: isExpand ? '16px 24px' : '16px 0',
      '&:hover': {
        background: '#F5F4F9'
      }
    }} onClick={handleClick}>
				<Box sx={{
        display: 'flex',
        columnGap: 1,
        alignItems: 'center',
        width: 0,
        flex: 1,
        justifyContent: 'center'
      }}>
					<AvatarComponent status={user?.onlineStatus} hasFilter={false} avatar={user?.avatar} title={avatarTitle} sx={{
          margin: 0
        }} />
					{isExpand && <Box sx={{
          width: 0,
          flex: 1
        }}>
							<LongTextEllipsis sx={{
            fontSize: '14px',
            color: '#1A1E22',
            fontWeight: 600,
            lineHeight: '22px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
          }} text={user?.firstName} />
							<LongTextEllipsis sx={{
            fontSize: '12px',
            color: '#A29DAE',
            fontWeight: 400,
            lineHeight: '18px'
          }} text={user?.companyName} />
						</Box>}
				</Box>
				{isExpand && <ArrowForwardIosRoundedIcon sx={{
        fontSize: '14px'
      }} />}
			</Box>
			<Menu anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} anchorEl={anchorEl} open={open} onClose={handleClose}>
				<Box sx={{
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 24px',
        boxSizing: 'border-box',
        '&:hover': {
          background: 'transparent!important'
        }
      }}>
					<Box sx={{
          display: 'grid',
          gridTemplateAreas: '"a b d" "a c d"',
          gridTemplateRows: '21px 21px',
          gridTemplateColumns: '42px 1fr 18px',
          columnGap: '16px'
        }}>
						<AvatarComponent showStatus={false} hasFilter={false} avatar={user?.avatar} title={avatarTitle} boxSx={{
            gridArea: 'a',
            margin: 0,
            width: '42px',
            height: '42px'
          }} sx={{
            width: '100%',
            height: '100%'
          }} />
						<LongTextEllipsis text={formatMessage({
            defaultMessage: '{a} {b}'
          }, {
            a: user?.firstName,
            b: user?.lastName
          })} sx={{
            gridArea: 'b',
            color: '#1A1E22',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '22px',
            textAlign: 'center'
          }} />
						<LongTextEllipsis text={user?.loginAccount} sx={{
            gridArea: 'c',
            color: '#898395',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
            textAlign: 'center'
          }} />
						<Tooltip arrow placement="top" title={formatMessage({
            defaultMessage: 'Your preference'
          })}>
							<IconButton sx={{
              borderRadius: '5px',
              gridArea: 'd',
              alignSelf: 'center',
              width: 'max-content',
              height: 'mac-content'
            }}>
								<SettingBlack onClick={() => handleNavigateAndRemember('/app/userSettings/myProfile')} sx={{
                width: '20px',
                height: '20px',
                fill: '#5E596A'
              }} />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<MenuItem sx={{
        p: 0
      }} onMouseEnter={(event: any) => setLanguageAnchorEl(event.currentTarget)} onMouseLeave={() => setLanguageAnchorEl(null)}>
					<MenuItemComponent showArrow>{formatMessage({
            defaultMessage: 'Language'
          })}</MenuItemComponent>
					<Popper anchorEl={languageAnchorEl} placement="right-start" open={openLanguageAnchorEl} sx={{
          zIndex: 3000,
          position: 'relative'
        }}>
						<Box sx={{
            width: '226px',
            backgroundColor: '#fff',
            boxShadow: '0px 2px 15px 0px rgba(26, 30, 35, 0.14), 0px 4px 5px -3px rgba(26, 30, 35, 0.15)',
            borderRadius: '8px'
          }}>
							{LANG_OPTIONS.map(({
              label,
              value
            }) => <MenuItem key={value} sx={{
              padding: '12px 16px',
              fontSize: '14px',
              color: '#212B36',
              fontWeight: 400,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '&:hover': {
                background: '#F5F4F9'
              }
            }} onClick={() => {
              changeLang(value);
            }}>
									{label}
									{locale === value && <Check sx={{
                color: '#602DED'
              }} />}
								</MenuItem>)}
						</Box>
						<Loading open={isLoading} />
					</Popper>
				</MenuItem>
				<Box sx={{
        borderTop: 'solid 1px rgba(215, 213, 223, 0.6)'
      }}>
					<MenuItem disableRipple sx={{
          p: 0,
          '&:hover': {
            cursor: 'default'
          }
        }}>
						<MenuItemComponent sx={{
            padding: '16px 24px 8px'
          }} icon={<IconButton sx={{
            borderRadius: '5px',
            gridArea: 'd',
            alignSelf: 'center',
            width: 'max-content',
            height: 'mac-content'
          }}>
									<SwitchIcon onClick={() => setIsSwitch(true)} />
								</IconButton>} showArrow={user?.tenantNums > 1}>
							<Box sx={{
              display: 'grid',
              gridTemplateAreas: '"a b" "a c"',
              gridTemplateRows: '16px 16px',
              gridTemplateColumns: '32px minmax(0, 200px)',
              columnGap: '16px'
            }}>
								<Box sx={{
                gridArea: 'a',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#EFEAFD',
                borderRadius: '50%',
                fontSize: '16px',
                fontWeight: '400',
                color: '#602DED'
              }}>
									{user?.companyName?.[0]}
								</Box>
								<LongTextEllipsis text={user?.companyName} sx={{
                gridArea: 'b',
                alignSelf: 'center',
                color: 'rgb(26, 30, 34)',
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
              }} />
								<LongTextEllipsis tip={formatMessage({
                defaultMessage: 'Copy company id'
              })} copyTrigger="text" copyText={formatMessage({
                defaultMessage: "You've successfully copied the company id"
              })} text={user?.accountId} sx={{
                gridArea: 'c',
                lineHeight: '18px',
                cursor: 'pointer',
                color: '#898395',
                fontSize: '12px',
                fontWeight: 400,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                '&:hover': {
                  color: '#602DED'
                }
              }} />
							</Box>
						</MenuItemComponent>
					</MenuItem>

					{user?.role === 'ADMIN' && <MenuItem sx={{
          p: 0
        }} onClick={() => handleNavigateAndRemember('/app/settings/companyInfo')}>
							<MenuItemComponent>
								{formatMessage({
              defaultMessage: 'Company settings'
            })}
							</MenuItemComponent>
						</MenuItem>}
					{user?.role !== 'ADMIN' && isOtherBrand ? null : <MenuItem sx={{
          p: 0
        }} onClick={() => {
          if (user?.role === 'ADMIN') {
            handleNavigateAndRemember('/app/billing/overview');
          } else {
            handleNavigateAndRemember('/app/billing/buyCredits');
          }
        }}>
							<MenuItemComponent>
								{formatMessage({
              defaultMessage: 'Billing'
            })}
							</MenuItemComponent>
						</MenuItem>}
					{!isOtherBrand && <MenuItem sx={{
          p: 0
        }} onMouseEnter={(event: any) => setHelpCenterAnchorEl(event.currentTarget)} onMouseLeave={() => setHelpCenterAnchorEl(null)}>
							<MenuItemComponent>
								{formatMessage({
              defaultMessage: 'Help center'
            })}
							</MenuItemComponent>
							<Popper anchorEl={helpCenterAnchorEl} placement="right-end" open={openHelpCenter} sx={{
            zIndex: 3000
          }}>
								<Box sx={{
              backgroundColor: '#fff',
              boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);',
              borderRadius: '4px'
            }}>
									<MenuItem sx={{
                padding: '12px 24px',
                fontSize: '16px',
                color: '#212B36',
                fontWeight: 400,
                lineHeight: '24px'
              }} onClick={e => helpCenterItemClick('https://docs.ycloud.com/reference/introduction', e)}>
										<FormattedMessage defaultMessage={'API docs'} />
									</MenuItem>
									<MenuItem sx={{
                padding: '12px 24px',
                fontSize: '16px',
                color: '#212B36',
                fontWeight: 400,
                lineHeight: '24px'
              }} onClick={e => helpCenterItemClick(docsAddressPrev, e)}>
										<FormattedMessage defaultMessage={'Product docs'} />
									</MenuItem>
								</Box>
							</Popper>
						</MenuItem>}
				</Box>
				<Box sx={{
        borderTop: 'solid 1px rgba(215, 213, 223, 0.6)'
      }}>
					<MenuItem sx={{
          p: 0
        }} onClick={handleLogout}>
						<MenuItemComponent>
							{formatMessage({
              defaultMessage: 'Log out'
            })}
						</MenuItemComponent>
					</MenuItem>
				</Box>
			</Menu>
			{isSwitch && <SwitchAccount open={isSwitch} title={formatMessage({
      defaultMessage: 'Switch Account'
    })} subTitle={formatMessage({
      defaultMessage: 'Please select an account from the following list'
    })} curAccount={loginUser} onChoose={handleChangeLoginUser} onClose={handleSwitchClose}>
					<TwoFactorAuthDialog open={FAOpen} action="switch-tenant" onClose={() => setFAOpen(false)} onOk={onTFAOk} />
				</SwitchAccount>}
		</Box>;
};
export default CustomAvatar;