import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, InputAdornment, Typography, Box, MenuItem, Divider, ListSubheader, ClickAwayListener, Stack, Collapse } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React, { FunctionComponent, ReactNode, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { createVariable } from 'src/features/bot/botHistorySlice';
import { VariableNamespace } from 'src/pages/bot/const';
import { selectVariables } from 'src/features/bot/botHistorySlice';
import { isEmpty } from 'lodash-es';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { CustomStyleTooltip } from 'src/app/components/form/customTooltip';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useForm } from 'react-hook-form';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useTheme from '@mui/material/styles/useTheme';
import { orderBy } from 'lodash-es';
import { ChatBotFlowContext } from 'src/pages/bot/detail/ChatBotFlowProvider';
import { systemAttributeDisplayNameMap } from 'src/pages/contact/contactList';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
import { botPresetVariableLabelMap } from '../../../build/components/configEditor/branchingConfigEditor/components/filter/components/AddFilter';
import { filterFn } from 'src/utils/tools';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDeepCompareEffect } from 'ahooks';
const CustomIconButton: FunctionComponent<{
  onClick: () => void;
  variant?: 'outlined' | 'contained';
  sx?: object;
  children: ReactNode;
}> = ({
  onClick,
  children,
  variant = 'outlined',
  sx
}) => {
  return <Box onClick={onClick} sx={{
    background: variant === 'outlined' ? '#fff' : '#1A1E22',
    border: variant === 'outlined' ? ' 1px solid #A29DAE' : 'none',
    width: '32px',
    height: '32px',
    color: variant === 'outlined' ? '#000000' : '#fff',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...sx
  }}>
			{children}
		</Box>;
};
const VariablesSelect: FunctionComponent<{
  onClick: (e: any) => void;
  onClose: () => void;
  updatePlacement: (v: any) => void;
  ignoreVariables?: string[];
  filterVariables?: (v: any) => boolean;
  creatable?: boolean;
}> = ({
  onClick,
  onClose,
  updatePlacement,
  ignoreVariables = [],
  filterVariables,
  creatable = false
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    filterList
  } = useContext(ChatBotFlowContext);
  const botVariables = useAppSelector(selectVariables);
  const botVariablesText = botVariables.map((item: any) => item.name);
  const [inputValue, setInputValue] = useState<any>('');
  const [isCreating, setIsCreating] = useState(false);
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  const {
    register,
    reset,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const filterVariablesList = filterVariables ? filterVariables : () => true;
  const [actives, setActives] = useState<string[]>([VariableNamespace.BOT, VariableNamespace.PRESET, VariableNamespace.CONTACT]);
  const variables = useMemo(() => {
    return [{
      namespace: VariableNamespace.BOT,
      groupName: formatMessage({
        defaultMessage: 'Bot custom variables'
      }),
      children: orderBy(botVariables.filter((item: any) => filterVariablesList(item)).map((item: any) => ({
        column: item.name,
        displayName: item.name,
        type: 'Text'
      })) || [], ['displayName'])
    }, {
      namespace: VariableNamespace.PRESET,
      groupName: formatMessage({
        defaultMessage: 'Bot preset variables'
      }),
      children: orderBy(['country', 'name', 'phone_number'].filter((item: any) => filterVariablesList(item)).map((item: string) => ({
        column: item,
        displayName: formatMessage(botPresetVariableLabelMap[item as keyof typeof botPresetVariableLabelMap]),
        type: 'Text'
      })), ['displayName']),
      help: formatMessage({
        defaultMessage: 'Bot automatically obtains relevant user data entering the process and stores it in the corresponding variables'
      })
    }, {
      namespace: VariableNamespace.CONTACT,
      groupName: formatMessage({
        defaultMessage: 'Contact attributes'
      }),
      children: orderBy(filterList.filter((item: any) => item.type !== 'Array' && filterVariablesList(item)), ['isCustomAttr', 'displayName']).map((item: any) => ({
        column: item.column,
        displayName: safetyFormatMessage(systemAttributeDisplayNameMap[item.displayName as keyof typeof systemAttributeDisplayNameMap], item.displayName),
        type: item.type
      }))
    }].filter((item: any) => !isEmpty(item.children) && item.namespace && !ignoreVariables.includes(item.namespace) || !item.namespace && !ignoreVariables.includes('bot'));
  }, [botVariables, filterList, ignoreVariables]);
  const filterResult = useMemo(() => {
    return variables.filter((item: any) => creatable && item.namespace === VariableNamespace.BOT || item.children.some((child: any) => filterFn(child, ['column', 'displayName'], inputValue)));
  }, [inputValue, variables]);
  const isExpandOrCollapse = variables.length > 1;
  const changeCollapse = (v: string) => {
    if (!isExpandOrCollapse) {
      return;
    }
    setActives(prev => {
      if (prev.includes(v)) {
        return prev.filter(item => item !== v);
      } else {
        return [...prev, v];
      }
    });
  };
  const handleInput = (e: any) => {
    setInputValue(e.target.value);
    reset({
      creatingValue: e.target.value
    });
  };
  const handleChange = (myValue: any) => {
    if (myValue) {
      onClick(myValue);
    }
  };
  const hasFilterList = useMemo(() => {
    return filterResult.some((item: any) => {
      return creatable && item.namespace === VariableNamespace.BOT || item.children.some((child: any) => filterFn(child, ['column', 'displayName'], inputValue));
    });
  }, [filterResult, inputValue]);
  const boxRef = useRef<any>(null);
  useLayoutEffect(() => {
    updatePlacement(boxRef.current?.getBoundingClientRect()?.height || 0);
  }, []);
  const handleCreateClick = () => {
    reset({
      creatingValue: inputValue
    });
    setIsCreating(true);
  };
  const handleCreateOk = (values: any) => {
    const newValue = values.creatingValue;
    dispatch(createVariable(newValue));
    handleChange({
      column: newValue,
      displayName: newValue,
      namespace: VariableNamespace.BOT
    });
    setIsCreating(false);
  };
  const handleCreateCancel = () => {
    reset({
      creatingValue: ''
    });
    setIsCreating(false);
  };
  useDeepCompareEffect(() => {
    setActives(filterResult.map((item: any) => item.namespace));
  }, [filterResult]);
  return <ClickAwayListener onClickAway={onClose}>
			<Box ref={boxRef}>
				<TextField autoFocus={true} placeholder={formatMessage({
        defaultMessage: 'Search'
      })} InputProps={{
        startAdornment: <InputAdornment position="start">
								<SearchRoundedIcon sx={{
            color: 'primary.main'
          }} />
							</InputAdornment>,
        ...(inputValue !== '' && {
          endAdornment: <InputAdornment position="end">
									<ClearRoundedIcon onClick={() => setInputValue('')} sx={{
              color: 'primary.dark',
              cursor: 'pointer'
            }} />
								</InputAdornment>
        })
      }} value={inputValue} onChange={handleInput} sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          px: '12px!important',
          borderRadius: '4px 4px 0px 0px',
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
          boxSizing: 'border-box',
          '& input': {
            height: '38px',
            px: 0,
            py: 1,
            color: '#1A1E22'
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none'
        }
      }} />

				<>
					{hasFilterList ? filterResult.map((item: any, index: number) => {
          return <Box key={index}>
									{index !== 0 && <Divider />}
									<ListSubheader onClick={() => changeCollapse(item.namespace)}>
										<Stack direction={'row'} sx={{
                alignItems: 'center',
                cursor: 'pointer',
                color: '#1A1E22'
              }}>
											<Typography variant="body1" sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '40px',
                  height: '40px',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
												{item.groupName}
												{item?.help && <CustomStyleTooltip title={item.help} PopperProps={{
                    style: {
                      zIndex: 2000
                    }
                  }}>
														<HelpRoundedIcon sx={{
                      color: 'rgba(26, 30, 34, 0.3)',
                      fontSize: '14px',
                      ml: 1,
                      verticalAlign: 'text-top',
                      cursor: 'pointer'
                    }}></HelpRoundedIcon>
													</CustomStyleTooltip>}
											</Typography>
											{isExpandOrCollapse && <>{actives.includes(item.namespace) ? <ExpandLess /> : <ExpandMore />}</>}
										</Stack>
									</ListSubheader>
									<Collapse key={item.namespace} in={actives.includes(item.namespace)} timeout="auto" unmountOnExit>
										{(item.children || []).filter((child: any) => filterFn(child, ['column', 'displayName'], inputValue)).map((variableItem: any, variableIndex: number) => {
                return <MenuItem key={variableIndex} onClick={() => handleChange({
                  ...variableItem,
                  namespace: item.namespace
                })}>
														<Box sx={{
                    wordBreak: 'noWrap',
                    width: '100%',
                    whiteSpace: 'break-spaces',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
															{variableItem?.displayName}
														</Box>
													</MenuItem>;
              })}
										{item.namespace === VariableNamespace.BOT && creatable && <MenuItem key={item.children.length}>
												{!isCreating ? <Box sx={{
                  color: theme.palette.secondary.main,
                  display: 'flex',
                  alignItems: 'center',
                  fontSizeL: '15px',
                  fontWeight: 600
                }} onClick={handleCreateClick}>
														<AddRoundedIcon />

														<span>
															<FormattedMessage defaultMessage={'Create a new variable'} />
														</span>
													</Box> : <Box sx={{
                  display: 'flex',
                  columnGap: 1
                }}>
														<TextField {...register('creatingValue', {
                    required: formatMessage({
                      defaultMessage: 'Required'
                    }),
                    maxLength: {
                      value: 50,
                      message: formatMessage({
                        defaultMessage: 'Length: 1~50 Characters'
                      })
                    },
                    validate: {
                      duplicate: (value: string) => {
                        if (botVariablesText.includes(value)) {
                          return formatMessage({
                            defaultMessage: 'Duplicate'
                          });
                        }
                        return true;
                      }
                    },
                    pattern: {
                      value: /^[a-z0-9_]+$/,
                      message: formatMessage({
                        defaultMessage: 'Only number, lowercase letter and _'
                      })
                    }
                  })} error={Boolean(errors?.creatingValue?.type)} helperText={<>{errors?.creatingValue?.message}</>} sx={{
                    flex: 1,
                    '& input': {
                      height: '32px !important'
                    }
                  }} placeholder={formatMessage({
                    defaultMessage: 'enter the variable'
                  })} />

														<Stack direction="row" spacing="3px">
															<CustomIconButton onClick={handleSubmit(handleCreateOk)} variant="contained">
																<CheckRoundedIcon sx={{
                        width: '24px',
                        height: '24px'
                      }} />
															</CustomIconButton>
															<CustomIconButton onClick={handleCreateCancel}>
																<CloseRoundedIcon sx={{
                        width: '24px',
                        height: '24px'
                      }} />
															</CustomIconButton>
														</Stack>
													</Box>}
											</MenuItem>}
									</Collapse>
								</Box>;
        }) : <Typography sx={{
          textAlign: 'center',
          py: '30px'
        }}>
							<FormattedMessage defaultMessage={'No results found'} />
						</Typography>}
				</>
			</Box>
		</ClickAwayListener>;
};
export default VariablesSelect;