import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import undoable, { includeAction } from 'redux-undo';
import { applyNodeChanges, Edge, getConnectedEdges, Node } from 'reactflow';
import { nodeConfigMap, NodeErrorType, NodeType } from 'src/features/journey/type';
import { GroupTypes, OperatorTypes } from 'src/pages/journey/detail/components/eventOrDataRule/eventOrDataRuleRender/const';
import { isEmpty, omit, uniq, flatMap } from 'lodash-es';
import { getJourneyById, updateJourney } from './journeyAPI';
import { CATEGORY } from 'src/pages/dashboard/consts';
import { flattenArray, hasValue } from 'src/utils/tools';

export const shopifyOptions = [
	{
		column: 'shopify:order_amount',
		displayName: 'Checkout total amount',
		disallowed: ['__buildin_shopify_cart_created__'],
	},

	{
		column: 'shopify:checkout_link',
		displayName: 'Checkout link',
		allowed: ['__buildin_shopify_cart_created__', '__buildin_shopify_checkout_created__'],
	},
	{
		column: 'shopify:checkout_link_path', // button
		displayName: 'Checkout link',
		allowed: ['__buildin_shopify_cart_created__', '__buildin_shopify_checkout_created__'],
	},
	{
		column: 'shopify:order_link',
		displayName: 'Order link',
		disallowed: ['__buildin_shopify_cart_created__', '__buildin_shopify_checkout_created__'],
	},
	{
		column: 'shopify:order_link_path', // button
		displayName: 'Order link',
		disallowed: ['__buildin_shopify_cart_created__', '__buildin_shopify_checkout_created__'],
	},

	{
		column: 'shopify:order_status',
		displayName: 'Order status',
		disallowed: ['__buildin_shopify_cart_created__', '__buildin_shopify_checkout_created__'],
	},
	{
		column: 'shopify:order_line_item_name',
		displayName: 'Product name',
		disallowed: ['__buildin_shopify_cart_created__'],
	},
];

export const filterShopifyOptions = (item: any, keyword: string) => {
	const { allowed, disallowed } = item;
	if (!isEmpty(allowed) || !isEmpty(disallowed)) {
		if (Array.isArray(disallowed) && disallowed.includes(keyword)) {
			return false;
		}
		if (Array.isArray(allowed) && !allowed.includes(keyword)) {
			return false;
		}
	}
	return true;
};

const checkVars = (data: any, keyword: string) => {
	if (!isEmpty(data.headVars) || !isEmpty(data.bodyVars) || !isEmpty(data.buttonVars)) {
		const vars = [...new Set([...data.headVars, ...data.bodyVars, ...data.buttonVars].filter((item) => item.value.startsWith('shopify:')).map((item) => item.value))];
		if (!isEmpty(vars)) {
			const options = [...new Set(shopifyOptions.filter((item) => filterShopifyOptions(item, keyword)).map((item) => item.column))];
			return vars.every((item) => options.includes(item));
		}
	}
	return true;
};

export const formatFilterData = (filterData: any) => {
	const filters = (filterData?.filters || []).map((filter: any) => ({
		connector: filter.connector,
		conditions: (filter?.conditions || []).map((condition: any) => omit(condition, ['displayName', 'type', 'values'])),
	}));

	return { connector: 'AND', ...filterData, filters: filters };
};

export const findAllPaths = (edges: any[], start: string, path: any[] = [], allPaths: any[] = [], visited: Set<string> = new Set()) => {
	path.push(start);
	visited.add(start); // Mark the current node as visited
	let hasEnd = false;
	for (const edge of edges) {
		if (edge.source === start && !visited.has(edge.target)) {
			// Check if the target node has not been visited
			hasEnd = true;
			findAllPaths(edges, edge.target, path, allPaths, visited); // Pass the visited set to the recursive call
		}
	}
	if (!hasEnd) {
		allPaths.push([...path]);
	}
	path.pop();
	visited.delete(start); // Remove the current node from visited set when backtracking
	return allPaths;
};

export const traceBack = (start: string, end: string, edges: any[]): string[] | null => {
	for (const edge of edges) {
		if (edge.target === start) {
			if (edge.source === end) {
				return [end];
			} else {
				const path: any = traceBack(edge.source, end, edges);
				if (path) {
					return [...path, edge.source];
				}
			}
		}
	}
	return null;
};

export const getConnectionNodeIds = (nodes: any[], edges: any[]) => {
	const triggerNode: any = nodes.find((node) => node.type === NodeType.TRIGGER);
	if (!triggerNode) {
		return [];
	}
	return uniq([triggerNode.id, ...flatMap(findAllPaths(edges, triggerNode.id))]);
};

const checkValues = (fields: any[] = [], target: any = {}) => {
	return fields.every((field) => {
		if (Array.isArray(field)) {
			return field.some((key) => hasValue(target[key]));
		}

		return hasValue(target[field]);
	});
};

export const checkTriggerOrExitRuleHasError = (data: any, extraParams: any = {}, isRequired?: boolean) => {
	if (isEmpty(data)) {
		return !!isRequired;
	}

	const { contactAttributesMapping = {}, tagsMapping = {}, shopifyEvents = [], customEvents = [] } = extraParams;
	if (data.group === GroupTypes.CONTACT_ATTRIBUTES) {
		if ((isEmpty(data?.eventAttribute?.column) || isEmpty(data?.eventAttribute?.operator) || !hasValue(data?.eventAttribute?.value)) && data?.eventAttribute?.timeStamp > 0) {
			return true;
		} else {
			if (!contactAttributesMapping[data.eventAttribute.column]) {
				return true;
			} else if (data.eventAttribute.column === 'tags' && ![OperatorTypes.HAS_ANY_VALUE, OperatorTypes.IS_UNKNOWN].includes(data.eventAttribute.operator) && !tagsMapping[data.eventAttribute.value]) {
				return true;
			}
		}
	}
	if (data.group === GroupTypes.SHOPIFY_EVENTS) {
		const shopifyEvent = shopifyEvents.find((item: any) => item.value === data.eventName);
		if (!shopifyEvent) {
			return true;
		}

		if (!isEmpty(data?.eventAttribute)) {
			if ((isEmpty(data.eventAttribute?.column) || isEmpty(data.eventAttribute?.operator) || !hasValue(data.eventAttribute?.value)) && data.eventAttribute?.timeStamp > 0) {
				return true;
			}
			const attributes = (shopifyEvent.attributes || []).map((item: any) => item.value);
			return !isEmpty(data.eventAttribute) && !attributes.includes(data.eventAttribute.column);
		}
	}
	if (data.group === GroupTypes.CUSTOM_EVENTS) {
		const customEvent = customEvents.find((item: any) => item.value === data.eventName);
		if (!customEvent) {
			return true;
		}
		if (!isEmpty(data?.eventAttribute)) {
			if ((isEmpty(data.eventAttribute?.column) || isEmpty(data.eventAttribute?.operator) || !hasValue(data.eventAttribute?.value)) && data.eventAttribute?.timeStamp > 0) {
				return true;
			}
			const attributes = (customEvent.attributes || []).map((item: any) => item.value);
			return !isEmpty(data.eventAttribute) && !attributes.includes(data.eventAttribute.column);
		}
	}
	return false;
};

export const checkRulesOrGoalHasError = (data: any, extraParams: any = {}, isRequired?: boolean) => {
	if (isEmpty(data)) {
		return !!isRequired;
	}
	const { contactAttributesMapping = {}, tagsMapping = {} } = extraParams;
	const filters = flattenArray((data?.filters || []).map((item: any) => item.conditions));
	if (isEmpty(filters)) {
		return true;
	}
	return filters.some((item: any) => !item.column || !item.operator || !hasValue(item.value) || (item.column && !contactAttributesMapping[item.column]) || (item.column === 'tags' && !tagsMapping[item.value]));
};

export const checkTagsHasError = (data: any, extraParams: any = {}) => {
	const { tagsMapping = {} } = extraParams;
	return data.some((item: any) => !tagsMapping[item?.id]);
};

const defaultViewPort = {
	x: 0,
	y: 0,
	zoom: 1,
};

type JourneySliceState = {
	nodes: Array<Node>;
	edges: Array<Edge>;
	viewport: typeof defaultViewPort;
	errors: {
		showResult: boolean;
		errors: Array<Error>;
	};
	basicInfo: any;
	isNodeMenuFold: boolean;
	hasUnsavedChange: boolean;
	maxNodeIdNumber: number;
	isGettingJourneyData: boolean;
	isSavingJourneyData: boolean;
	extendOriginStruct: any;
};

const initialState: JourneySliceState = {
	nodes: [], // 储存ReactFlow节点
	edges: [], // 储存ReactFlow连接线
	viewport: defaultViewPort, // 储存ReactFlow视图位置
	errors: {
		showResult: false,
		errors: [],
	}, // 储存ReactFlow节点检查报错，这个仅在前端使用，后端数据无需存储
	isNodeMenuFold: false,
	maxNodeIdNumber: 0,
	basicInfo: {},
	isGettingJourneyData: false,
	isSavingJourneyData: false,
	hasUnsavedChange: false,
	extendOriginStruct: {},
};

export const getJourneyByIdAsync = createAsyncThunk('journey/getJourneyById', async (params: { id: string; extraParams: any }) => {
	const { extraParams, id } = params;
	const [error, data] = await getJourneyById(id);
	if (!error) {
		return { data, extraParams };
	}
	return {};
});

export const updateJourneyAsync = createAsyncThunk('journey/updateJourney', async (params: any, { getState }) => {
	const state: any = getState();

	const current = state.journey.present;

	const { nodes = [], edges = [], basicInfo = {}, viewport, maxNodeIdNumber } = current;

	const extendOriginStruct: any = {};

	const connectionNodeIds = getConnectionNodeIds(nodes, edges);

	const mapperStruct = nodes
		.filter((node: any) => isEmpty(node?.data?.errors) && connectionNodeIds.includes(node.id))
		.map((node: any) => {
			const { id, data, type } = node;
			const { config = {} } = data;
			const connectedEdges = getConnectedEdges([node], edges).filter((edge) => edge.source === id);

			if (node.type === NodeType.TRIGGER) {
				const { trigger = {}, goal = {}, exitRule = {}, maxFollowingTime } = config;
				const triggerType = trigger.group;
				if (!isEmpty(goal)) {
					extendOriginStruct.goal = {
						...(goal.eventName && { eventName: goal.eventName }),
						...(!isEmpty(goal.eventAttribute) && {
							key: goal?.eventAttribute.column,
							operator: goal?.eventAttribute.operator,
							exceptVal: goal?.eventAttribute.value,
						}),
						maxFollowingTime: maxFollowingTime * 24 * 60,
					};
				}

				if (!isEmpty(exitRule)) {
					extendOriginStruct.exitRule = {
						eventExit: [GroupTypes.CUSTOM_EVENTS, GroupTypes.SHOPIFY_EVENTS].includes(exitRule.group),
						...(exitRule.eventName && { eventName: exitRule.eventName }),
						...(!isEmpty(exitRule.eventAttribute) && {
							connector: 'AND',
							filters: [
								{
									connector: 'AND',
									conditions: [
										{
											column: exitRule.eventAttribute.column,
											operator: exitRule.eventAttribute.operator,
											value: exitRule.eventAttribute.value,
										},
									],
								},
							],
						}),
					};
				}

				return {
					nodeId: +id,
					type: type,
					startNodes: connectedEdges.map((item) => item.target), // 不变
					triggerType,
					senderId: config?.sender?.id, // 不变
					attachFilterCondition: isEmpty(config?.rules) ? null : formatFilterData(config?.rules),
					unsubscribeFilter: config?.unsubscribeFilter, // 不变
					blocklistFilter: config?.blocklistFilter, // 不变
					strategy: config?.strategy, // 不变
					...(triggerType === GroupTypes.CONTACT_ATTRIBUTES && {
						monitorKey: trigger?.eventAttribute?.column,
						exceptVal: trigger?.eventAttribute?.value,
						operator: trigger?.eventAttribute?.operator,
					}),
					...(triggerType === GroupTypes.SHOPIFY_EVENTS && {
						exceptEvent: trigger?.eventName.match(/__buildin_shopify_(.*?)__/)?.[1]?.toUpperCase(),
						property: trigger?.eventAttribute?.column,
						operator: trigger?.eventAttribute?.operator,
						exceptVal: trigger?.eventAttribute?.value,
					}),
					...(triggerType === GroupTypes.CUSTOM_EVENTS && {
						eventName: trigger?.eventName,
						property: trigger?.eventAttribute?.column,
						operator: trigger?.eventAttribute?.operator,
						exceptVal: trigger?.eventAttribute?.value,
					}),
				};
			} else if (node.type === NodeType.SEND_TEMPLATE_MESSAGE) {
				const { languageTemplate, headVars, headerMediaVar, bodyVars, buttonVars } = config || {};
				const { name, language, category } = languageTemplate || {};
				const paramComponentList = [];
				if (!isEmpty(headerMediaVar)) {
					paramComponentList.push({
						type: 'header',
						paramDetailList: [headerMediaVar],
					});
				}
				if (!isEmpty(headVars)) {
					paramComponentList.push({
						type: 'header',
						paramDetailList: headVars.map((item: any) => ({
							name: item.name,
							fixValue: item?.isFixValue ? item.value : '',
							paramColumn: item?.isFixValue ? '' : item?.accuracy && item?.timezone ? `${item.value}:${item.accuracy},${item.timezone}` : item.value,
						})),
					});
				}
				if (!isEmpty(bodyVars)) {
					paramComponentList.push({
						type: 'body',
						paramDetailList: bodyVars.map((item: any) => ({
							name: item.name,
							fixValue: item?.isFixValue ? item.value : '',
							paramColumn: item?.isFixValue ? '' : item?.accuracy && item?.timezone ? `${item.value}:${item.accuracy},${item.timezone}` : item.value,
						})),
					});
					if (category === CATEGORY.AUTHENTICATION) {
						paramComponentList.push({
							type: 'button',
							paramDetailList: bodyVars.map((item: any) => ({
								name: item.name,
								fixValue: item?.isFixValue ? item.value : '',
								paramColumn: item?.isFixValue ? '' : item.value,
							})),
						});
					}
				}
				if (!isEmpty(buttonVars)) {
					paramComponentList.push({
						type: 'button',
						paramDetailList: buttonVars.map((item: any) => ({
							name: item.name,
							fixValue: item?.isFixValue ? item.value : '',
							paramColumn: item?.isFixValue ? '' : item?.accuracy && item?.timezone ? `${item.value}:${item.accuracy},${item.timezone}` : item.value,
						})),
					});
				}
				return {
					nodeId: +id,
					type: type,
					nextNodesId: connectedEdges.map((item) => item.target),
					templateName: name,
					language: language,
					paramComponentList: paramComponentList,
				};
			} else if (node.type === NodeType.ADD_TAG) {
				return {
					nodeId: +id,
					type: type,
					nextNodesId: connectedEdges.map((item) => item.target),
					tagIds: (config?.tagList || []).map((item: any) => item.id),
				};
			} else if (node.type === NodeType.WAIT) {
				return {
					nodeId: +id,
					type: type,
					nextNodesId: connectedEdges.map((item) => item.target),
					delayMinute: config?.minutes + config?.hours * 60 + config?.days * 24 * 60,
				};
			} else if (node.type === NodeType.MESSAGE_STATUS_RULE) {
				return {
					nodeId: +id,
					type: type,
					watchMessageNodeId: config?.template?.nodeId,
					watchDuration: config?.minutes + config?.hours * 60 + config?.days * 24 * 60,
					exceptStatus: config?.exceptStatus?.toUpperCase(),
					matchNodesId: connectedEdges.filter((item) => item.sourceHandle === '1').map((item) => item.target),
					otherwiseNodesId: connectedEdges.filter((item) => item.sourceHandle === '0').map((item) => item.target),
				};
			}
		});

	const [error, data] = await updateJourney({
		...basicInfo,
		...{
			originStruct: JSON.stringify({
				nodes,
				edges,
				viewport,
				maxNodeIdNumber,
			}),
			mapperStruct: mapperStruct,
		},
		...params,
		...extendOriginStruct,
	});
	if (!error) {
		return data;
	}
	return {};
});

export const journeySlice = createSlice({
	name: 'journey',
	initialState,
	reducers: {
		// 节点更新操作
		updateNodes: (state, params) => {
			state.nodes = [...params.payload];
			state.hasUnsavedChange = true;
		},
		// 连接线更新操作
		updateEdges: (state, params) => {
			state.edges = [...params.payload];
			state.hasUnsavedChange = true;
		},
		// 同时更新节点和连接线，此方法存在的意义是避免了记录两次操作记录
		updateNodesAndEdges: (state, params) => {
			state.edges = [...params.payload.edges];
			state.nodes = [...params.payload.nodes];
			state.hasUnsavedChange = true;
		},
		setViewport: (state, params) => {
			state.viewport = params.payload;
			// state.hasUnsavedChange = true
		},
		// 该方法仅适用于节点位置更新，不需要更新flowData，不会记录进历史操作
		updateNodesPosition: (state, params) => {
			if (params.payload.some((item: any) => item.type === 'position' && item.dragging)) {
				state.hasUnsavedChange = true;
			}
			state.nodes = applyNodeChanges(params.payload, state.nodes);
		},
		// 该方法仅适用于连接线样式更新，无需更新flowData，不会记录进历史操作
		updateEdgeStyles: (state, params) => {
			state.edges = [...params.payload];
		},
		checkFlow: (state, { payload }) => {
			const { nodes, edges = [] } = state;
			const { isCheck, extraParams = {} } = payload;

			const connectionNodeIds = getConnectionNodeIds(nodes, edges);
			const triggerNode: any = nodes.find((node) => node.type === NodeType.TRIGGER);
			const triggerConfig: any = triggerNode.data.config || {};

			const errors: any[] = [];
			state.nodes = nodes.map((node) => {
				const nodeErrors = [];

				const { type, id, data = {} } = node;

				const { config = {} } = data;

				if (connectionNodeIds.includes(id)) {
					/* 检查Trigger是否有连接点 */
					if (type === NodeType.TRIGGER && !edges.some((edge) => edge.source === id)) {
						nodeErrors.push({ type: NodeErrorType.TRIGGER_WITHOUT_NEXT, nodeId: id });
					}
					if (nodeConfigMap[type as keyof typeof nodeConfigMap]?.checkForm && isEmpty(config)) {
						nodeErrors.push({ type: NodeErrorType.NODE_NO_CONFIG, nodeId: id });
					} else {
						if (type === NodeType.TRIGGER) {
							if ((isEmpty(config.goal) && !checkValues(['trigger', 'sender', 'unsubscribeFilter', 'blocklistFilter', 'strategy'], config)) || (!isEmpty(config.goal) && !checkValues(['trigger', 'sender', 'unsubscribeFilter', 'blocklistFilter', 'strategy', 'goal', 'maxFollowingTime'], config))) {
								/* 校验规则修改 */
								nodeErrors.push({ type: NodeErrorType.PARAMS_ERROR, nodeId: id });
							} else {
								if (checkTriggerOrExitRuleHasError(config.trigger, extraParams, true) || checkTriggerOrExitRuleHasError(config.exitRule, extraParams, false) || checkTriggerOrExitRuleHasError(config?.goal || [], extraParams, false) || checkRulesOrGoalHasError(config?.rules, extraParams)) {
									nodeErrors.push({ type: NodeErrorType.PARAMS_ERROR, nodeId: id });
								}
							}
						}

						if (type === NodeType.SEND_TEMPLATE_MESSAGE) {
							/* 判断 send Template 节点参数是不是依赖 Trigger */
							if (config?.languageTemplate?.wabaId !== triggerNode?.data?.config?.sender?.wabaId || !checkValues(['template', 'languageTemplate'], config) || (triggerConfig.trigger.group === GroupTypes.SHOPIFY_EVENTS && !checkVars(config, triggerConfig.trigger.eventName))) {
								nodeErrors.push({ type: NodeErrorType.PARAMS_ERROR, nodeId: id });
							}
						}

						if (type === NodeType.ADD_TAG) {
							if (!checkValues(['tagList'], config) || checkTagsHasError(config?.tagList || [], extraParams)) {
								nodeErrors.push({ type: NodeErrorType.PARAMS_ERROR, nodeId: id });
							}
						}

						if (type === NodeType.WAIT) {
							if (!checkValues([['days', 'hours', 'minutes']], config)) {
								nodeErrors.push({ type: NodeErrorType.PARAMS_ERROR, nodeId: id });
							}
						}

						if (type === NodeType.MESSAGE_STATUS_RULE) {
							if (!checkValues(['template', 'exceptStatus', ['days', 'hours', 'minutes']], config)) {
								nodeErrors.push({ type: NodeErrorType.PARAMS_ERROR, nodeId: id });
							} else {
								const template = config?.template || {};

								const traceBackIds: string[] = traceBack(id, triggerNode.id, edges) || [];
								const sendTemplateNodes: any = nodes.filter((node) => node.type === NodeType.SEND_TEMPLATE_MESSAGE && traceBackIds.includes(node.id));
								/* 判断 是否有send Template 节点 */
								if (isEmpty(sendTemplateNodes)) {
									nodeErrors.push({ type: NodeErrorType.MESSAGE_STATE_RULE_TEMPLATE_ERROR, nodeId: id });
								} else if (isEmpty(template) || (!isEmpty(template) && !sendTemplateNodes.find((item: any) => item.id === template?.nodeId && item?.data?.config?.languageTemplate?.id === template?.templateId))) {
									nodeErrors.push({ type: NodeErrorType.MESSAGE_STATE_RULE_TEMPLATE_ERROR, nodeId: id });
								}
							}
						}
					}

					errors.push(...nodeErrors);
					return { ...node, data: { ...data, errors: nodeErrors }, selected: false };
				} else {
					return {
						...node,
						data: {
							...data,
							errors: [],
						},
						selected: false,
					};
				}
			});
			state.errors = { showResult: Boolean(isCheck), errors };
		},
		setNodeMenuFold: (state, params) => {
			state.isNodeMenuFold = params.payload;
		},
		updateMaxNodeIdNumber: (state) => {
			state.maxNodeIdNumber = state.maxNodeIdNumber + 1;
		},
		setIsGettingJourneyData: (state, params) => {
			state.isGettingJourneyData = params.payload;
		},
		setIsSavingJourneyData: (state, params) => {
			state.isSavingJourneyData = params.payload;
		},
		updateBaseInfo: (state, { payload }) => {
			state.basicInfo = {
				...state.basicInfo,
				name: payload,
			};
		},
		updateJourneyHasUnsavedChange: (state, { payload }) => {
			state.hasUnsavedChange = payload;
		},
		resetJourney: (state) => {
			state.nodes = [];
			state.edges = [];
			state.viewport = defaultViewPort;
			state.errors = {
				showResult: false,
				errors: [],
			};
			state.isNodeMenuFold = false;
			state.maxNodeIdNumber = 0;
			state.basicInfo = {};
			state.isGettingJourneyData = false;
			state.isSavingJourneyData = false;
			state.hasUnsavedChange = false;
			state.extendOriginStruct = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getJourneyByIdAsync.fulfilled, (state, { payload }) => {
				const { data = {}, extraParams = {} } = payload;
				const { originStruct, triggerType = GroupTypes.CONTACT_ATTRIBUTES } = data;
				const { shopifyEvents = [] } = extraParams;

				try {
					const { nodes = [], edges = [], viewport = defaultViewPort, maxNodeIdNumber = 1, extendOriginStruct = {} } = originStruct ? JSON.parse(originStruct) : {};
					state.basicInfo = data;

					state.nodes = nodes.map((item: any) => {
						const config = item.data.config || {};
						if (item.type === NodeType.TRIGGER) {
							const contactAttribute = config?.event?.filters?.[0]?.conditions?.[0] || {} || {};
							const shopifyEvent = shopifyEvents.find((child: any) => child.value.includes((config?.exceptEvent || {})?.value?.toLowerCase()));
							const exitRule = extendOriginStruct?.exitRule ? extendOriginStruct?.exitRule?.filters?.[0]?.conditions?.[0] : {};
							let goal = extendOriginStruct?.goal ? extendOriginStruct?.goal?.filters?.[0]?.conditions?.[0] : {};
							if (!isEmpty(config?.goal) && Array.isArray(config?.goal?.filters) && config?.goal?.connector) {
								goal = config?.goal?.filters?.[0]?.conditions?.[0];
							}
							return {
								...item,
								data: {
									...item.data,
									config: {
										...config,
										...(extendOriginStruct?.maxFollowingTime && { maxFollowingTime: extendOriginStruct.maxFollowingTime }),
										...(!isEmpty(exitRule) && {
											exitRule: {
												group: GroupTypes.CONTACT_ATTRIBUTES,
												eventName: undefined,
												eventDisplayName: undefined,
												eventAttribute: {
													column: exitRule.column,
													columnDisplayName: exitRule.displayName,
													type: exitRule.type,
													operator: exitRule.operator,
													value: exitRule.value,
													timeStamp: Date.now(),
													values: [], // 需要填充的数据
												},
											},
										}),
										...(!isEmpty(goal) && {
											goal: {
												group: GroupTypes.CONTACT_ATTRIBUTES,
												eventName: undefined,
												eventDisplayName: undefined,
												eventAttribute: {
													column: goal.column,
													columnDisplayName: goal.displayName,
													type: goal.type,
													operator: goal.operator,
													value: goal.value,
													timeStamp: Date.now(),
													values: [], // 需要填充的数据
												},
											},
										}),
										...(triggerType === GroupTypes.CONTACT_ATTRIBUTES &&
											!isEmpty(contactAttribute) && {
												trigger: {
													group: GroupTypes.CONTACT_ATTRIBUTES,
													eventName: undefined,
													eventDisplayName: undefined,
													eventAttribute: {
														column: contactAttribute.column,
														columnDisplayName: contactAttribute.displayName,
														type: contactAttribute.type,
														operator: contactAttribute.operator,
														value: contactAttribute.value,
														timeStamp: Date.now(),
														values: [], // 需要填充的数据
													},
												},
											}),
										...(triggerType === GroupTypes.SHOPIFY_EVENTS &&
											!isEmpty(shopifyEvent) && {
												trigger: {
													group: GroupTypes.SHOPIFY_EVENTS,
													eventName: shopifyEvent.value,
													eventDisplayName: shopifyEvent.label,
													eventAttribute: {},
												},
											}),
									},
								},
							};
						}
						return item;
					});
					state.edges = edges;
					state.viewport = viewport;
					state.maxNodeIdNumber = maxNodeIdNumber;
				} catch (e) {
					console.log(e);
				}
			})
			.addCase(updateJourneyAsync.fulfilled, (state, { payload }) => {
				if (!isEmpty(payload)) {
					const { originStruct } = payload;
					try {
						const { extendOriginStruct = {} } = originStruct ? JSON.parse(originStruct) : {};
						state.basicInfo = payload;
						state.extendOriginStruct = extendOriginStruct;
						state.hasUnsavedChange = false;
					} catch (e) {
						console.log(e);
					}
				}
			});
	},
});

export const selectBaseInfo = (state: any) => {
	return state.journey.present.basicInfo;
};

export const selectNodes = (state: any) => {
	return state.journey.present.nodes;
};

export const selectEdges = (state: any) => {
	return state.journey.present.edges;
};
export const selectViewport = (state: any) => {
	return state.journey.present.viewport;
};
export const selectIsNodeMenuFold = (state: any) => {
	return state.journey.present.isNodeMenuFold;
};
export const selectMaxNodeIdNumber = (state: any) => {
	return state.journey.present.maxNodeIdNumber;
};
export const selectNodeErrors = (state: any) => {
	return state.journey.present.errors;
};
export const selectHasUnsavedChange = (state: any) => {
	return state.journey.present.hasUnsavedChange;
};
export const selectIsGettingJourneyData = (state: any) => {
	return state.journey.present.isGettingJourneyData;
};
export const selectIsSavingJourneyData = (state: any) => {
	return state.journey.present.isSavingJourneyData;
};
export const selectBasicInfo = (state: any) => {
	return state.journey.present.basicInfo;
};
export const selectExtendOriginStruct = (state: any) => {
	return state.journey.present.extendOriginStruct;
};

export const { setNodeMenuFold, checkFlow, updateMaxNodeIdNumber, updateNodesPosition, updateEdgeStyles, updateNodesAndEdges, updateNodes, setViewport, updateEdges, setIsGettingJourneyData, setIsSavingJourneyData, updateBaseInfo, updateJourneyHasUnsavedChange, resetJourney } = journeySlice.actions;

export default undoable(journeySlice.reducer, {
	filter: includeAction(['journey/setNodes', 'journey/setEdges', 'journey/updateFlowData', 'journey/setNodesAndEdges', 'journey/onEdgesChange']),
});
