import { Box } from '@mui/material';
import NavGroup from './NavGroup';
import { useMemo } from 'react';
import { screenMenusByRole } from 'src/routes/menuData';
import { cloneDeep, isEmpty } from 'lodash-es';
import { useAppSelector } from 'src/app/hooks';
import { getBlacklist, selectCustomerType, selectFeatureStatus, selectUser } from 'src/features/user/userSlice';
import { Home, Agent, Contact, Campaign, IntegrationMenuIcon, Bot, Ctwa, Developers, Journey, Partners, Orders } from 'src/app/icons';
import { useIntl } from 'react-intl';
import { CustomerType } from 'src/features/user/type';
import { selectIntlMessages } from 'src/features/intl/intlSlice';
import { selectWabaList } from 'src/features/whatsapp/whatsappSlice';
import { useNavigate } from 'react-router-dom';
const Navigation = () => {
  const {
    role
  } = useAppSelector(selectUser);
  const blacklist = useAppSelector(getBlacklist);
  const customerType = useAppSelector(selectCustomerType);
  const wabaList = useAppSelector(selectWabaList);
  const featureStatus = useAppSelector(selectFeatureStatus);
  const {
    formatMessage
  } = useIntl();
  const navigate = useNavigate();
  const intlMessages = useAppSelector(selectIntlMessages);
  const newMenuData = [{
    type: 'group',
    id: '1',
    level: 0,
    children: [{
      name: formatMessage({
        defaultMessage: 'Home'
      }),
      // 标题
      icon: Home,
      // 图标
      activeKeys: ['dashboard'],
      // 匹配路由是否高亮
      unRoles: ['AGENT', 'SALE'],
      // 不允许的角色
      type: 'collapse',
      id: '1-1',
      level: 1,
      children: [{
        name: formatMessage({
          defaultMessage: 'Getting started'
        }),
        path: '/app/dashboard/getStarted',
        activeKeys: ['getStarted'],
        id: '1-1-1',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'WhatsApp accounts'
        }),
        path: '/app/dashboard/account',
        activeKeys: ['account', 'accountSetting'],
        id: '1-1-2',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Templates'
        }),
        path: '/app/dashboard/template',
        activeKeys: ['template', 'templateSetting', 'templateInsights'],
        id: '1-1-3',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'WhatsApp Flows'
        }),
        path: '/app/dashboard/whatsappFlows',
        activeKeys: ['whatsappFlows'],
        id: '1-1-5',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Analytics'
        }),
        path: '/app/dashboard/analytics',
        activeKeys: ['analytics'],
        id: '1-1-4',
        level: 2
      }]
    }, {
      name: formatMessage({
        defaultMessage: 'Inbox'
      }),
      icon: Agent,
      activeKeys: ['inbox'],
      path: '/app/inbox/chat',
      type: 'collapse',
      id: '1-2',
      level: 1,
      children: [{
        name: formatMessage({
          defaultMessage: 'Online chat'
        }),
        path: '/app/inbox/chat',
        activeKeys: ['chat'],
        hide: true,
        id: '1-2-1',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Analytics'
        }),
        path: '/app/inbox/analytics',
        activeKeys: ['analytics'],
        hide: true,
        id: '1-2-2',
        level: 2
      }]
    }, {
      name: formatMessage({
        defaultMessage: 'Contact'
      }),
      icon: Contact,
      activeKeys: ['contact'],
      unRoles: ['OPERATOR', 'AGENT'],
      // 不允许的角色
      type: 'collapse',
      id: '1-3',
      level: 1,
      children: [{
        name: formatMessage({
          defaultMessage: 'Contact list'
        }),
        path: '/app/contact/contactList',
        activeKeys: ['contactList', 'upload'],
        id: '1-3-1',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Growth Tool'
        }),
        path: '/app/contact/growthTools',
        activeKeys: ['growthTools'],
        unRoles: ['SALE'],
        // 不允许的角色
        id: '1-3-2',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Unsubscribe'
        }),
        path: '/app/contact/unsubscribe',
        activeKeys: ['unsubscribe'],
        unRoles: ['SALE'],
        // 不允许的角色
        id: '1-3-3',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Settings'
        }),
        path: '/app/contact/settings/attributes',
        unRoles: ['SALE'],
        // 不允许的角色
        activeKeys: ['settings'],
        id: '1-3-4',
        level: 2
      }]
    }]
  }, {
    type: 'group',
    id: '2',
    level: 0,
    children: [{
      name: formatMessage({
        defaultMessage: 'Campaign'
      }),
      icon: Campaign,
      path: '/app/bulkMessages/logs',
      activeKeys: ['bulkMessages'],
      unRoles: ['AGENT', 'SALE'],
      // 不允许的角色
      children: [],
      id: '2-1',
      level: 1
    }, {
      name: formatMessage({
        defaultMessage: 'Journey'
      }),
      icon: Journey,
      path: '/app/journey/list',
      activeKeys: ['journey'],
      unRoles: ['AGENT', 'SALE'],
      // 不允许的角色
      id: '2-2',
      level: 1
    }, {
      name: formatMessage({
        defaultMessage: 'Chatbot'
      }),
      icon: Bot,
      path: '/app/chatbot/list',
      activeKeys: ['chatbot'],
      unRoles: ['OPERATOR', 'AGENT', 'SALE'],
      // 不允许的角色
      children: [],
      id: '2-3',
      level: 1
    }, {
      name: formatMessage({
        defaultMessage: 'CTWA'
      }),
      icon: Ctwa,
      activeKeys: ['ctwa'],
      unRoles: ['AGENT', 'SALE'],
      // 不允许的角色
      unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
      path: '/app/ctwa/introduce',
      id: '2-4',
      level: 1
    }, {
      name: formatMessage({
        defaultMessage: 'Orders'
      }),
      icon: Orders,
      activeKeys: ['orders'],
      roles: ['ADMIN', 'OPERATOR'],
      unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
      id: '2-5',
      level: 1,
      onClick: async () => {
        const hasBindWaba = !isEmpty(wabaList);
        if (!hasBindWaba) {
          navigate(`/app/orders/introduce?type=noWaba`);
          return;
        }
        if (!featureStatus.orders) {
          navigate(`/app/orders/introduce?type=noPayment`);
          return;
        }
        navigate(`/app/orders/list`);
      }
    }]
  }, {
    type: 'group',
    id: '3',
    level: 0,
    children: [{
      name: formatMessage({
        defaultMessage: 'Integrations'
      }),
      icon: IntegrationMenuIcon,
      activeKeys: ['integrations'],
      unRoles: ['OPERATOR', 'AGENT', 'SALE'],
      // 不允许的角色
      unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
      type: 'collapse',
      id: '3-1',
      level: 1,
      children: [{
        name: formatMessage({
          defaultMessage: 'All integrations'
        }),
        path: '/app/integrations/all',
        activeKeys: ['all'],
        id: '3-1-1',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'SMS'
        }),
        alignName: 'SMS',
        path: '/app/integrations/sms/getStarted',
        activeKeys: ['sms'],
        id: '3-1-2',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Email'
        }),
        alignName: 'EMAIL',
        path: '/app/integrations/email/getStarted',
        activeKeys: ['email'],
        id: '3-1-3',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Voice Code'
        }),
        alignName: 'VOICE_CODE',
        path: '/app/integrations/voice/getStarted',
        activeKeys: ['voice'],
        id: '3-1-4',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Verify'
        }),
        alignName: 'VERIFY',
        path: '/app/integrations/verify/getStarted',
        activeKeys: ['verify'],
        id: '3-1-5',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Shopify'
        }),
        alignName: 'SHOPIFY',
        path: '/app/integrations/shopify',
        activeKeys: ['shopify'],
        id: '3-1-6',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Coupons'
        }),
        alignName: 'COUPON',
        path: '/app/integrations/coupon/list',
        activeKeys: ['coupon'],
        id: '3-1-7',
        level: 2
      }]
    }, {
      name: formatMessage({
        defaultMessage: 'Developers'
      }),
      icon: Developers,
      activeKeys: ['developers'],
      roles: ['ADMIN', 'DEVELOPER'],
      type: 'collapse',
      id: '3-2',
      level: 1,
      children: [{
        name: formatMessage({
          defaultMessage: 'API key'
        }),
        path: '/app/developers/apikey',
        activeKeys: ['apikey'],
        id: '3-2-1',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Webhooks'
        }),
        path: '/app/developers/webhook',
        activeKeys: ['webhook'],
        id: '3-2-2',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'Failed logs'
        }),
        path: '/app/developers/failedLogs',
        activeKeys: ['failedLogs'],
        unPartnerTypes: [CustomerType.CHANNEL_CUSTOMER],
        roles: ['ADMIN'],
        id: '3-2-3',
        level: 2
      }, {
        name: formatMessage({
          defaultMessage: 'IP whitelists'
        }),
        path: '/app/developers/whitelist',
        activeKeys: ['whitelist'],
        roles: ['ADMIN'],
        id: '3-2-4',
        level: 2
      }]
    }, {
      name: formatMessage({
        defaultMessage: 'Partners'
      }),
      icon: Partners,
      roles: ['ADMIN'],
      path: '/app/partners',
      activeKeys: ['partners'],
      partnerTypes: [CustomerType.CHANNEL],
      level: 1
    }]
  }];
  const formatMenuData = useMemo(() => {
    return screenMenusByRole(cloneDeep(newMenuData), role, blacklist, customerType);
  }, [role, blacklist, customerType, intlMessages]);
  const navGroups = formatMenuData.filter((item: any) => !isEmpty(item.children)).map((item: any) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return null;
    }
  });
  return <Box>{navGroups}</Box>;
};
export default Navigation;