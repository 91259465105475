import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from 'src/features/login/loginSlice';
import shortLinkReducer from 'src/features/shortLink/shortLinkSlice';
import toastReducer from 'src/features/toast/toastSlice';
import userReducer from 'src/features/user/userSlice';
import commonReducer from 'src/features/common/commonSlice';
import smsReducer from 'src/features/sms/smsSlice';
import emailReducer from 'src/features/email/emailSlice';
import voiceReducer from 'src/features/voice/voiceSlice';
import verifyReducer from 'src/features/verify/verifySlice';
import smsAnalyticsReducer from 'src/features/smsAnalytics/smsAnalyticsSlice';
import smsSenderIdReducer from 'src/features/smsSenderId/smsSenderIdSlice';
import billingReducer from 'src/features/billing/billingSlice';
import developersReducer from 'src/features/developers/developersSlice';
import whatsappReducer from 'src/features/whatsapp/whatsappSlice';
import memberReducer from 'src/features/member/memberSlice';
import growthToolsReducer from 'src/features/contact/growthTools/growthToolsSlice';
import contactListReducer from 'src/features/contact/contactList/contactListSlice';
import contactSettingReducer from 'src/features/contact/settings/contactSettingSlice';
import botHistoryReducer from 'src/features/bot/botHistorySlice';
import botReducer from 'src/features/bot/botSlice';
import integrationReducer from 'src/features/integration/integrationSlice';
import brandReducer from 'src/features/brand/brandSlice';
import ctwaReducer from 'src/features/ctwa/ctwaSlice';
import chatBotReducer from 'src/features/chatBot/chatBotSlice';
import settingReducer from 'src/features/setting/settingSlice';
import couponReducer from 'src/features/coupon/couponSlice';
import intlReducer from 'src/features/intl/intlSlice';
import journeyReducer from 'src/features/journey/journeySlice';
import ordersReducer from 'src/features/orders/ordersSlice';

export const store = configureStore({
	reducer: {
		login: loginReducer,
		botHistory: botHistoryReducer,
		toast: toastReducer,
		common: commonReducer,
		shortLink: shortLinkReducer,
		user: userReducer,
		sms: smsReducer,
		email: emailReducer,
		voice: voiceReducer,
		verify: verifyReducer,
		smsAnalytics: smsAnalyticsReducer,
		smsSenderId: smsSenderIdReducer,
		billing: billingReducer,
		developers: developersReducer,
		whatsapp: whatsappReducer,
		member: memberReducer,
		growthTools: growthToolsReducer,
		contactList: contactListReducer,
		contactSetting: contactSettingReducer,
		bot: botReducer,
		integration: integrationReducer,
		brand: brandReducer,
		ctwa: ctwaReducer,
		chatBot: chatBotReducer,
		setting: settingReducer,
		coupon: couponReducer,
		intl: intlReducer,
		journey: journeyReducer,
		orders: ordersReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
