import { useIntl } from 'react-intl';
import SlateEditor, { getNoParamsText } from 'src/app/components/form/slateEditor';
import EmojiPicker from 'src/app/components/emojiPicker';
import { Box, Typography } from '@mui/material';
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import StrikethroughSRoundedIcon from '@mui/icons-material/StrikethroughSRounded';
import { CodeIcon } from 'src/app/icons';
import { useRef, useMemo, forwardRef } from 'react';
import VariableButton from 'src/pages/bot/detail/components/VariableButton';
import CouponButton from 'src/pages/bot/detail/components/CouponButton';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import { useAppSelector } from 'src/app/hooks';
import { botCouponVariablePattern } from 'src/app/components/form/slateEditor/regexp';
import { selectIsOtherBrand } from 'src/features/brand/brandSlice';
export { getNoParamsText };
export const iconStyle = {
  fontSize: '20px',
  color: 'primary.dark',
  p: '10px',
  mr: '4px',
  cursor: 'pointer',
  verticalAlign: 'middle'
};
interface IProps {
  onChange: any;
  value: string;
  [index: string]: any;
}
export type Ref = any;
const RichTextInput = forwardRef<Ref, IProps>(({
  ...props
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const buttonPosition = props.buttonPosition || 'end';
  const {
    error,
    value = '',
    isCode = false,
    isRichText = true,
    placeholder = '',
    ignoreVariables = [],
    isInput = false,
    onChange,
    helperText,
    defaultHeight = 264,
    maxLength = 1024,
    isJsonVariable = true,
    isEmojiOnly = false,
    ...field
  } = props;
  const variableRef: any = useRef();
  const handleAddFormat = (symbol: string) => {
    variableRef.current.handleAddText(symbol, false, true);
  };
  const handleAddEmoji = (e: any) => {
    variableRef.current.handleFocus();
    setTimeout(() => {
      variableRef.current.handleAddText(e.native);
    }, 100);
  };
  const handleSelect = (selectValue: string) => {
    variableRef.current.handleAddText(selectValue);
  };
  const disabledCouponVariable = useMemo(() => {
    return [...value.matchAll(new RegExp(botCouponVariablePattern, 'g'))].length >= 1;
  }, [value]);
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  return <>
			{!(isInput || isCode || !isRichText) && <Box>
					<FormatBoldRoundedIcon sx={{
        ...iconStyle
      }} onClick={() => handleAddFormat('*')} />
					<EmojiPicker iconStyle={iconStyle} onSelect={handleAddEmoji} />
					<FormatItalicRoundedIcon sx={{
        ...iconStyle
      }} onClick={() => handleAddFormat('_')} />
					<StrikethroughSRoundedIcon sx={{
        ...iconStyle
      }} onClick={() => handleAddFormat('~')} />
					<CodeIcon sx={{
        ...iconStyle
      }} onClick={() => handleAddFormat('```')} />
					{props?.hasCoupon && !isOtherBrand && <CouponButton disabled={disabledCouponVariable} onChange={handleSelect} />}

					{!props?.ignoreVariable && <VariableButton filterVariables={props?.filterVariables} ignoreVariables={ignoreVariables} onChange={handleSelect} />}
				</Box>}
			{isEmojiOnly && <Box>
					<EmojiPicker iconStyle={iconStyle} onSelect={handleAddEmoji} />
					{props.otherButton}
				</Box>}
			<Box sx={{
      display: 'flex',
      flexWrap: buttonPosition === 'bottom' ? 'wrap' : 'nowrap',
      alignItems: 'center',
      gap: '4px',
      width: '100%'
    }}>
				<Box sx={{
        flex: 1,
        width: '100%'
      }}>
					<SlateEditor {...field} ignoreVariable={props.ignoreVariable || false} value={value} onChange={onChange} ref={variableRef} error={Boolean(error)} defaultHeight={defaultHeight} helperText={<>{helperText}</>} type="bot" placeholder={placeholder || formatMessage({
          defaultMessage: 'Please enter'
        })} resizable={!isInput} isInput={isInput} footer={!isInput && !isCode && <Typography variant="caption" color="text.secondary" sx={{
          lineHeight: '40px',
          float: 'right'
        }}>
									{getNoParamsText(value, 'bot').length}/{maxLength}
								</Typography>} />
				</Box>
				{(isInput || isCode) && <Box sx={buttonPosition === 'bottom' ? {
        width: '100%'
      } : {
        flex: 0
      }}>
						<VariableButton isJsonVariable={isJsonVariable} filterVariables={props?.filterVariables} ignoreVariables={ignoreVariables} onChange={handleSelect} />
					</Box>}
			</Box>
		</>;
});
export default RichTextInput;