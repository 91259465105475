import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetBotsParams, DeleteBotParams, GetTriggerLogsParams, GetTriggerCountParams, CreateBotParams, UpdateBotParams, UpdateBotConfigParams, GetBotConfigParams, GetBotByIdParams } from './type';
import { getBots, deleteBot, getTriggerCount, getTriggerLogs, createBot, getTriggerCountByIds, updateBot, getBotConfig, updateBotConfig, getBotById } from './botAPI';
import { RootState } from 'src/app/store';

export interface BotState {
	wabaId: string;
	botData: any;
}

const initialState: BotState = {
	wabaId: '',
	botData: {},
};
export const getBotsAsync = createAsyncThunk('bot/getBots', async ({ hasTrigger = true, ...params }: GetBotsParams) => {
	const response = await getBots({ ...params, includes: 'flowData' });
	const ids = (response?.data?.records || [])?.map((item: any) => item.id).join(',');
	if (ids && hasTrigger) {
		try {
			const triggerResponse = await getTriggerCountByIds({ ids });
			const triggers = triggerResponse?.data?.records || [];
			(response?.data?.records || [])?.forEach((item: any) => {
				item.triggerCount = triggers.find((child: any) => child.botId === item.id)?.triggerCount;
			});
		} catch (e) {
			console.log(e);
			return response;
		}
	}
	return response;
});

export const createBotAsync = createAsyncThunk('bot/createBot', async (params: CreateBotParams) => {
	const response = await createBot(params);
	return response;
});

export const deleteBotAsync = createAsyncThunk('bot/deleteBot', async (params: DeleteBotParams) => {
	const response = await deleteBot(params);
	return response;
});

export const updateBotAsync = createAsyncThunk('bot/updateBot', async (params: UpdateBotParams) => {
	const response = await updateBot(params);
	return response;
});

export const getBotConfigAsync = createAsyncThunk('bot/getBotConfig', async (params: GetBotConfigParams) => {
	const response = await getBotConfig(params);
	return response;
});

export const updateBotConfigAsync = createAsyncThunk('bot/updateBotConfig', async (params: UpdateBotConfigParams) => {
	const response = await updateBotConfig(params);
	return response;
});

export const getTriggerLogsAsync = createAsyncThunk('bot/getTriggerLogs', async (params: GetTriggerLogsParams) => {
	const response = await getTriggerLogs(params);
	return response;
});

export const getTriggerCountAsync = createAsyncThunk('bot/getTriggerCount', async (params: GetTriggerCountParams) => {
	const response = await getTriggerCount(params);
	return response;
});

export const getBotByIdAsync = createAsyncThunk('bot/getBotById', async (params: GetBotByIdParams) => {
	const response = await getBotById(params);
	return response;
});

export const selectWabaId = (state: RootState) => state.bot.wabaId;
export const selectBotData = (state: RootState) => state.bot.botData;

export const botSlice = createSlice({
	name: 'bot',
	initialState,
	reducers: {
		setWabaId: (state, action) => {
			state.wabaId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBotByIdAsync.fulfilled, (state, action) => {
				state.botData = action.payload.data;
			})
			.addCase(getBotByIdAsync.rejected, (state) => {
				state.botData = {};
			});
	},
});

export const { setWabaId } = botSlice.actions;

export default botSlice.reducer;
