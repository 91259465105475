import { useIntl } from 'react-intl';
import { Box, TextField, Typography, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getDateStartTime, getDateEndTime } from 'src/utils/tools';
import { formatDate } from 'src/utils/transform';
import { DateRegexp } from 'src/utils/regexp';
const DateRange: FunctionComponent<{
  value: any;
  onChange: (e: any) => void;
}> = ({
  value,
  onChange
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dateTextStyle = {
    width: '144px',
    '& input': {
      height: '32px'
    }
  };
  const activeDateStyle = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #1A1E22'
    }
  };
  const errInputStyle = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #FF4842 !important'
    }
  };
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [active, setActive] = useState<string>('start');
  const [dataValue, setDataValue] = useState<any>();
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');
  const [isStartError, setIsStartError] = useState<boolean>(false);
  const [isEndError, setIsEndError] = useState<boolean>(false);
  const [errText, setErrText] = useState<string>('');
  const handleFocus = (e: string) => {
    setActive(e);
    if (e === 'start') {
      setDataValue(new Date(start));
    } else if (e === 'end') {
      setDataValue(new Date(end));
    }
  };
  const setInputText = (type: string, e: string) => {
    if (type === 'start') {
      setStart(e);
    } else if (type === 'end') {
      setEnd(e);
    }
  };
  const setInputError = (type: string, e: boolean) => {
    if (type === 'start') {
      setIsStartError(e);
    } else if (type === 'end') {
      setIsEndError(e);
    }
  };
  const handleInputChange = (type: string, e: string) => {
    setInputText(type, e);
    if (!DateRegexp.test(e)) {
      setErrText('yyyy-mm-dd');
      setInputError(type, true);
      return;
    }
    if (type === 'start') {
      if (end && getDateStartTime(new Date(e)) > getDateEndTime(new Date(end))) {
        setErrText('startTime <= endTime');
        setInputError(type, true);
        return;
      }
    }
    if (type === 'end') {
      if (start && getDateEndTime(new Date(e)) < getDateStartTime(new Date(start))) {
        setErrText('endTime >= startTime');
        setInputError(type, true);
        return;
      }
    }
    setErrText('');
    setInputError(type, false);
    setDataValue(new Date(e));
  };
  const handleDateChange = (e: any) => {
    setDataValue(new Date(e));
    const text = formatDate(e);
    setInputText(active, text);
  };
  useEffect(() => {
    if (!isStartError && !isEndError && start && end) {
      const e: string = getDateStartTime(new Date(start)) + ',' + getDateEndTime(new Date(end));
      onChange(e);
    }
  }, [start, end, isStartError, isEndError]);
  useEffect(() => {
    if (!isFirst) {
      setIsFirst(true);
      const arr = value.split(',');
      setStart(formatDate(Number(arr[0])));
      setDataValue(new Date(Number(arr[0])));
      if (arr[1]) {
        setEnd(formatDate(Number(arr[1])));
      }
    }
  }, [value, isFirst]);
  return <>
			<Box sx={{
      display: 'flex',
      columnGap: '12px',
      alignItems: 'center',
      mb: isStartError || isEndError ? '20px' : 1,
      ml: '36px'
    }}>
				<Box sx={{
        position: 'relative'
      }}>
					<TextField autoFocus={true} variant="outlined" autoComplete="off" placeholder={formatMessage({
          defaultMessage: 'yyyy-mm-dd'
        })} value={start} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleInputChange('start', event.target.value);
        }} onFocus={() => handleFocus('start')} sx={{
          ...dateTextStyle,
          ...(active === 'start' && activeDateStyle),
          ...(isStartError && errInputStyle)
        }} />
					{isStartError && <FormHelperText error sx={{
          position: 'absolute'
        }}>
							{errText}
						</FormHelperText>}
				</Box>
				<Typography variant="caption" color="primary.dark" component="div">
					{formatMessage({
          defaultMessage: '—'
        })}
				</Typography>
				<Box sx={{
        position: 'relative'
      }}>
					<TextField variant="outlined" autoComplete="off" placeholder={formatMessage({
          defaultMessage: 'yyyy-mm-dd'
        })} value={end} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleInputChange('end', event.target.value);
        }} onFocus={() => handleFocus('end')} sx={{
          ...dateTextStyle,
          ...(active === 'end' && activeDateStyle),
          ...(isEndError && errInputStyle)
        }} />
					{isEndError && <FormHelperText error sx={{
          position: 'absolute'
        }}>
							{errText}
						</FormHelperText>}
				</Box>
			</Box>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<StaticDatePicker displayStaticWrapperAs="desktop"
      // maxDate={maxDate}
      minDate={active === 'end' ? new Date(start) : undefined} value={dataValue} onChange={(i: any) => handleDateChange(i)} />
			</LocalizationProvider>
		</>;
};
export default DateRange;