import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import undoable, { includeAction } from 'redux-undo';
import { applyNodeChanges, Edge, Node } from 'reactflow';
import { GetBotByIdParams, nodeConfigMap, NodeErrorType, NodeType, Variable, CompositeTriggerType } from './type';
import { getBotById, testCallApi, updateBot, uploadMediaFile } from './botAPI';
import { flatMap, isEmpty, now, uniq } from 'lodash-es';
import { formatOutput } from './formatData';
import { deepCopy } from 'src/utils/transform';
import { defineMessages } from 'react-intl';
import { getWabaIdFromAskQuestion, getWabaIdFromTrigger } from 'src/pages/bot/detail/build/components/configEditor/askQuestionConfigEditor';
import { computeWhatsAppFlowError } from './utils';

export const timeOptions = [
	{ label: 'more than', value: 'MORE_THAN' },
	{ label: 'less than', value: 'LESS_THAN' },
	{ label: 'between', value: 'BETWEEN' },
	{ label: 'before', value: 'BEFORE' },
	{ label: 'after', value: 'AFTER' },
	{ label: 'on', value: 'ON' },
];

export const stringOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'contains', value: 'CONTAINS' },
];

export const booleanOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'is not', value: 'IS_NOT' },
	{ label: 'is unknown', value: 'IS_UNKNOWN' },
];

export const numberOptions = [
	{ label: 'is', value: 'IS' },
	{ label: 'greater than', value: 'GREATER_THAN' },
	{ label: 'less than', value: 'LESS_THAN' },
	{ label: 'between', value: 'BETWEEN' },
];

export const operatorLabelMap = defineMessages({
	IS: {
		defaultMessage: 'is',
	},
	IS_NOT: {
		defaultMessage: 'is not',
	},
	CONTAINS: {
		defaultMessage: 'contains',
	},
	MORE_THAN: {
		defaultMessage: 'more than',
	},
	LESS_THAN: {
		defaultMessage: 'less than',
	},
	BETWEEN: {
		defaultMessage: 'between',
	},
	BEFORE: {
		defaultMessage: 'before',
	},
	AFTER: {
		defaultMessage: 'before',
	},
	ON: {
		defaultMessage: 'on',
	},
	IS_UNKNOWN: {
		defaultMessage: 'is unknown',
	},
	GREATER_THAN: {
		defaultMessage: 'greater than',
	},
});

export const findAllPaths = (edges: any[], start: string, path: any[] = [], allPaths: any[] = [], visited: Set<string> = new Set()) => {
	path.push(start);
	visited.add(start); // Mark the current node as visited
	let hasEnd = false;
	for (const edge of edges) {
		if (edge.source === start && !visited.has(edge.target)) {
			// Check if the target node has not been visited
			hasEnd = true;
			findAllPaths(edges, edge.target, path, allPaths, visited); // Pass the visited set to the recursive call
		}
	}
	if (!hasEnd) {
		allPaths.push([...path]);
	}
	path.pop();
	visited.delete(start); // Remove the current node from visited set when backtracking
	return allPaths;
};

export const getConnectionNodeIds = (nodes: any[], edges: any[]) => {
	const triggerNode: any = nodes.find((node) => node.type === NodeType.KEYWORD || node.type === NodeType.COMPOSITE_TRIGGER);
	if (!triggerNode) {
		return [];
	}
	return uniq([triggerNode.id, ...flatMap(findAllPaths(edges, triggerNode.id))]);
};

// 将handleId附在button或list里面
const computeAskQuestionConfig = (handles: any, askQuestionConfig: any) => {
	if (!handles) {
		handles = [];
	}
	let maxHandleIdNumber = handles.length
		? Math.max.apply(
				null,
				handles.map((item: any) => Number(item.id)),
			)
		: 0;
	for (let i = 0; i < handles.length; i++) {
		const ref = handles[i].ref;
		if (!ref) {
			continue;
		}
		if (ref.type === 'button') {
			if (askQuestionConfig.message.interactive.action.buttons[ref.buttonIndex]) {
				askQuestionConfig.message.interactive.action.buttons[ref.buttonIndex].handleId = handles[i].id;
			}
		} else {
			if (askQuestionConfig.message.interactive.action.sections[ref.sectionIndex]) {
				askQuestionConfig.message.interactive.action.sections[ref.sectionIndex].rows[ref.rowIndex].handleId = handles[i].id;
			}
		}
	}
	if (askQuestionConfig && askQuestionConfig.message) {
		if (askQuestionConfig.message.interactive.type === 'button') {
			const buttons = askQuestionConfig.message.interactive.action.buttons;
			for (let i = 0; i < buttons.length; i++) {
				if (!buttons[i].handleId) {
					buttons[i].handleId = `${maxHandleIdNumber + 1}`;
					maxHandleIdNumber += 1;
				}
			}
		} else {
			const sections = askQuestionConfig.message.interactive.action.sections;
			let maxSectionIdNumber = 0;

			for (let i = 0; i < sections.length; i++) {
				sections[i].sectionId = `${maxSectionIdNumber++}`; // 这里用于section拖拽给唯一的Id
				for (let j = 0; j < sections[i].rows.length; j++) {
					if (!sections[i].rows[j].handleId) {
						sections[i].rows[j].handleId = `${maxHandleIdNumber + 1}`;
						maxHandleIdNumber += 1;
					}
				}
			}
		}
	}
	return [askQuestionConfig, maxHandleIdNumber];
};

const getArrayMax = (arr: number[]): number => Math.max.apply(null, arr);

const computeMaxHandle = (nodeType: string, data: any): number => {
	if (isEmpty(data) || isEmpty(nodeType)) {
		return 0;
	}

	let handles: any[] = [],
		maxHandleIdNumber = 0;

	const handleMapping = new Map();

	if (!isEmpty(data.handles) && Array.isArray(data.handles)) {
		handles = data.handles;
		maxHandleIdNumber = getArrayMax(
			handles.map((item: any, index: number) => {
				const handle: number = isNaN(+item.id) ? index + 1 : +item.id;
				if (item?.ref?.buttonIndex) {
					handleMapping.set(item.ref.buttonIndex, handle);
				}
				if (item?.ref?.sectionIndex) {
					handleMapping.set(item.ref.sectionIndex, handle);
				}
				return handle;
			}),
		);
	}

	const nodeData = data[nodeType] || {};

	if (nodeType === NodeType.ASK_QUESTION) {
		const interactiveData = nodeData?.message?.interactive;

		if (interactiveData?.type === 'button' && !isEmpty(interactiveData?.action?.buttons) && Array.isArray(interactiveData.action.buttons)) {
			interactiveData.action.buttons.forEach((item: any, index: number) => {
				if (!item.handle) {
					item.handle = String(handleMapping.get(index) ? handleMapping.get(index) : ++maxHandleIdNumber);
				} else {
					maxHandleIdNumber = Math.max(maxHandleIdNumber, +item.handle || 0);
				}
			});
		}
		if (interactiveData?.type === 'list' && !isEmpty(interactiveData?.action?.sections) && Array.isArray(interactiveData.action.sections)) {
			interactiveData.action.sections.forEach((item: any, index: number) => {
				if (!item.handle) {
					item.handle = String(handleMapping.get(index) ? handleMapping.get(index) : ++maxHandleIdNumber);
				} else {
					maxHandleIdNumber = Math.max(maxHandleIdNumber, +item.handle || 0);
				}
			});
		}
	}

	if (nodeType === NodeType.CALL_API && !isEmpty(nodeData?.responseRoutes) && Array.isArray(nodeData.responseRoutes)) {
		nodeData.responseRoutes.forEach((item: any, index: number) => {
			if (!item.handle) {
				item.handle = String(handleMapping.get(index) ? handleMapping.get(index) : ++maxHandleIdNumber);
			} else {
				maxHandleIdNumber = Math.max(maxHandleIdNumber, +item.handle || 0);
			}
		});
	}

	if (nodeType === NodeType.BRANCHING && !isEmpty(nodeData?.branches) && Array.isArray(nodeData.branches)) {
		nodeData.branches.forEach((item: any, index: number) => {
			if (!item.handle) {
				item.handle = handleMapping.get(index) ? handleMapping.get(index) : ++maxHandleIdNumber;
			} else {
				maxHandleIdNumber = Math.max(maxHandleIdNumber, +item.handle || 0);
			}
		});
	}

	return maxHandleIdNumber;
};

// 将从后端接口获取到的节点数据保存在reactFlow的nodes（node.data.config中）
const combineTwoData = (botData: any, filterMapping: any, tagMapping: any) => {
	const copyBotData = deepCopy(botData);
	const nodes = copyBotData?.reactflow?.nodes || [];
	const flowDataNodes = botData?.flowData?.nodes || [];
	const newNodes = [...nodes];
	const maxNodeHandleNumbers = [];
	for (let i = 0; i < newNodes.length; i++) {
		for (let j = 0; j < flowDataNodes.length; j++) {
			const currentNode = flowDataNodes[j];
			const currentNodeData = currentNode?.[currentNode?.type] || {};
			if (newNodes[i].id === flowDataNodes[j].id) {
				const maxNodeHandleNumber = computeMaxHandle(newNodes[i].type!, currentNode);
				maxNodeHandleNumbers.push({
					nodeId: newNodes[i].id,
					maxNodeHandleNumber,
				});
				if (!isEmpty(newNodes[i]?.data?.config)) {
					break;
				}
				if (newNodes[i].type === NodeType.ASK_QUESTION && flowDataNodes[j][flowDataNodes[j].type] && flowDataNodes[j][flowDataNodes[j].type]?.message?.type !== 'text') {
					const [config] = computeAskQuestionConfig(flowDataNodes[j].handles, flowDataNodes[j][flowDataNodes[j].type]);
					newNodes[i].data = {
						...newNodes[i].data,
						config,
					};
				} else if (newNodes[i].type === NodeType.BRANCHING) {
					newNodes[i].data = {
						...newNodes[i].data,
						config: {
							branches: (flowDataNodes[j]?.branching?.branches || [])
								.map((item: any) => {
									return {
										...item,
										conditions: (item?.conditions || []).map((conditionItem: any, conditionIndex: number) => {
											let namespace = '',
												column = conditionItem?.column;
											if (conditionItem?.column.includes(':')) {
												[namespace, column] = conditionItem?.column?.replace(/(\{\{|\}\})/g, '').split(':') || [];
											}

											const current = filterMapping[column] || {};
											return {
												id: `${item.handle}-${conditionIndex}`,
												timeStamp: now(),
												...conditionItem,
												namespace: namespace,
												column: column,
												value: conditionItem?.column === '{{contact:tags}}' ? tagMapping?.[conditionItem?.value] : conditionItem?.value,
												displayName: namespace === 'contact' ? current?.displayName : column,
												type: namespace === 'contact' ? current?.displayType : 'Text',
											};
										}),
									};
								})
								.filter((item: any) => item.handle !== '-1' && item.handle !== `${newNodes[i].id}-default`),
						},
					};
				} else if (newNodes[i].type === NodeType.UNSUBSCRIBE) {
					newNodes[i].data = {
						...newNodes[i].data,
						config: {
							autoReply: Boolean(currentNodeData?.autoReply),
							message: currentNodeData?.message?.text?.body,
						},
					};
				} else if (newNodes[i].type === NodeType.ADD_TAG) {
					newNodes[i].data = {
						...newNodes[i].data,
						config: !isEmpty(currentNodeData.tagIds)
							? {
									tagList: (currentNodeData.tagIds || []).filter(Boolean).map((id: string) => tagMapping?.[id] || id),
								}
							: {},
					};
				} else if (newNodes[i].type === NodeType.SEND_MESSAGE) {
					newNodes[i].data = {
						...newNodes[i].data,
						config: !isEmpty(currentNodeData.messages)
							? {
									messages: (currentNodeData.messages || []).map((item: any) => {
										if (item.type !== 'interactive') {
											return item;
										}
										const { header = {}, ...params } = item.interactive as any;
										if (!header?.type) {
											return { ...item, interactive: { ...params, header: { ...header, type: 'none' } } };
										}
										return item;
									}),
								}
							: { messages: [] },
					};
				} else if (newNodes[i].type === NodeType.COMPOSITE_TRIGGER) {
					const flowDataComponents = flowDataNodes[j]?.compositeTrigger?.components || [];
					const templateButtonComponent = flowDataComponents.find((item: any) => item.type === CompositeTriggerType.TEMPLATE_BUTTON);
					if (templateButtonComponent) {
						const templateChangeItem = botData?.templateChanges[0] || {};
						newNodes[i].data = {
							...newNodes[i].data,
							config: {
								changed: templateChangeItem?.changed,
								components: [
									{
										type: CompositeTriggerType.TEMPLATE_BUTTON,
										[CompositeTriggerType.TEMPLATE_BUTTON]: {
											...templateChangeItem,
											maxSourceHandleIndex: Math.max.apply(null, currentNode.handles?.map((handle: any) => Number(handle.id)) || []),
											wabaId: templateChangeItem?.latestTemplate?.wabaId,
											buttonValidityEnabled: templateButtonComponent.templateButton.buttonValidityEnabled,
											buttonValiditySeconds: templateButtonComponent.templateButton.buttonValiditySeconds,
											triggerOnce: templateButtonComponent.templateButton.triggerOnce,
											template: botData?.reactflow?.nodes[0]?.data?.config?.components?.find((component: any) => component.type === 'templateButton').templateButton?.template || {},
										},
									},
								],
							},
						};
					} else {
						newNodes[i].data = {
							...newNodes[i].data,
							config: flowDataNodes[j][flowDataNodes[j].type],
						};
					}
				} else if (newNodes[i].type === NodeType.KEYWORD) {
					newNodes[i] = {
						...newNodes[i],
						type: NodeType.COMPOSITE_TRIGGER,
						data: {
							...newNodes[i].data,
							config: {
								components: [
									{
										type: 'keyword',
										keyword: flowDataNodes[j][flowDataNodes[j].type],
									},
								],
							},
						},
					};
				} else if (newNodes[i].type === NodeType.ASSIGN_AGENT) {
					newNodes[i] = {
						...newNodes[i],
						type: NodeType.ASSIGN_AGENT,
						data: {
							...newNodes[i].data,
							config: {
								assignType: 'assignment',
							},
						},
					};
				} else {
					newNodes[i].data = {
						...newNodes[i].data,
						config: flowDataNodes[j][flowDataNodes[j].type],
					};
				}
				break;
			}
		}
	}

	return {
		newNodes,
		maxNodeHandleNumbers,
	};
};

// 过滤掉所有未使用的edge，这个方法有问题
// const computeInUseEdges = (nodes: Array<Node>, edges: Array<Edge>) => {
// 	const sourceHandleList = nodes.flatMap(node => {
// 		const { type, data = {} } = node;

// 		const { config } = data;

// 		if (type === NodeType.ASK_QUESTION && config?.message?.type !== 'text') {
// 			return config?.message?.interactive?.type === 'button' ? config?.message?.interactive?.action?.buttons.map((item: any) => item.handleId) : config?.message?.interactive?.action?.sections.flatMap((item: any) => item.rows.map((row: any) => row.handleId));
// 		}
// 		if (type === NodeType.CALL_API) {
// 			return config?.responseRoutes?.map((item: any) => item.handle) || [];
// 		}
// 		if (type === NodeType.BRANCHING) {
// 			return [...(config?.branches?.map((item: any) => item.handle) || []), '-1'];
// 		}
// 		if (type === NodeType.COMPOSITE_TRIGGER) {
// 			const components = config?.components || [];
// 			let sourceHandleIdList = [];
// 			for (let i = 0; i < components.length; i++) {
// 				if (components[i].type === CompositeTriggerType.TEMPLATE_BUTTON) {
// 					const latestTemplate = components[i][components[i].type].latestTemplate;
// 					const templateComponents = latestTemplate?.components || [];
// 					for (let j = 0; j < templateComponents.length; j++) {
// 						if (templateComponents[j].type === 'BUTTONS') {
// 							sourceHandleIdList = templateComponents[j].buttons.map((item: any, index: number) => index.toString());
// 							break;
// 						}
// 					}
// 					break;
// 				}
// 			}
// 			return sourceHandleIdList;
// 		}
// 		return [];
// 	});

// 	return edges.filter(edge => !edge.sourceHandle || (edge.sourceHandle && sourceHandleList.includes(edge.sourceHandle)));
// };

export const computeInUseEdges = (nodes: Array<Node>, edges: Array<Edge>): Array<Edge> => {
	// 创建一个 Map 来存储每个节点的有效 sourceHandles
	const validSourceHandlesMap = new Map<string, Set<string>>();

	// 遍历所有节点，收集每个节点的有效 sourceHandles
	nodes.forEach((node) => {
		const { id, type, data } = node;
		const config = data?.config;
		const validSourceHandles = new Set<string>();

		switch (type) {
			case NodeType.ASK_QUESTION:
				if (config?.message?.type !== 'text') {
					if (config?.message?.interactive?.type === 'button') {
						config?.message?.interactive?.action?.buttons?.forEach((button: any) => {
							validSourceHandles.add(button.handleId);
						});
					} else if (config?.message?.interactive?.type === 'list') {
						config?.message?.interactive?.action?.sections?.forEach((section: any) => {
							section.rows?.forEach((row: any) => {
								validSourceHandles.add(row.handleId);
							});
						});
					}
				}
				break;
			case NodeType.CALL_API:
				config?.responseRoutes?.forEach((route: any) => {
					validSourceHandles.add(route.handle);
				});
				break;
			case NodeType.BRANCHING:
				config?.branches?.forEach((branch: any) => {
					validSourceHandles.add(branch.handle);
				});
				validSourceHandles.add('-1'); // 默认分支
				break;
			case NodeType.COMPOSITE_TRIGGER:
				config?.components?.forEach((component: any) => {
					if (component.type === CompositeTriggerType.TEMPLATE_BUTTON) {
						const templateButtons = component[CompositeTriggerType.TEMPLATE_BUTTON]?.latestTemplate?.components?.find((c: any) => c.type === 'BUTTONS')?.buttons || [];
						templateButtons.forEach((_: any, index: number) => {
							validSourceHandles.add(index.toString());
						});
					}
				});
				break;
			default:
				// 对于其他类型的节点，我们假设它们可能有一个默认的出口
				validSourceHandles.add(id);
		}

		validSourceHandlesMap.set(id, validSourceHandles);
	});

	// 过滤边缘
	return edges.filter((edge) => {
		const sourceNode = nodes.find((node) => node.id === edge.source);
		if (!sourceNode) {
			return false;
		}

		const validSourceHandles = validSourceHandlesMap.get(sourceNode.id);
		if (!validSourceHandles) {
			return false;
		}

		// 保留没有 sourceHandle 的边缘（这些可能是默认连接）
		if (!edge.sourceHandle) {
			return true;
		}

		// 或者 sourceHandle 在该节点的有效列表中的边缘
		return validSourceHandles.has(edge.sourceHandle);
	});
};

// 计算后端需要的ref信息
const computeRefInfo = (handleId: string, node: Node) => {
	const { type, data: { config: { message: { interactive = {} } = {} } = {} } = {} } = node;

	if (type === NodeType.ASK_QUESTION) {
		const { type: interactiveType, action: { buttons = [], sections = [] } = {} } = interactive;

		if (interactiveType === 'button') {
			const buttonIndex = buttons.findIndex((button: any) => button.handleId === handleId);
			return buttonIndex !== -1 ? { type: 'button', buttonIndex } : undefined;
		} else if (interactiveType === 'list') {
			for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
				const { rows = [] } = sections[sectionIndex];
				const rowIndex = rows.findIndex((row: any) => row.handleId === handleId);
				if (rowIndex !== -1) {
					return { type: 'list', sectionIndex, rowIndex };
				}
			}
		}
	} else if (type === NodeType.CALL_API) {
		return { type: 'response_route' };
	} else if (type === NodeType.BRANCHING) {
		return { type: 'branch' };
	}
	if (type === NodeType.COMPOSITE_TRIGGER) {
		return {
			type: 'template_button',
			buttonIndex: Number(handleId),
		};
	}
	return undefined;
};

// 计算后端接口需要的next和handles信息
const computeNextAndHandles = (node: Node, edges: Array<Edge>) => {
	const relativeEdges = edges.filter((edge) => edge.source === node.id);
	if (!relativeEdges.length) {
		return [undefined, undefined];
	}

	const singleEdgeWithoutHandle = relativeEdges.length === 1 && !relativeEdges[0].sourceHandle;
	if (singleEdgeWithoutHandle) {
		return [{ node: relativeEdges[0].target }, undefined];
	}

	const handles = relativeEdges.map((edge) => ({
		id: edge.sourceHandle || '',
		ref: computeRefInfo(edge.sourceHandle || '', node),
		next: { node: edge.target },
	}));

	return [undefined, handles];
};

const defaultViewPort = {
	x: 0,
	y: 0,
	zoom: 1,
};

type BotHistoryState = {
	nodes: Array<Node>;
	edges: Array<Edge>;
	viewport: typeof defaultViewPort;
	errors: {
		showResult: boolean;
		errors: Array<Error>;
	};
	basicInfo: any;
	isNodeMenuFold: boolean;
	hasUnsavedChange: boolean;
	maxNodeIdNumber: number;
	isGettingBotData: boolean;
	isSavingBotData: boolean;
	variables: Array<Variable>;
	maxNodeHandleNumbers: Array<{
		nodeId: string;
		maxNodeHandleNumber: number;
	}>;
	templateChanges: [];
	whatsappFlows: [];
};

const initialState: BotHistoryState = {
	nodes: [], // 储存ReactFlow节点
	edges: [], // 储存ReactFlow连接线
	viewport: defaultViewPort,
	errors: {
		showResult: false,
		errors: [],
	}, // 储存ReactFlow节点检查报错，这个仅在前端使用，后端数据无需存储
	isNodeMenuFold: false,
	maxNodeIdNumber: 0,
	variables: [],
	basicInfo: {},
	isGettingBotData: false,
	isSavingBotData: false,
	hasUnsavedChange: false,
	maxNodeHandleNumbers: [],
	templateChanges: [],
	whatsappFlows: [],
};

export const getBotByIdAsync = createAsyncThunk('bot/getBotById', async (params: GetBotByIdParams & { extraParams: any }) => {
	const { extraParams, ...fetchParams } = params;
	const res = await getBotById(fetchParams);
	return { botData: res.data, extraParams };
});

export const uploadMediaFileAsync = createAsyncThunk('bot/uploadMediaFile', async (params: any, { rejectWithValue }) => {
	try {
		const res = await uploadMediaFile(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const testCallApiAsync = createAsyncThunk('bot/testCallApi', async (params: any) => {
	const res = await testCallApi(params);
	return res;
});

export const saveBotAsync = createAsyncThunk(
	'bot/saveBot',
	async (
		params: {
			status: string;
			id: string;
			nodes: Array<Node>;
			edges: Array<Edge>;
		},
		thunkAPI,
	) => {
		const state: any = thunkAPI.getState();
		const { nodes, edges, viewport } = state.botHistory.present;
		const inUseEdges = computeInUseEdges(nodes, edges);

		const flowDataNodes = nodes.map((item: any) => {
			const [next, handles] = computeNextAndHandles(item, inUseEdges);
			const { id, type, data = {} } = item;

			return {
				id,
				type,
				next,
				handles,
				[type]: formatOutput(data?.config || {}, type),
			};
		});

		const apiParams = {
			id: params.id,
			flowData: {
				variables: state.botHistory.present.variables,
				nodes: flowDataNodes,
			},
			status: params.status,
			reactflow: {
				nodes,
				edges: inUseEdges,
				viewport: viewport,
			},
		};
		return await updateBot(apiParams);
	},
);

export const botHistory = createSlice({
	name: 'bot',
	initialState,
	reducers: {
		// 节点更新操作
		setNodes: (state, params) => {
			state.nodes = [...params.payload];
			state.hasUnsavedChange = true;
		},
		// 连接线更新操作
		setEdges: (state, params) => {
			state.edges = [...params.payload];
			state.hasUnsavedChange = true;
		},
		// 同时更新节点和连接线，此方法存在的意义是避免了记录两次操作记录
		setNodesAndEdges: (state, params) => {
			state.edges = [...params.payload.edges];
			state.nodes = [...params.payload.nodes];
			state.hasUnsavedChange = true;
		},
		setViewport: (state, params) => {
			state.viewport = params.payload;
			// state.hasUnsavedChange = true
		},
		// 该方法仅适用于节点位置更新，不需要更新flowData，不会记录进历史操作
		updateNodesPosition: (state, params) => {
			if (params.payload.some((item: any) => item.type === 'position' && item.dragging)) {
				state.hasUnsavedChange = true;
			}
			state.nodes = applyNodeChanges(params.payload, state.nodes);
		},
		// 该方法仅适用于连接线样式更新，无需更新flowData，不会记录进历史操作
		updateEdgeStyles: (state, params) => {
			state.edges = [...params.payload];
		},
		updateMaxNodeHandleNumberReducer: (state, params) => {
			let finded = false;
			state.maxNodeHandleNumbers = state.maxNodeHandleNumbers.map((item) => {
				if (item.nodeId === params.payload) {
					finded = true;
					return {
						...item,
						maxNodeHandleNumber: (item.maxNodeHandleNumber || 0) + 1,
					};
				} else {
					return item;
				}
			});
			if (!finded) {
				state.maxNodeHandleNumbers = [
					...state.maxNodeHandleNumbers,
					{
						nodeId: params.payload,
						maxNodeHandleNumber: 1,
					},
				];
			}
		},
		checkFlow: (state, { payload }) => {
			const { nodes, edges } = state;

			const { isCheck, extraParams = {} } = payload;
			const { tagMapping = {}, teamMapping = {}, agentMapping = {}, filterMapping = {} } = extraParams;
			const connectionNodeIds = getConnectionNodeIds(nodes, edges);

			const errors: any[] = [];

			const triggerWabaId = getWabaIdFromTrigger(nodes[0]);

			let askQuestionNodeWabaId = '';

			let isAskQuestionNodeWabaIdConsistent = true;

			const askQuestionNodesId: Array<string> = [];

			state.nodes = nodes.map((node) => {
				const nodeErrors = [];

				const { type, id, data = {} } = node;

				if (connectionNodeIds.includes(id)) {
					if (type === NodeType.KEYWORD && !edges.some((edge) => edge.source === id)) {
						nodeErrors.push({ type: NodeErrorType.TRIGGER_WITHOUT_NEXT, nodeId: id });
					}

					if (type === NodeType.COMPOSITE_TRIGGER) {
						if (!edges.some((edge) => edge.source === id)) {
							nodeErrors.push({ type: NodeErrorType.TRIGGER_WITHOUT_NEXT, nodeId: id });
						}
						const templateButtonComponent = data?.config?.components?.find((item: any) => item.type === CompositeTriggerType.TEMPLATE_BUTTON);
						if (templateButtonComponent) {
							const latestTemplate = templateButtonComponent[CompositeTriggerType.TEMPLATE_BUTTON]?.latestTemplate;
							const templateButtons = latestTemplate?.components?.find((c: any) => c.type === 'BUTTONS')?.buttons || [];
							const templateQuickReplyButtonIndexs = templateButtons?.map((button: any, index: number) => {
								if (button.type === 'QUICK_REPLY') {
									return index.toString();
								}
								return undefined;
							});
							let isWrongConnection = false;
							state.edges = state.edges.map((edge) => {
								if (edge.source === id && !templateQuickReplyButtonIndexs.includes(edge.sourceHandle)) {
									if (typeof edge.markerEnd !== 'string' && typeof edge.markerEnd !== 'undefined') {
										edge.markerEnd.color = '#FF4842';
									}
									edge.style = { ...edge.style, stroke: '#FF4842' };
									isWrongConnection = true;
								} else {
									if (typeof edge.markerEnd !== 'string' && typeof edge.markerEnd !== 'undefined') {
										edge.markerEnd.color = '#1A1E22';
									}
									edge.style = { ...edge.style, stroke: '#1A1E22' };
								}
								return edge;
							});
							if (isWrongConnection) {
								nodeErrors.push({ type: NodeErrorType.WRONG_CONNECTION_LINE, nodeId: id });
							}
						}
					}

					if (nodeConfigMap[type as keyof typeof nodeConfigMap]?.checkForm && isEmpty(data?.config)) {
						nodeErrors.push({ type: NodeErrorType.NODE_NO_CONFIG, nodeId: id });
					} else {
						const { config } = data;

						if (type === NodeType.ASSIGN_AGENT) {
							if (config.assignType === 'agent' && !agentMapping[config.agent.id]) {
								nodeErrors.push({ type: NodeErrorType.ABNORMAL_PARAMETER, nodeId: id });
							}
							if (config.assignType === 'team' && !teamMapping[config.team.id]) {
								nodeErrors.push({ type: NodeErrorType.ABNORMAL_PARAMETER, nodeId: id });
							}
						} else if (type === NodeType.ADD_TAG) {
							if ((config.tagList || []).some((item: any) => !tagMapping[item?.id])) {
								nodeErrors.push({ type: NodeErrorType.ABNORMAL_PARAMETER, nodeId: id });
							}
						} else if (type === NodeType.BRANCHING) {
							const branches = (data?.config?.branches || []).filter(Boolean);
							if (branches.some((item: any) => (item?.conditions || []).some((condition: any) => (condition.namespace === 'contact' && !filterMapping[condition?.column]) || (condition.column === 'tags' && !tagMapping?.[condition?.value?.id])))) {
								nodeErrors.push({ type: NodeErrorType.ABNORMAL_PARAMETER, nodeId: id });
							}
						} else if (type === NodeType.ASK_QUESTION) {
							const nodeWabaId = getWabaIdFromAskQuestion(node);
							if (nodeWabaId) {
								if (triggerWabaId && nodeWabaId && triggerWabaId !== nodeWabaId) {
									nodeErrors.push({ type: NodeErrorType.WABA_NOT_CONSISTENT, nodeId: id });
								}
								if (!triggerWabaId) {
									askQuestionNodesId.push(id);
									if (!askQuestionNodeWabaId) {
										askQuestionNodeWabaId = nodeWabaId;
									} else {
										if (askQuestionNodeWabaId !== nodeWabaId) {
											isAskQuestionNodeWabaIdConsistent = false;
										}
									}
								}
							}
						}
					}
					const computedErrors = computeWhatsAppFlowError(node, nodeErrors, state.whatsappFlows || []);
					errors.push(...computedErrors);
					return { ...node, data: { ...data, errors: computedErrors }, selected: false };
				} else {
					return { ...node, data: { ...data, errors: [] }, selected: false };
				}
			});

			if (!isAskQuestionNodeWabaIdConsistent) {
				state.nodes = nodes.map((node) => {
					if (askQuestionNodesId.includes(node.id)) {
						return {
							...node,
							data: {
								...node.data,
								errors: [...(node.data.errors || []), { type: NodeErrorType.WABA_NOT_CONSISTENT, nodeId: node.id }],
							},
						};
					} else {
						return node;
					}
				});
			}
			state.errors = { showResult: Boolean(isCheck), errors };
		},
		setNodeMenuFold: (state, params) => {
			state.isNodeMenuFold = params.payload;
		},
		updateMaxNodeIdNumber: (state) => {
			state.maxNodeIdNumber = state.maxNodeIdNumber + 1;
		},
		setIsGettingBotData: (state, params) => {
			state.isGettingBotData = params.payload;
		},
		setIsSavingBotData: (state, params) => {
			state.isSavingBotData = params.payload;
		},
		updateBaseInfo: (state, params) => {
			state.basicInfo = {
				...state.basicInfo,
				name: params.payload,
			};
		},
		createVariable: (state, params) => {
			state.variables = [
				...state.variables,
				{
					name: params.payload,
					type: 'text',
				},
			];
		},
		updateBotHasUnsavedChange: (state, { payload }) => {
			state.hasUnsavedChange = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBotByIdAsync.fulfilled, (state, action) => {
				let newVariables = [];

				if (!isEmpty(action.payload)) {
					const { botData = {}, extraParams = {} }: any = action.payload;

					const { filterMapping, tagMapping } = extraParams;

					const { newNodes, maxNodeHandleNumbers } = combineTwoData(botData, filterMapping, tagMapping);

					state.basicInfo = {
						name: botData.name,
						status: botData.status,
						wabaId: botData.wabaId,
					};
					state.templateChanges = botData.templateChanges || [];
					state.nodes = newNodes;
					state.whatsappFlows = botData.whatsappFlows || [];

					let errorArr: Array<any> = [];
					newNodes.forEach((newNode: any) => {
						if (newNode?.data?.errors?.length) {
							errorArr = errorArr.concat(newNode?.data?.errors);
						}
						errorArr = computeWhatsAppFlowError(newNode, errorArr, botData.whatsappFlows || []);
						newNode.data.errors = errorArr;
					});
					state.errors = {
						showResult: false,
						errors: errorArr,
					};
					if (botData?.flowData?.variables) {
						newVariables = botData?.flowData?.variables;
					}
					state.edges = botData?.reactflow?.edges || [];
					state.viewport = botData?.reactflow?.viewport || defaultViewPort;
					/* 待修改 */
					state.maxNodeIdNumber = newNodes.length
						? Math.max.apply(
								null,
								newNodes.map((item) => Number(item.id)),
							)
						: 1;
					/* 待修改 */
					state.maxNodeHandleNumbers = maxNodeHandleNumbers;

					state.hasUnsavedChange = false;
					state.variables = newVariables;
				}
			})
			.addCase(saveBotAsync.fulfilled, (state, action) => {
				if (action?.payload?.data?.status) {
					state.basicInfo = {
						...state.basicInfo,
						status: action?.payload?.data?.status,
					};
				}
				state.hasUnsavedChange = false;
			});
	},
});

export const selectNodes = (state: any) => {
	return state.botHistory.present.nodes;
};

export const selectWhatsAppFlows = (state: any) => {
	return state.botHistory.present.whatsappFlows;
};

export const selectEdges = (state: any) => {
	return state.botHistory.present.edges;
};
export const selectViewport = (state: any) => {
	return state.botHistory.present.viewport;
};
export const selectIsNodeMenuFold = (state: any) => {
	return state.botHistory.present.isNodeMenuFold;
};

export const selectMaxNodeIdNumber = (state: any) => {
	return state.botHistory.present.maxNodeIdNumber;
};

export const selectMaxNodeHandleNumbers = (state: any) => {
	return state.botHistory.present.maxNodeHandleNumbers;
};

export const selectNodeErrors = (state: any) => {
	return state.botHistory.present.errors;
};

export const selectMaxNodeHandleNumber = (state: any, nodeId: string) => {
	return state.botHistory.present.maxNodeHandleNumbers.find((item: any) => item.nodeId === nodeId)?.maxNodeHandleNumber || 0;
};

export const selectHasUnsavedChange = (state: any) => {
	return state.botHistory.present.hasUnsavedChange;
};

export const selectIsGettingBotData = (state: any) => {
	return state.botHistory.present.isGettingBotData;
};

export const selectIsSavingBotData = (state: any) => {
	return state.botHistory.present.isSavingBotData;
};

export const selectBasicInfo = (state: any) => {
	return state.botHistory.present.basicInfo;
};

export const selectVariables = (state: any) => {
	return state.botHistory.present.variables;
};

export const { setNodeMenuFold, checkFlow, updateMaxNodeHandleNumberReducer, updateMaxNodeIdNumber, updateNodesPosition, updateEdgeStyles, setNodesAndEdges, setNodes, setViewport, createVariable, setEdges, setIsGettingBotData, setIsSavingBotData, updateBaseInfo, updateBotHasUnsavedChange } =
	botHistory.actions;

export default undoable(botHistory.reducer, {
	filter: includeAction(['bot/setNodes', 'bot/setEdges', 'bot/updateFlowData', 'bot/setNodesAndEdges', 'bot/onEdgesChange']),
});
