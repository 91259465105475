import { useState, useCallback } from 'react';
import { useAppDispatch } from 'src/app/hooks';

const useTable = (func: any, shouldDispatch?: boolean) => {
	const dispatch = useAppDispatch();
	const [resData, setResData] = useState<any>();
	const [tableData, setTableData] = useState([]);
	const [isChange, setIsChange] = useState(true);

	const [pagin, setPagin] = useState({
		pageSize: 20,
		pageNo: 1,
		totalCount: 0,
	});

	const [loading, setLoading] = useState(true);

	const [searchFormParams, setSearchFormParams] = useState<any>();

	const fetchTableData: any = async (params: any) => {
		setLoading(true);
		setIsChange(true);
		try {
			const res = await ((shouldDispatch ?? true) ? dispatch(func(params)).unwrap() : func(params));
			if (res && res.data) {
				setResData(res.data);
				setPagin(res.data.pagin);
				setTableData(res.data.records);
			}
		} catch (err: any) {
			console.log(err);
		} finally {
			setLoading(false);
			setIsChange(false);
		}
	};

	const refresh = () => {
		fetchTableData({
			...searchFormParams,
			pageNo: pagin.pageNo,
			pageSize: pagin.pageSize,
		});
	};

	const handleSearch = useCallback((params: any) => {
		setPagin({
			...pagin,
			pageNo: 1,
		});
		setSearchFormParams(params);
		fetchTableData({
			pageNo: 1,
			pageSize: pagin.pageSize,
			...params,
		});
	}, []);

	const handleSortSearch = useCallback((params: any) => {
		setPagin({
			...pagin,
			pageNo: 1,
		});
		fetchTableData({
			...searchFormParams,
			pageNo: 1,
			pageSize: pagin.pageSize,
			...params,
		});
	}, []);

	const handlePageChange = (pageNo: number, pageSize: number) => {
		setPagin({
			...pagin,
			pageNo,
			pageSize,
		});
		fetchTableData({
			...searchFormParams,
			pageNo,
			pageSize,
		});
	};

	return {
		tableData,
		refresh,
		loading,
		setLoading,
		handleSearch,
		handlePageChange,
		handleSortSearch,
		pagin,
		searchFormParams,
		setSearchFormParams,
		resData,
		setTableData,
		isChange,
	};
};

export default useTable;
