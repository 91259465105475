import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, checkRegisterForbidden, checkAccount, resend, needUpdatePassword, updateDefaultPassword, verifyMobile, register, sendVcode, registerConfirm, logout, getCurrencyList, sendResetPassword, resetPassword, checkConfirmCode, emailCheck } from './loginAPI';
import { LoginParams, LoginAccountObject, UpdateDefaultPasswordParams, SendVcodeParams, ResetPasswordParams, SendResetPassword, CheckConfirmCodeParams } from './type';
import { RootState } from 'src/app/store';

export interface LoginState {
	activeEmail: string;
}

const initialState: LoginState = {
	activeEmail: '',
};

export const loginAsync = createAsyncThunk('login/login', async (params: LoginParams, { rejectWithValue }) => {
	try {
		const res = await login(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const checkRegisterForbiddenAsync = createAsyncThunk('login/checkRegisterForbidden', async () => {
	const res = await checkRegisterForbidden();
	return res;
});

export const sendResetPasswordAsync = createAsyncThunk('login/sendResetPassword', async (params: SendResetPassword) => {
	const res = await sendResetPassword(params);
	return res;
});

export const registerConfirmAsync = createAsyncThunk('login/registerConfirm', async (params: CheckConfirmCodeParams, { rejectWithValue }) => {
	try {
		const res = await registerConfirm(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const sendVcodeAsync = createAsyncThunk('login/sendVcode', async (params: SendVcodeParams) => {
	const res = await sendVcode(params).catch((err) => err);
	return res;
});

export const verifyMobileAsync = createAsyncThunk('login/verifyMobile', async (params: any) => {
	const res = await verifyMobile(params).catch((err) => err);
	return res;
});

export const registerAsync = createAsyncThunk('login/register', async (params: any, { rejectWithValue }) => {
	try {
		const res = await register(params);
		return res;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const logoutAsync = createAsyncThunk('login/logout', async () => {
	const res = await logout();
	return res;
});

export const loginCheckAccountAsync = createAsyncThunk('login/checkAccount', async (params: LoginAccountObject) => {
	try {
		const response = await checkAccount(params);
		return response.data;
	} catch (err) {
		return err;
	}
});

export const checkConfirmCodeAsync = createAsyncThunk('login/checkConfirmCode', async (params: CheckConfirmCodeParams) => {
	const response = await checkConfirmCode(params);
	return response;
});

export const loginResendAsync = createAsyncThunk('login/resend', async (params: LoginAccountObject, { rejectWithValue }) => {
	try {
		const response = await resend(params);
		return response;
	} catch (e) {
		return rejectWithValue(e);
	}
});

export const resetPasswordAsync = createAsyncThunk('login/resetPassword', async (params: ResetPasswordParams) => {
	const response = await resetPassword(params);
	return response;
});

export const getCurrencyListAsync = createAsyncThunk('login/getCurrencyList', async () => {
	const response = await getCurrencyList();
	return response;
});

export const updateDefaultPasswordAsync = createAsyncThunk('login/updateDefaultPassword', async (params: UpdateDefaultPasswordParams) => {
	const response = await updateDefaultPassword(params);
	return response;
});

export const needUpdatePasswordAsync = createAsyncThunk('login/needUpdatePassword', async () => {
	const response = await needUpdatePassword();
	return response;
});
export const emailCheckAsync = createAsyncThunk('login/emailCheck', async (params: { loginAccount: string }) => {
	const response = await emailCheck(params);
	return response;
});

export const selectActiveEmail = (state: RootState) => state.login.activeEmail;

export const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		updateActiveEmail: (state, action) => {
			state.activeEmail = action.payload;
		},
	},
});

export const { updateActiveEmail } = loginSlice.actions;

export default loginSlice.reducer;
