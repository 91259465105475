import { useIntl } from 'react-intl';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import ItemEditor, { DragHandleContent, style } from '../ItemEditor';
import { ItemTypes } from './ItemTypes';
import ErrorHelperText from 'src/app/components/form/ErrorHelperText';
import { Box, Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
export interface CardProps {
  id: any;
  text: string;
  index: number;
  onValueChange: any;
  onDelete: any;
  error: string;
  sections: any;
  moveCard: any;
  myIndex: any;
  children: any;
  onBlur: any;
  onAddItem: any;
  disabledAdd: boolean;
}
interface DragItem {
  index: number;
  id: string;
  myIndex: any;
  sectionIndex?: number;
  type: string;
}
export const ListWithHandle: FC<CardProps> = ({
  onBlur,
  myIndex,
  sections,
  disabledAdd,
  id,
  onAddItem,
  error,
  text,
  index,
  children,
  onDelete,
  moveCard,
  onValueChange
}) => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [{
    handlerId
  }, drop] = useDrop<DragItem, void, {
    handlerId: any | null;
  }>({
    accept: [ItemTypes.BOX, ItemTypes.LIST],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.myIndex;
      const dragSectionIndex = item.sectionIndex;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragSectionIndex === undefined && dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (dragSectionIndex !== undefined) {
        if (sections[hoverIndex]?.rows?.length) {
          return;
        }
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, dragSectionIndex, item.id);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      if (dragSectionIndex !== undefined) {
        item.myIndex = 0;
        item.mySectionIndex = index;
      } else {
        item.myIndex = hoverIndex;
      }
    }
  });
  const [{
    isDragging
  }, drag, preview] = useDrag({
    type: ItemTypes.BOX,
    item: () => {
      return {
        id,
        index,
        myIndex
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  drop(preview(ref));
  return <div ref={ref} style={{
    ...style,
    opacity
  }} data-handler-id={handlerId}>
			<ItemEditor handle={<div ref={dragRef}>
						<DragHandleContent isDragging={isDragging} />
					</div>} sx={{
      background: '#EFEDF5'
    }} placeholder={formatMessage({
      defaultMessage: 'Click to edit section'
    })} value={text} onBlur={onBlur} onChange={newValue => onValueChange(index, newValue)} onDelete={() => onDelete(index)} />
			{error === 'titleRequired' && <ErrorHelperText>
					{formatMessage({
        defaultMessage: 'Required'
      })}
				</ErrorHelperText>}
			<Box sx={{
      pl: '35px',
      opacity,
      pt: 1
    }}>
				{children}
				<Button color="secondary" sx={{
        mt: 1
      }} disabled={disabledAdd} startIcon={<AddRoundedIcon />} onClick={() => onAddItem(index)}>
					{formatMessage({
          defaultMessage: 'Add row'
        })}
				</Button>
				{error === 'rowRequired' && <ErrorHelperText>
						{formatMessage({
          defaultMessage: 'Please add at least one row'
        })}
					</ErrorHelperText>}
			</Box>
		</div>;
};