import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Box, Table, TableHead, TableCell, TableSortLabel, TableBody, TableRow, TableContainer, IconButton, Collapse, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CustomPagination from './CustomPagination';
import EmptyData from './EmptyData';
import NoSearchData from './NoSearchData';
import { useAppSelector } from 'src/app/hooks';
import { selectIsSearch } from 'src/features/common/commonSlice';
import CustomTooltip from '../form/customTooltip';
import SelectAll, { handleSelectSingle } from '../form/selectall';
import LongTextEllipsis from '../form/LongTextEllipsis';
import { getIsExpand } from 'src/features/setting/settingSlice';
const Row: FunctionComponent<{
  row: any;
  columns: any;
  index: number;
  open: boolean;
  onOpen: any;
  hover?: boolean;
  onSelectClick?: any;
  onTableRowClick?: any;
  selectedId?: string;
  isSelected?: any;
  style?: any;
}> = ({
  row,
  columns,
  open,
  index,
  style,
  selectedId = '',
  onOpen,
  hover = true,
  onSelectClick,
  onTableRowClick,
  isSelected
}) => {
  const handleClick = () => {
    if (!open) {
      onOpen(index);
    } else {
      onOpen();
    }
  };
  const handleTableRowClick = () => {
    if (onTableRowClick) {
      onTableRowClick(row);
      handleClick();
    }
  };
  const handleSelect = (event: any, e: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (onSelectClick) {
      onSelectClick(e);
    }
  };
  return <>
			<TableRow hover={hover} onClick={handleTableRowClick} style={style} sx={{
      background: selectedId === row.id ? '#F5F5F5' : '#fff',
      cursor: onSelectClick || onTableRowClick ? 'pointer' : '',
      '&:hover': {
        '& .multiline-text-ellipsis': {
          backgroundColor: '#F5F5F5'
        }
      }
    }}>
				{Boolean(onSelectClick && columns?.length) && <TableCell key="checkbox" align="center" padding="checkbox" size="small" onClick={event => handleSelect(event, row)}>
						<Checkbox checked={isSelected} />
					</TableCell>}
				{columns.map((column: any, columnIndex: number) => {
        const cellContent = column.format ? column.format(row[column.key], row) : row[column.key];
        if (column?.isCollapse) {
          return <TableCell key={columnIndex}>
								<IconButton aria-label="expand row" size="small" onClick={handleClick}>
									{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							</TableCell>;
        } else {
          const defaultMaxWidth = '500px';
          const cellStyle = typeof cellContent === 'string' ? {
            maxWidth: column.maxWidth || defaultMaxWidth,
            ...(column.style && column.style)
          } : {
            wordWrap: 'break-word',
            minWidth: column.width ? column.width + 'px' : '',
            ...(column.curWidth ? {
              width: column.curWidth + 'px',
              minWidth: column.curWidth + 'px'
            } : {}),
            ...(column.maxWidth ? {
              maxWidth: column.maxWidth + 'px'
            } : {}),
            ...(column.style && column.style)
          };
          return <TableCell onClick={column.onClick || (() => {})} onMouseUp={column.onMouseUp || (() => {})} key={columnIndex} sx={cellStyle}>
								{typeof cellContent === 'string' ? cellContent === '' ? '-' : <LongTextEllipsis variant="body1" tooltip={cellContent} maxWidth={column.maxWidth || defaultMaxWidth}>
											{cellContent}
										</LongTextEllipsis> : cellContent === null || cellContent === undefined ? '-' : <>{cellContent}</>}
							</TableCell>;
        }
      })}
			</TableRow>
			{columns[0]?.isCollapse && <TableRow>
					<TableCell sx={{
        p: 0
      }} colSpan={columns.length}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{columns[0].format('', row)}
						</Collapse>
					</TableCell>
				</TableRow>}
		</>;
};
const SimpleTable: FunctionComponent<{
  columns: any;
  tableData: any;
  pagin?: any;
  height?: any;
  headBg?: string;
  headZIndex?: number;
  onPageChange?: any;
  defaultOpen?: any;
  isManualPagination?: boolean; // 后端是否不支持分页，需要前端手动分页
  hover?: boolean;
  isLoading?: boolean;
  noDataStyle?: any;
  keyWord?: string;
  onSelect?: (e: any) => void; // 非undefined，则表格支持每行选择
  onRequestSort?: (e: any, sortKey: string, sortBy: string) => void;
  singleSort?: boolean;
  isFixed?: boolean;
  isExpand?: boolean;
  isChange?: any;
  tableStyle?: object;
  selectedId?: string;
  onRowClick?: (row: any) => void;
  rowStyle?: any;
  rowKey?: string;
  offset?: number;
  tableContainerStyle?: any;
  paginationStyle?: object;
  pageSizeOptions?: number[];
  newTheme?: boolean;
}> = ({
  defaultOpen,
  columns,
  tableData,
  pagin,
  height = '',
  onPageChange = () => {},
  headBg = '#fff',
  headZIndex = 2,
  isManualPagination = false,
  hover = true,
  tableStyle = {},
  isLoading,
  noDataStyle,
  keyWord,
  selectedId,
  onSelect,
  onRequestSort,
  onRowClick,
  isFixed = false,
  isExpand = false,
  isChange,
  rowStyle,
  rowKey = 'id',
  offset = 0,
  tableContainerStyle = {},
  paginationStyle = {},
  pageSizeOptions,
  newTheme,
  singleSort = false
}) => {
  columns = columns.filter((item: any) => !item.isHide);
  const containerRef: any = useRef();
  const tableRef: any = useRef();
  const scrollBarRef: any = useRef();
  const isSearch = useAppSelector(selectIsSearch);
  const [order, setOrder] = useState<any>({});
  const [open, setOpen] = useState<any>();
  const [containerOffset, setContainerOffset] = useState<number>(0);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const isMenuExpand = useAppSelector(getIsExpand);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (keyWord && onSelect) {
      if (event.target.checked) {
        const newSelected = tableData.map((n: any) => n[keyWord]);
        setSelected(newSelected);
        onSelect(newSelected);
        return;
      }
      onSelect([]);
      setSelected([]);
    }
  };
  const handleSelectClick = (row: any) => {
    if (keyWord && onSelect) {
      const newSelected: readonly string[] = handleSelectSingle(selected, row[keyWord]);
      setSelected(newSelected);
      onSelect(newSelected);
    }
  };
  const handleSort = (e: string) => {
    const isAsc = order[e] && order[e] === 'desc';
    const newOrder = singleSort ? {
      [e]: order[e] ? isAsc ? 'asc' : 'desc' : 'asc'
    } : {
      ...order,
      [e]: order[e] ? isAsc ? 'asc' : 'desc' : 'asc'
    };
    setOrder(newOrder);
    if (onRequestSort) {
      onRequestSort(newOrder, e, order[e] ? isAsc ? 'asc' : 'desc' : 'asc');
    }
  };
  const handlePageChange = (pageNumber: number) => {
    onPageChange(pageNumber, pagin.pageSize);
  };
  const handlePageSizeChange = (e: any) => {
    onPageChange(1, parseInt(e.target.value));
  };
  const rowsPerPage = pagin?.pageSize || 0;
  const page = pagin?.pageNo - 1 || 0;
  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);
  useEffect(() => {
    if (isChange) {
      setOrder({});
      setSelected([]);
      if (onSelect) {
        onSelect([]);
      }
    }
  }, [isChange]);

  // isFixed=true:滚动条固定底部、分页固定底部
  const scrollFunc = (event: any) => {
    const scrollLeft: number = event.target.scrollLeft;
    tableRef.current.parentNode.scrollTo({
      left: scrollLeft
    });
  };
  const tableScrollFunc = (event: any) => {
    const scrollLeft: number = event.target.scrollLeft;
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollTo({
        left: scrollLeft
      });
    }
  };
  useEffect(() => {
    if (tableRef.current) {
      setContainerOffset(containerRef.current.getBoundingClientRect().left - offset);
      const tableScrollLeft = tableRef.current.parentNode.scrollLeft;
      if (tableScrollLeft > 0 && scrollBarRef.current) {
        scrollBarRef.current.scrollTo({
          left: tableScrollLeft
        });
      }
    }
  }, [isFixed, isExpand, isMenuExpand]);
  const [tableScrollWidth, setTableScrollWidth] = useState<number>(0);
  useEffect(() => {
    setTimeout(() => {
      setTableScrollWidth(tableRef.current?.scrollWidth);
    }, 1000);
  }, []);
  return <Box sx={{
    pb: isFixed ? '54px' : 0,
    ...(newTheme ? {
      boxShadow: 'none',
      border: !isLoading ? '1px solid #D7D5DF' : '',
      px: '0px',
      pt: '0px',
      pb: '8px',
      borderRadius: '8px',
      overflow: 'hidden'
    } : {})
  }} ref={containerRef}>
			{!isLoading && <>
					{isFixed && <Box ref={scrollBarRef} onScroll={scrollFunc} sx={{
        width: tableRef.current?.parentNode?.clientWidth,
        height: '8px',
        borderRadius: '8px',
        position: 'fixed',
        bottom: '62px',
        zIndex: 2,
        right: 70,
        overflowX: 'auto'
      }}>
							<Box sx={{
          width: tableScrollWidth > tableRef.current?.scrollWidth ? tableScrollWidth : tableRef.current?.scrollWidth,
          height: '8px',
          borderRadius: '8px'
        }} />
						</Box>}
					{tableData?.length > 0 ? <TableContainer sx={{
        maxHeight: height,
        ...(isFixed && {
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          }
        }),
        ...(newTheme ? {
          '& th': {
            border: 'none !important'
          },
          '& thead tr th': {
            backgroundColor: '#EFEDF5 !important'
          }
        } : {}),
        ...tableContainerStyle
      }} onScroll={tableScrollFunc}>
							<Table stickyHeader sx={{
          minWidth: 650,
          ...tableStyle
        }} aria-label="simple table" ref={tableRef}>
								<TableHead sx={{
            '& th': {
              background: headBg,
              zIndex: headZIndex
            }
          }}>
									<TableRow>
										{Boolean(onSelect && columns?.length) && <TableCell>
												<SelectAll allLen={tableData.length} selected={selected} onAll={handleSelectAllClick} />
											</TableCell>}
										{columns.map((column: any, columnIndex: number) => {
                return <TableCell key={columnIndex} sx={{
                  wordWrap: 'break-word',
                  minWidth: column.width + 'px',
                  ...(column.curWidth ? {
                    width: column.curWidth + 'px'
                  } : {}),
                  ...(column.maxWidth ? {
                    maxWidth: column.maxWidth + 'px'
                  } : {}),
                  ...(column.style && column.style)
                }}>
													{column.sortBy ? <TableSortLabel hideSortIcon={true} direction={order[column.sortBy] ? order[column.sortBy] : 'desc'} onClick={() => handleSort(column.sortBy)}>
															{column.title}
															{column.toolTip && <CustomTooltip tip={column.toolTip} />}
															<Box sx={{
                      position: 'relative',
                      ml: '4px'
                    }}>
																<ArrowDropUpRoundedIcon sx={{
                        color: order[column.sortBy] && order[column.sortBy] === 'asc' ? 'primary.main' : 'rgba(26, 30, 34, 0.3)',
                        position: 'absolute',
                        top: '-15px'
                      }} />
																<ArrowDropDownRoundedIcon sx={{
                        color: order[column.sortBy] && order[column.sortBy] === 'asc' ? 'rgba(26, 30, 34, 0.3)' : 'primary.main',
                        position: 'absolute',
                        top: '-7px'
                      }} />
															</Box>
														</TableSortLabel> : <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
															<LongTextEllipsis sx={{
                      maxWidth: '500px',
                      fontWeight: 'bold'
                    }}>
																{column.title}
															</LongTextEllipsis>
															{column.toolTip && <CustomTooltip tip={column.toolTip} />}
														</Box>}
												</TableCell>;
              })}
									</TableRow>
								</TableHead>
								<TableBody>
									{(isManualPagination ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map((row: any, index: number) => {
              return <Row key={rowKey && row?.[rowKey] ? row?.[rowKey] : index} row={row} columns={columns} open={open === index} index={index} onOpen={(i: any) => setOpen(i)} hover={hover} selectedId={selectedId} isSelected={keyWord && isSelected(row[keyWord])} onTableRowClick={Boolean(onRowClick) && onRowClick} onSelectClick={Boolean(onSelect) && handleSelectClick} style={rowStyle && (typeof rowStyle === 'function' ? rowStyle(row) : rowStyle)} />;
            })}
								</TableBody>
							</Table>
						</TableContainer> : isSearch ? <NoSearchData sx={{
        pb: '67px',
        ...noDataStyle
      }} /> : <EmptyData sx={{
        pt: '107px',
        pb: '67px',
        ...noDataStyle
      }} />}
					{pagin && Boolean(pagin.totalCount > 0) && <CustomPagination sx={{
        ...(newTheme ? {
          paddingRight: '8px'
        } : {}),
        ...paginationStyle
      }} totalCount={pagin.totalCount} pageSize={pagin.pageSize} pageNo={pagin.pageNo} onPageNoChange={handlePageChange} onPageSizeChange={handlePageSizeChange} isFixed={isFixed} isExpand={isExpand} isChange={isChange} offset={containerOffset} pageSizeOptions={pageSizeOptions} />}
				</>}
		</Box>;
};
export default SimpleTable;