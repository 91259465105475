import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { exportWhatsAppFlowLogs, syncWhatsAppFlows, searchWhatsAppFlowsList, getFlowsSubmissionsNum, getWhatsAppFlowInfo, getWhatsAppFlowLogs, getExportTaskStatus, getWhatsAppFlowsTemplateList } from './whatsappFlowsAPI';
import { GetFlowsSubmissionsNumParams, GetWhatsAppFlowInfoParams, SyncWhatsAppFlowsParams } from './type';

interface WhatsappFlowsState {
	[index: string]: any;
}

const initialState: WhatsappFlowsState = {};

export const syncWhatsAppFlowsAsync = createAsyncThunk('whatsappFlows/syncWhatsAppFlows', async (params: SyncWhatsAppFlowsParams) => {
	const res = await syncWhatsAppFlows(params);
	return res;
});

export const getFlowsSubmissionsNumAsync = createAsyncThunk('whatsappFlows/getFlowsSubmissionsNum', async (params: GetFlowsSubmissionsNumParams) => {
	const res = await getFlowsSubmissionsNum(params);
	return res;
});

export const getWhatsAppFlowLogsAsync = createAsyncThunk('whatsappFlows/getWhatsAppFlowLogs', async (params: any) => {
	const res = await getWhatsAppFlowLogs(params);
	return res;
});

export const getWhatsAppFlowInfoAsync = createAsyncThunk('whatsappFlows/getWhatsAppFlowInfo', async (params: GetWhatsAppFlowInfoParams) => {
	const res = await getWhatsAppFlowInfo(params);
	return res;
});

export const searchWhatsAppFlowsListAsync = createAsyncThunk('whatsappFlows/searchWhatsAppFlowsList', async (params: any) => {
	const res = await searchWhatsAppFlowsList(params);
	return res;
});

export const exportWhatsAppFlowLogsAsync = createAsyncThunk('whatsappFlows/exportWhatsAppFlowLogs', async (params: any) => {
	const res = await exportWhatsAppFlowLogs(params);
	return res;
});

export const getExportTaskStatusAsync = createAsyncThunk('whatsappFlows/getExportTaskStatus', async (params: any) => {
	const res = await getExportTaskStatus(params);
	return res;
});

export const getWhatsAppFlowsTemplateListAsync = createAsyncThunk('whatsappFlows/getWhatsAppFlowsTemplateList', async (params: any) => {
	const res = await getWhatsAppFlowsTemplateList(params);
	return res;
});

export const whatsappFlowsSlice = createSlice({
	name: 'whatsappFlows',
	initialState,
	reducers: {},
});

export default whatsappFlowsSlice.reducer;
