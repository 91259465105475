import { createHashRouter, RouterProvider } from 'react-router-dom';
import { rootRouters } from 'src/routes/Routes';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { computeBrandTheme } from './styles/theme';
import { ConfirmProvider } from 'material-ui-confirm';
import confirmDefaultOptions from './styles/confirmDefaultOptions';
import { IntercomProvider } from 'react-use-intercom';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { updateLogoType, getBrandConfigAsync, selectWhatsappEmbeddedSignupConfig, selectBrandConfig, selectSecondaryColor, selectIsOtherBrand } from 'src/features/brand/brandSlice';
import Loading from 'src/app/components/loading';
import { convertStringColorToObj } from 'src/styles/color';
import { fbJsdk } from 'src/utils/fb';
import { fbJsdkForBrand } from 'src/utils/fbForBrand';
import 'react-photo-view/dist/react-photo-view.css';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { getIntlContentAsync, selectIntlMessages, selectLocale } from 'src/features/intl/intlSlice';
import { LogoType } from './features/brand/type';
export const router = createHashRouter(rootRouters);
function App() {
  const dispatch = useAppDispatch();
  const secondaryColor = useAppSelector(selectSecondaryColor);
  const isOtherBrand = useAppSelector(selectIsOtherBrand);
  const brandConfig = useAppSelector(selectBrandConfig);
  const whatsappEmbeddedSignupConfig = useAppSelector(selectWhatsappEmbeddedSignupConfig);
  const checkLogoType = (src: string) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const {
        width,
        height
      } = img;
      if (width === height) {
        dispatch(updateLogoType(LogoType.Square));
      } else if (width > height) {
        if (width / height <= 2.2) {
          dispatch(updateLogoType(LogoType.Small_Width));
        } else {
          dispatch(updateLogoType(LogoType.Width));
        }
      } else {
        dispatch(updateLogoType(LogoType.Height));
      }
    };
  };
  const getBrandConfig = async () => {
    const res = await dispatch(getBrandConfigAsync());
    if (getBrandConfigAsync.fulfilled.match(res)) {
      if (res && res?.payload?.data?.logo) {
        checkLogoType(res?.payload?.data?.logo);
      }
    }
  };
  useEffect(() => {
    localStorage.removeItem('brandLoginUrl');
    getBrandConfig();
  }, [dispatch]);
  useEffect(() => {
    if (brandConfig) {
      if (isOtherBrand && whatsappEmbeddedSignupConfig?.customized) {
        fbJsdkForBrand(whatsappEmbeddedSignupConfig?.appId);
      } else {
        fbJsdk();
      }
    }
  }, [brandConfig]);
  const intlMessages = useAppSelector(selectIntlMessages);
  const locale = useAppSelector(selectLocale);
  useEffect(() => {
    dispatch(getIntlContentAsync({
      language: locale,
      translateContentType: 'DASHBOARD'
    }));
  }, [locale]);
  const theme = useMemo(() => brandConfig ? computeBrandTheme({
    isOtherBrand,
    secondary: convertStringColorToObj(brandConfig?.themeColor0),
    primary: convertStringColorToObj(brandConfig?.themeColor1)
  }) : createTheme(), [isOtherBrand, brandConfig]);
  return secondaryColor ? <ThemeProvider theme={theme}>
			<IntlProvider locale={locale} messages={intlMessages}>
				<SnackbarProvider autoHideDuration={5000}>
					<ConfirmProvider defaultOptions={{
          ...confirmDefaultOptions,
          confirmationText: <FormattedMessage defaultMessage={'Ok'} />,
          cancellationText: <FormattedMessage defaultMessage={'Cancel'} />
        }}>
						<IntercomProvider appId={isOtherBrand ? '' : 'fkl1sb41'}>
							<RouterProvider router={router} />
						</IntercomProvider>
					</ConfirmProvider>
				</SnackbarProvider>
			</IntlProvider>
		</ThemeProvider> : <Loading open fullScreen />;
}
export default App;