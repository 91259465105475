import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ShortUrlInfo, GetBatchShortLinkInfoParams, GetShortLinkClicksByDateParams, GetRemainShortUrlNumberParams, CreateShortLinkParams } from './type';
import { getBatchShortLinkInfo, getShortLinkClicksByDate, getRemainShortUrlNumber, createShortLink } from './shortLinkAPI';
import { RootState } from 'src/app/store';
import { CODE } from 'src/app/const/code';

export interface ShortLinkState {
	allShortUrlInfoList: Array<ShortUrlInfo>;
}

const initialState: ShortLinkState = {
	allShortUrlInfoList: [],
};

export const getBatchShortLinkInfoAsync = createAsyncThunk('shortLink/getBatchShortLinkInfo', async (params: GetBatchShortLinkInfoParams, thunk) => {
	const state: any = thunk.getState();
	const allShortUrlInfoList = state.shortLink.allShortUrlInfoList;
	const shortUrls = params.shortUrls;

	const shortUrlsInfoMemo = [];
	let newShortUrls = [];
	if (allShortUrlInfoList.length) {
		for (let i = 0; i < shortUrls.length; i++) {
			for (let j = 0; j < allShortUrlInfoList.length; j++) {
				if (allShortUrlInfoList[j].url === shortUrls[i]) {
					shortUrlsInfoMemo.push(allShortUrlInfoList[j]);
					break;
				}
				if (j === allShortUrlInfoList.length - 1) {
					newShortUrls.push(shortUrls[i]);
				}
			}
		}
	} else {
		newShortUrls = shortUrls;
	}
	if (newShortUrls.length) {
		const gotShortLinkInfoList = [];
		const res = await getBatchShortLinkInfo({
			shortUrls: newShortUrls,
		});
		if (res && res.data) {
			for (let i = 0; i < res.data.length; i++) {
				if (res.data[i].shortLinkResponse && res.data[i].shortLinkResponse.code === CODE.OK && res.data[i].shortLinkResponse.data) {
					gotShortLinkInfoList.push(res.data[i].shortLinkResponse.data);
				}
			}
		}
		return shortUrlsInfoMemo.concat(gotShortLinkInfoList);
	} else {
		return shortUrlsInfoMemo;
	}
});

export const getRemainShortUrlNumberAsync = createAsyncThunk('shortLink/getRemainShortUrlNumber', async (params: GetRemainShortUrlNumberParams) => {
	const res = await getRemainShortUrlNumber(params);
	return res;
});

export const createShortLinkAsync = createAsyncThunk('shortLink/createShortLink', async (params: CreateShortLinkParams) => {
	const res = await createShortLink(params);
	return res;
});

export const getShortLinkClicksByDateAsync = createAsyncThunk('shortLink/getShortLinkClicksByDate', async (params: GetShortLinkClicksByDateParams) => {
	const res = await getShortLinkClicksByDate(params);
	return res;
});

export const selectAllShortUrlInfoList = (state: RootState) => state.shortLink.allShortUrlInfoList;

export const shortLinkSlice = createSlice({
	name: 'shortLink',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getBatchShortLinkInfoAsync.fulfilled, (state, action) => {
				state.allShortUrlInfoList = action.payload;
			})
			.addCase(createShortLinkAsync.fulfilled, (state, action) => {
				state.allShortUrlInfoList.push(action.payload.data);
			});
	},
});

export default shortLinkSlice.reducer;
