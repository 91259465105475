import { createSlice } from '@reduxjs/toolkit';

export interface OrdersState {
	id: string;
}

const initialState: OrdersState = {
	id: '1',
};

export const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {},
});

export default ordersSlice.reducer;
