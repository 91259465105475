import { Box, Stack, Typography, Button, IconButton, Tooltip, Alert, Collapse } from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import AddSearch from 'src/app/components/form/AddSearch';
import ConfirmTitle from 'src/app/components/confirm/ConfirmTitle';
import LongTooltipText from 'src/app/components/form/LongTooltipText';
import { ShowSideBarIcon, SideBarIcon, SegmentIcon } from 'src/app/icons';
import { store } from 'src/app/store';
import { CODE } from 'src/app/const/code';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { getAllCustomAttrAsync, getAllSegmentAsync, getAllFilterAsync, getContactTableAsync, setCurSeg, setCurTag, selectCurSeg, selectFilterList, addEditSeg, selectAllSegments, updateContactFieldVisibleAsync, removeTagAsync, getBlocklistSumAsync, setBlocklistTotal, selectCurTag } from 'src/features/contact/contactList/contactListSlice';
import { deepCopy, formatDateTime } from 'src/utils/transform';
import Segment from './segment';
import SegmentCreate from './segment/SegmentCreate';
import FilterPopper from './filter/FilterPopper';
import FilterList from './filter/FilterList';
import ContactTable from './table';
import TagColumn from './table/TagColumn';
import ColorChip from 'src/app/components/colorChip';
import AddDialog from './user/AddDialog';
import AddEditUser from './user/AddEditUser';
import IntegrationPlatformSyncStatus from './components/IntegrationPlatformSyncStatus';
import { isEmpty } from 'lodash-es';
import { hasValue } from 'src/utils/tools';
import { tipMap } from './user/components/UserForm';
import { selectUser } from 'src/features/user/userSlice';
import Cookies from 'js-cookie';
import useSafetyIntl from 'src/hooks/useSafetyIntl';
export const systemAttributeDisplayNameMap = defineMessages({
  'Phone number': {
    defaultMessage: 'Phone number'
  },
  Owner: {
    defaultMessage: 'Owner'
  },
  Tag: {
    defaultMessage: 'Tag'
  },
  Country: {
    defaultMessage: 'Country'
  },
  Email: {
    defaultMessage: 'Email'
  },
  'Last contacted ': {
    defaultMessage: 'Last contacted '
  },
  'Create time': {
    defaultMessage: 'Create time'
  },
  Source: {
    defaultMessage: 'Source'
  },
  Nickname: {
    defaultMessage: 'Nickname'
  },
  'Last connected number': {
    defaultMessage: 'Last connected number'
  },
  'Source URL': {
    defaultMessage: 'Source URL'
  },
  'Source ID': {
    defaultMessage: 'Source ID'
  }
});

// 值为空的条件删除
const delEmptyFilter = (data: any) => {
  let newData = deepCopy(data);
  const list = newData.filters;
  if (!isEmpty(list)) {
    list.forEach((i: any, iIndex: number) => {
      const index = i.conditions.findIndex((j: any) => !hasValue(j.value));
      if (index > -1) {
        i.conditions.splice(index);
      }
      if (isEmpty(i.conditions)) {
        list.splice(iIndex);
      }
    });
  }
  if (isEmpty(list)) {
    newData = {};
  }
  return newData;
};
const ContactList = () => {
  const {
    formatMessage: formatMessage
  } = useIntl();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const curSegId = searchParams.get('curSegId');
  const ctwaSource: any = searchParams.get('Ctwa source');
  const condition: any = searchParams.get('condition');
  const allSegments = useAppSelector(selectAllSegments);
  const filterList = useAppSelector(selectFilterList);
  const curSeg = useAppSelector(selectCurSeg);
  const {
    role,
    accountId
  } = useAppSelector(selectUser);
  const curTag = useAppSelector(selectCurTag);
  const tableRef: any = useRef();
  const confirm = useConfirm();
  const [columnData, setColumnData] = useState<Array<any>>([]);
  const [sourceColumnData, setSourceColumnData] = useState<Array<any>>([]);
  const [isAlertClosed, setIsAlertClosed] = useState<boolean>(true); // 默认不展示

  const [isColumnsComputing, setIsColumnsComputing] = useState(false); // 表格的列在计算中，表格也展示loading状态
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [addEditUser, setAddEditUser] = useState<any>({
    type: '',
    open: false,
    item: undefined
  });
  const [sideBarExpand, setSideBarExpand] = useState<boolean>(true);
  const [keyWord, setKeyWord] = useState<string>(condition);
  const [preFilterData, setPreFilterData] = useState<any>({});
  const [filterData, setFilterData] = useState<any>({});
  const [searchFilter, setSearchFilter] = useState<any>({});
  const [isCreateSeg, setIsCreateSeg] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterValue, setFilterValue] = useState<any>();
  const [filterType, setFilterType] = useState<string>('');
  const [filterPosition, setFilterPosition] = useState<any>();
  const [preFilterType, setPreFilterType] = useState<string>('');
  const [isNotOperate, setIsNotOperate] = useState<boolean>(false);
  const hiddenFor = new Set(['SALE']);
  const specialControlForDisplay = () => {
    return !hiddenFor.has(role);
  };
  const fetchAndSetBlocklistTotal = async () => {
    const res = await dispatch(getBlocklistSumAsync()).unwrap();
    store.dispatch(setBlocklistTotal(res.data));
  };
  const handleAddEditUser = (e: string, i?: any) => {
    setAddEditUser({
      type: e,
      open: true,
      item: i && i.contactId ? i : undefined
    });
  };
  const handleCloseUser = () => {
    setAddEditUser({
      type: '',
      open: false,
      item: undefined
    });
  };
  const handleSubmitUser = async () => {
    handleCloseUser();
    if (tableRef && tableRef.current) {
      tableRef.current.refresh();
    }
    await fetchAndSetBlocklistTotal();
  };
  const getTableData = (seg: any, filter: any) => {
    if (tableRef && tableRef.current) {
      tableRef.current.handleSearch({
        ...(!['all', 'blocklist'].includes(seg) && {
          segmentId: seg.id
        }),
        ...(seg === 'blocklist' && {
          blocked: true
        }),
        query: filter
      });
    }
  };
  const getFilterTable = (seg: any, filter: any) => {
    setPreFilterData(filter);
    setFilterData(filter);
    setSearchFilter(filter);
    getTableData(seg, filter);
  };
  const handleSegClick = async (e: any) => {
    if (curSeg === 'blocklist' !== (e === 'blocklist')) {
      await loadColumns(e);
    }
    if (curTag) {
      dispatch(setCurTag(''));
    }
    store.dispatch(setCurSeg(e));
    if (['all', 'blocklist'].includes(e)) {
      setKeyWord('');
      getFilterTable(e, {});
      setIsNotOperate(false);
      await fetchAndSetBlocklistTotal();
    } else {
      setPreFilterData(e.query);
      setFilterData(e.query);
      setSearchFilter(e.query);
      if (role === 'SALE') {
        setIsNotOperate(true);
      }
      const searchParam: any = {
        segmentId: e.id,
        query: e.query,
        pageNo: 1,
        pageSize: 20
      };
      if (tableRef && tableRef.current) {
        tableRef.current.setLoading(true);
        tableRef.current.setSearchFormParams(searchParam);
      }
      const res: any = await dispatch(getContactTableAsync(searchParam)).unwrap();
      tableRef.current.setLoading(false);
      store.dispatch(addEditSeg({
        type: 'edit',
        data: {
          ...e,
          count: res.totalCount
        }
      }));
    }
  };
  const handleTagClick = async (tagName: string, query: any) => {
    if (curSeg === 'blocklist') {
      await loadColumns('tag');
    }
    if (curSeg) {
      dispatch(setCurSeg(''));
    }
    dispatch(setCurTag(tagName));
    setPreFilterData(query);
    setFilterData(query);
    setSearchFilter(query);
    const searchParam: any = {
      query,
      pageNo: 1,
      pageSize: 20
    };
    await dispatch(getContactTableAsync(searchParam)).unwrap();
    tableRef.current.setLoading(false);
  };
  const handleKeywordSearch = (e: any) => {
    const value = e === '' ? e : e.target.value;
    setKeyWord(value);
    if (tableRef && tableRef.current) {
      if (e === '') {
        tableRef.current.handleSearch({
          condition: '',
          ...(curSeg === 'blocklist' && {
            blocked: true
          })
        });
      } else {
        tableRef.current.handleInputSearch(parseInt(e.timeStamp), {
          condition: value,
          ...(curSeg === 'blocklist' && {
            blocked: true
          })
        });
      }
    }
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
    setAnchorEl(null);
    setFilterValue('');
  };
  const handleAddPop = (e: any, item: any) => {
    setAnchorEl(e);
    const targetFilter = filterList.find((m: any) => m.column === item.column);
    setFilterValue({
      ...item,
      ...(targetFilter && {
        type: targetFilter.type
      })
    });
    setFilterType('edit');
    setOpenFilter(true);
  };
  const handleFilterClick = (event: any, i: any, j: string, k: any) => {
    setPreFilterType(j);
    if (j === 'del') {
      handleCloseFilter();
      let newFilter = deepCopy(filterData);
      const list = newFilter.filters;
      const [itemIndex, index] = k.split('-');
      list[itemIndex].conditions.splice(index, 1);
      if (list[itemIndex].conditions.length === 0) {
        list.splice(itemIndex, 1);
      }
      if (list.length === 0) {
        setKeyWord('');
        newFilter = {};
      }
      setFilterData(newFilter);
      if (JSON.stringify(newFilter) !== JSON.stringify(searchFilter)) {
        getTableData(curSeg, newFilter);
      }
      setSearchFilter(newFilter);
    } else {
      if (event) {
        event.stopPropagation();
        if (j === 'edit') {
          const targetFilter = filterList.find((m: any) => m.column === i.column);
          setFilterValue({
            ...i,
            ...(targetFilter && {
              type: targetFilter.type
            })
          });
        } else {
          setFilterValue(i);
        }
        setFilterType(j);
        setFilterPosition(k);
        setAnchorEl(event.currentTarget);
        setOpenFilter(prev => anchorEl !== event.currentTarget || !prev);
      }
    }
  };
  const handleFilterChange = (e: any) => {
    let newFilter = deepCopy(filterData);
    const list = newFilter.filters;
    if (filterType === 'operate') {
      if (filterPosition === 'list') {
        newFilter = {
          ...newFilter,
          connector: e
        };
      }
      if (filterPosition.includes('-')) {
        const [itemIndex] = filterPosition.split('-');
        list[itemIndex].connector = e;
      }
    } else if (filterType === 'add') {
      const newItem = {
        column: e.column,
        type: e.type,
        values: e.values || [],
        value: '',
        operator: e.operators[0]
      };
      if (filterPosition === '') {
        if (list && list.length > 0) {
          list.push({
            connector: list[0].connector,
            conditions: [newItem]
          });
          setFilterPosition(list.length - 1 + '-' + 0);
        } else {
          setFilterData({
            connector: 'AND',
            filters: [{
              connector: 'AND',
              conditions: [newItem]
            }]
          });
          handleCloseFilter();
          setFilterPosition('0-0');
          return;
        }
      }
      if (typeof filterPosition === 'number') {
        list[filterPosition].conditions.push(newItem);
        setFilterPosition(filterPosition + '-' + (list[filterPosition].conditions.length - 1));
      }
    } else if (filterType === 'edit') {
      const [itemIndex, index] = filterPosition.split('-');
      list[itemIndex].conditions[index] = {
        ...list[itemIndex].conditions[index],
        operator: e.operator,
        value: e.value
      };
    }
    setFilterData(newFilter);
    const resultFilter = delEmptyFilter(newFilter);
    if (JSON.stringify(resultFilter) !== JSON.stringify(searchFilter)) {
      if (typeof e === 'string') {
        getTableData(curSeg, resultFilter);
      } else {
        tableRef.current.handleInputSearch(parseInt(e.timeStamp), {
          ...(curSeg !== 'all' && {
            segmentId: curSeg.id
          }),
          ...(curSeg === 'blocklist' && {
            blocked: true
          }),
          query: resultFilter
        });
      }
    }
    setSearchFilter(resultFilter);
    if (filterType === 'operate' || filterType === 'add') {
      handleCloseFilter();
    }
  };
  const isDisabledAdd = (data: any) => {
    let isDisable = false;
    if (isNotOperate) {
      isDisable = true;
      return isDisable;
    }
    const list = data?.filters;
    if (list && list.length > 0) {
      list.forEach((i: any) => {
        i.conditions.forEach((j: any) => {
          if (j.value === '' || !j.value) {
            isDisable = true;
          }
        });
      });
    }
    return isDisable;
  };
  const removeTagSubmit = async (id: string, tag: any) => {
    confirm({
      title: <ConfirmTitle color="error" title={formatMessage({
        defaultMessage: 'Are you sure to delete this tag?'
      })} />,
      content: <>
					{formatMessage({
          defaultMessage: 'The {tag} tag for this person will be removed'
        }, {
          tag: <Typography variant="subtitle2" component="span" sx={{
            wordBreak: 'break-all'
          }}>
									{tag?.tag}
								</Typography>
        })}
				</>
    }).then(async () => {
      const res: any = await dispatch(removeTagAsync({
        contactId: id,
        tag: tag?.id
      })).unwrap();
      if (res.code === CODE.OK) {
        if (tableRef && tableRef.current) {
          tableRef.current.refresh();
        }
      }
    }).catch(() => {});
  };
  const {
    safetyFormatMessage
  } = useSafetyIntl();
  const loadColumns = async (target?: string | undefined) => {
    setIsColumnsComputing(true);
    try {
      const colRes: any = await dispatch(getAllCustomAttrAsync(target === 'blocklist' ? true : undefined)).unwrap();
      const resultColumns: Array<any> = [];
      const nickname = colRes.find((item: any) => item.displayName === 'Nickname');
      if (nickname && nickname.isShown !== false) {
        resultColumns.push({
          key: 'Nickname',
          title: formatMessage({
            defaultMessage: 'Nickname'
          }),
          maxWidth: 140,
          format: (value: any) => !value ? '-' : <LongTooltipText variant="body1" maxLine={2} sx={{
            cursor: 'pointer',
            '&:hover': {
              color: 'secondary.main'
            }
          }}>
								{value}
							</LongTooltipText>
        });
      }
      colRes.forEach((e: any) => {
        let title = e.displayName;
        if (e.displayName !== 'Nickname' && e.isShown !== false) {
          if (!e.isCustomAttr) {
            title = safetyFormatMessage(systemAttributeDisplayNameMap[e.displayName as keyof typeof systemAttributeDisplayNameMap], e.displayName);
          }
          resultColumns.push({
            key: e.displayName,
            title,
            toolTip: e.displayName === 'Owner' && formatMessage(tipMap['owner']),
            maxWidth: e.displayName === 'Country' || e.displayName === 'Phone number' || e.displayName === 'Email' ? 188 : e.displayName === 'Source' || e.displayName === 'Tag' || e.isCustomAttr && e.displayType === 'Array' ? 228 : undefined,
            ...((e.displayName === 'Tag' || e.isCustomAttr && e.displayType === 'Array') && {
              curWidth: 228
            }),
            ...(e.isCustomAttr && {
              maxWidth: 520
            }),
            ...(e.displayName === 'Date Blocked' && {
              sortBy: 'blockTimeDesc'
            }),
            ...(e.displayName === 'Create time' && {
              sortBy: 'creatTimeDesc'
            }),
            ...(e.displayName === 'Last contacted ' && {
              sortBy: 'lastSeenDesc'
            }),
            format: (value: any, row: any) => {
              if (value === null || value === undefined || value?.length === 0) {
                return '-';
              }
              if (e.displayType && e.displayType === 'Time') {
                return value && formatDateTime(value);
              } else if (e.displayName === 'Tag') {
                // 奇怪的数据
                if (typeof value === 'string') {
                  return null;
                }
                return <TagColumn contactId={row.contactId} data={(value || []).filter(Boolean)} onDel={e.displayName === 'Tag' ? removeTagSubmit : undefined} />;
              } else if (e.isCustomAttr && e.displayType === 'Array') {
                if (Array.isArray(value)) {
                  return <Stack direction="row" spacing={1}>
											{value.map(item => <ColorChip label={item} key={item} value={{
                      r: 94,
                      g: 89,
                      b: 106
                    }} size="small" />)}
										</Stack>;
                }
                return null;
              } else if (e.displayType && e.displayType === 'True/False') {
                const valueType = typeof value;
                return valueType === 'string' ? value : valueType === 'boolean' ? String(value).slice(0, 1).toUpperCase() + String(value).slice(1).toLocaleLowerCase() : '';
              } else {
                return value;
              }
            }
          });
        }
      });
      setColumnData(resultColumns);
      setSourceColumnData(colRes);
    } catch (err) {
      console.log(err);
    } finally {
      setIsColumnsComputing(false);
    }
  };
  const handleUpdateColumn = async (keys: string[]) => {
    // update columns
    const params: any = [];
    sourceColumnData.forEach(item => {
      if (keys.includes(item.id)) {
        params.push({
          id: item.id,
          isShown: true
        });
      } else {
        params.push({
          id: item.id,
          isShown: false
        });
      }
    });
    await dispatch(updateContactFieldVisibleAsync(params));
    // reload columns
    loadColumns(curSeg);
  };
  const handleMessageClose = () => {
    setIsAlertClosed(true);
    Cookies.set(`isSaleAlertClosed_${accountId}`, 'true', {
      expires: 365
    });
  };
  useEffect(() => {
    if (curSegId && curSegId !== 'all' && curSeg.id) {
      getFilterTable(curSeg, curSeg.query);
    }
    if (!curSegId && curSeg === 'all' && !ctwaSource && !condition) {
      getFilterTable('all', {});
    }
    if (!curSegId && curSeg === 'blocklist' && ctwaSource) {
      getFilterTable('blocklist', {});
    }
    if (ctwaSource) {
      const queryParams = {
        filters: [{
          conditions: [{
            column: 'Ctwa source',
            operator: 'IS',
            value: ctwaSource.replaceAll('-', ':')
          }],
          connector: 'AND'
        }],
        connector: 'AND'
      };
      setPreFilterData(queryParams);
      setFilterData(queryParams);
      setSearchFilter(queryParams);
      const searchParam: any = {
        query: queryParams,
        pageNo: 1,
        pageSize: 20
      };
      if (tableRef && tableRef.current) {
        tableRef.current.setLoading(true);
        tableRef.current.setSearchFormParams(searchParam);
      }
      dispatch(getContactTableAsync(searchParam)).unwrap().then(() => {
        tableRef.current.setLoading(false);
      });
    }
    if (condition) {
      tableRef.current.handleSearch({
        condition
      });
    }
  }, [curSegId, ctwaSource, condition]);
  useEffect(() => {
    setIsAlertClosed(Cookies.get(`isSaleAlertClosed_${accountId}`) === 'true');
    const initData = async () => {
      if (allSegments.length === 0) {
        const res: any = await dispatch(getAllSegmentAsync()).unwrap();
        if (curSegId && curSegId !== 'all') {
          const target = res.filter((e: any) => e.id === curSegId);
          if (target && target.length > 0) {
            const targetSeg = target[0];
            store.dispatch(setCurSeg(targetSeg));
            getFilterTable(targetSeg, targetSeg.query);
          }
        }
      }
      if (filterList && filterList.length === 0) {
        await dispatch(getAllFilterAsync());
      }
      await loadColumns(curSeg);
      await fetchAndSetBlocklistTotal();
    };
    initData();
    const content = document.getElementById('pageContent');
    if (content) {
      content.addEventListener('click', handleCloseFilter);
    }
    return () => {
      store.dispatch(setCurSeg('all'));
      if (content) {
        content.removeEventListener('click', handleCloseFilter);
      }
    };
  }, []);
  return <Box sx={{
    display: 'flex',
    width: '100%',
    height: '100vh',
    overflow: 'hidden'
  }}>
			<Segment isExpand={sideBarExpand} editable={specialControlForDisplay()} onSegClick={handleSegClick} onTagClick={handleTagClick} />
			{openFilter && <FilterPopper open={openFilter} anchorEl={anchorEl} value={filterValue} type={filterType} preType={preFilterType} onChange={handleFilterChange} onClose={handleCloseFilter} />}
			<Box sx={{
      backgroundColor: 'fill.bg',
      borderRadius: '8px 0px 0px 8px',
      px: 3,
      py: 3,
      boxSizing: 'border-box',
      width: 0,
      flex: 1,
      overflow: 'hidden auto'
    }}>
				{!isAlertClosed && role === 'SALE' && <Collapse in={!isAlertClosed}>
						<Alert severity="info" sx={{
          mb: 2
        }} onClose={handleMessageClose}>
							<FormattedMessage defaultMessage={'You can now view and edit the information of the contacts. You can also add new contacts and create segments.'} />
						</Alert>
					</Collapse>}
				<Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 3
      }}>
					<Box sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 1
        }}>
						<Tooltip arrow title={sideBarExpand ? formatMessage({
            defaultMessage: 'hide sidebar'
          }) : formatMessage({
            defaultMessage: 'show sidebar'
          })}>
							<IconButton onClick={() => setSideBarExpand(!sideBarExpand)}>{sideBarExpand ? <SideBarIcon /> : <ShowSideBarIcon />}</IconButton>
						</Tooltip>
						<Typography variant="h2">
							{curSeg === 'all' ? formatMessage({
              defaultMessage: 'All'
            }) : curSeg === 'blocklist' ? formatMessage({
              defaultMessage: 'Blocklist'
            }) : curSeg.name || curTag}
						</Typography>
						{curSeg === 'blocklist' && <Tooltip arrow title={formatMessage({
            defaultMessage: 'Once added to the Blocklist, you will no longer receive WhatsApp messages from this user in Chatbot and Inbox.'
          })}>
								<HelpRoundedIcon sx={{
              color: 'rgba(26, 30, 34, 0.3)',
              fontSize: '14px',
              ml: 1,
              verticalAlign: 'text-top',
              cursor: 'pointer'
            }}></HelpRoundedIcon>
							</Tooltip>}
					</Box>
					{curSeg !== 'blocklist' && <Button size="large" startIcon={<AddRoundedIcon />} variant="contained" onClick={() => setIsAdd(true)} sx={{}}>
							<FormattedMessage defaultMessage={'New contacts'} />
						</Button>}
				</Box>
				{specialControlForDisplay() && <IntegrationPlatformSyncStatus />}
				{JSON.stringify(filterData) === '{}' ? <AddSearch hasAdd={false} value={keyWord} onChange={handleKeywordSearch} inputStyle={{
        width: '349px',
        mb: 1
      }} placeholder={formatMessage({
        defaultMessage: 'Nickname/Country/Phone number/Source'
      })} /> : <FilterList openFilter={openFilter} isNotOperate={isNotOperate} isDisabledAdd={isDisabledAdd(filterData)} data={filterData} filterType={filterType} preFilterType={preFilterType} filterPosition={filterPosition} onOpenFilter={handleFilterClick} onAddPop={handleAddPop} />}
				<Box sx={{
        mb: 3,
        position: 'relative',
        display: 'flex',
        columnGap: 1
      }}>
					<Button variant="text" color="secondary" startIcon={<AddRoundedIcon />} disabled={isDisabledAdd(filterData)} onClick={event => handleFilterClick(event, '', 'add', '')} sx={{
          backgroundColor: 'transparent !important'
        }}>
						<FormattedMessage defaultMessage={'Add filter'} />
					</Button>
					{(JSON.stringify(preFilterData) !== JSON.stringify(filterData) || curTag) && JSON.stringify(filterData) !== '{}' && specialControlForDisplay() && curSeg !== 'blocklist' && <Button variant="text" color="secondary" startIcon={<SegmentIcon />} disabled={isDisabledAdd(filterData)} onClick={() => setIsCreateSeg(true)} sx={{
          backgroundColor: 'transparent !important'
        }}>
							<FormattedMessage defaultMessage={'Save segment'} />
						</Button>}
				</Box>
				<ContactTable ref={tableRef} columnsComputing={isColumnsComputing} columnData={columnData} sourceColumnData={sourceColumnData} isExpand={sideBarExpand} onEditUser={handleAddEditUser} onUpdateColumn={handleUpdateColumn} isSale={role !== 'SALE'} />
			</Box>
			<SegmentCreate open={isCreateSeg} filterData={filterData} onReload={handleSegClick} onClose={() => setIsCreateSeg(false)} />
			<AddDialog open={isAdd} onOneUser={handleAddEditUser} onClose={() => setIsAdd(false)} />
			<AddEditUser type={addEditUser.type} open={addEditUser.open} item={addEditUser.item} curSeg={curSeg} onClose={handleCloseUser} onSubmit={handleSubmitUser} />
		</Box>;
};
export default ContactList;