import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSendCountryList, getSmsOverviewSummary, getSmsLogs, getSmsOverviewData } from './smsAnalyticsAPI';
import { GetSendCountryListParams, GetSmsOverviewSummaryParams, GetSmsOverviewParams } from './type';

export interface SmsAnalyticsState {
	[index: string]: any;
}

const initialState: SmsAnalyticsState = {};

export const getSendCountryListAsync = createAsyncThunk('user/getSendCountryList', async (params: GetSendCountryListParams) => {
	const response = await getSendCountryList(params);
	return response.data;
});

export const getSmsLogsAsync = createAsyncThunk('user/getSmsLogs', async (params: any) => {
	const response = await getSmsLogs(params);
	return response.data;
});

export const getSmsOverviewSummaryAsync = createAsyncThunk('user/getSmsOverviewSummary', async (params: GetSmsOverviewSummaryParams) => {
	const response = await getSmsOverviewSummary(params);
	return response.data;
});

export const getSmsOverviewDataAsync = createAsyncThunk('user/getSmsOverviewData', async (params: GetSmsOverviewParams) => {
	const response = await getSmsOverviewData(params);
	return response.data;
});

export const smsAnalyticsSlice = createSlice({
	name: 'smsAnalytics',
	initialState,
	reducers: {},
});

export default smsAnalyticsSlice.reducer;
