import { useIntl } from 'react-intl';

export default function useSafetyIntl() {
	const { formatMessage } = useIntl();

	// 避免出现因为找不到ID而导致的报错，适用于对象的属性值
	const safetyFormatMessage = (id: any, defaultMessage?: any) => {
		try {
			return formatMessage(id);
		} catch (err) {
			console.log(err);
			return defaultMessage;
		}
	};

	return {
		safetyFormatMessage,
	};
}
