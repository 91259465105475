import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BrandConfig, DistributionType, LogoType } from './type';
import { getBrandConfig } from './brandAPI';
import { RootState } from 'src/app/store';

function changeFavicon(url: string) {
	const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = 'shortcut icon';
	link.href = url;

	document.getElementsByTagName('head')[0].appendChild(link);
}

// 修改title
const changeTitle = (title: string) => {
	document.title = title;
};

export interface BrandState {
	config?: BrandConfig;
	logoType: LogoType;
}

const initialState: BrandState = {
	config: undefined,
	logoType: LogoType.Width,
};

export const getBrandConfigAsync = createAsyncThunk('brand/getBrandConfig', async () => {
	const res = await getBrandConfig();
	return res;
});

export const brandSlice = createSlice({
	name: 'brand',
	initialState,
	reducers: {
		updateLogoType: (state, action) => {
			state.logoType = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getBrandConfigAsync.fulfilled, (state, action) => {
			if (!action.payload.data) {
				const name = 'YCloud';
				const favicon = `${process.env.ASSET_PREFIX}/favicon.ico`;

				state.config = {
					loginUrl: '/entry/login',
					name,
					favicon,
					logo: '',
					squareLogo: '',
					themeColor0: '96,45,237',
					themeColor1: '26,30,34',
					whatsappEmbeddedSignupConfig: {
						customized: false,
						appId: '',
						configId: '',
						solutionId: '',
					},
				};
				changeTitle(name);
				changeFavicon(favicon);
			} else {
				state.config = action.payload.data;
				// 贴牌
				if (action.payload.data?.favicon) {
					changeFavicon(action.payload.data?.favicon);
				}
				if (action.payload.data?.name) {
					changeTitle(action.payload.data?.name);
				}
				if (action.payload.data?.loginUrl) {
					localStorage.setItem('brandLoginUrl', action.payload.data?.loginUrl);
				} else {
					localStorage.removeItem('brandLoginUrl');
				}
			}
		});
	},
});

// 页面的二级色，对应ycloud紫色
export const selectSecondaryColor = (state: RootState) => state.brand.config?.themeColor0;

// 页面的主色，对应ycloud黑色
export const selectPrimaryColor = (state: RootState) => state.brand.config?.themeColor1;

export const selectLogo = (state: RootState) => state.brand.config?.logo;
export const selectSquareLogo = (state: RootState) => state.brand.config?.squareLogo;

// 是否是贴牌的厂商
export const selectIsOtherBrand = (state: RootState) => state.brand.config?.distribution === DistributionType.Partner;

export const selectLoginUrl = (state: RootState) => state.brand.config?.loginUrl;

export const selectBrandName = (state: RootState) => state.brand.config?.name;

export const selectBrandConfig = (state: RootState) => state.brand.config;

export const selectWhatsappEmbeddedSignupConfig = (state: RootState) => state.brand.config?.whatsappEmbeddedSignupConfig;

export const selectLogoType = (state: RootState) => state.brand.logoType;

export const { updateLogoType } = brandSlice.actions;

export default brandSlice.reducer;
