import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { WebhookParams, WebhookActionParams, FailedLogParams } from './type';
import { getListAllEvents, getWebhookList, addWebhook, updateWebhook, enableWebhook, disableWebhook, getEventTypeList, delWebhook, getWebhookLogs, getFailedLog } from './developersAPI';

export interface WebhookState {
	eventsOptions: Array<any>;
}

const initialState: WebhookState = {
	eventsOptions: [],
};

export const getSelectOptions = (arr: Array<any>) => {
	const options: Array<any> = [];
	arr.forEach((m: any) => {
		const {
			type,
			category: { displayName },
			description,
		} = m;
		const displayNames = options.map((item) => item.label);
		const current = { value: type, label: type, description };

		if (displayNames.includes(displayName)) {
			const index = options.findIndex((e) => e.label === displayName);
			options[index].options.push(current);
		} else {
			options.push({ label: displayName, options: [current] });
		}
	});
	return options;
};

export const getWebhookLogsAsync = createAsyncThunk('webhook/getWebhookLogs', async (params: any) => {
	const res = await getWebhookLogs(params);
	return res;
});

export const getEventTypeListAsync = createAsyncThunk('webhook/getEventTypeList', async (params: any) => {
	const res = await getEventTypeList(params);
	return res;
});

export const getWebhookListAsync = createAsyncThunk('webhook/getWebhookList', async () => {
	const res = await getWebhookList();
	return res;
});

export const getListAllEventsAsync = createAsyncThunk('webhook/getListAllEvents', async () => {
	const res = await getListAllEvents();
	return res;
});

export const addWebhookAsync = createAsyncThunk('webhook/addWebhook', async (params: WebhookParams) => {
	const res = await addWebhook(params);
	return res;
});

export const updateWebhookAsync = createAsyncThunk('webhook/updateWebhook', async (params: WebhookParams) => {
	const res = await updateWebhook(params);
	return res;
});

export const enableWebhookAsync = createAsyncThunk('webhook/enableWebhook', async (params: WebhookActionParams) => {
	const res = await enableWebhook(params);
	return res;
});

export const disableWebhookAsync = createAsyncThunk('webhook/disableWebhook', async (params: WebhookActionParams) => {
	const res = await disableWebhook(params);
	return res;
});

export const delWebhookAsync = createAsyncThunk('webhook/delWebhook', async (params: WebhookActionParams) => {
	const res = await delWebhook(params);
	return res;
});

export const getFailedLogAsync = createAsyncThunk('webhook/getFailedLog', async (params: FailedLogParams) => {
	const res = await getFailedLog(params);
	return res;
});

export const selectEventsOptions = (state: RootState) => state.developers.eventsOptions;

export const developersSlice = createSlice({
	name: 'developers',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getListAllEventsAsync.fulfilled, (state, action) => {
			const data = action.payload.data;
			if (data.length > 0) {
				const options: Array<any> = getSelectOptions(data);
				state.eventsOptions = options;
			}
		});
	},
});

export default developersSlice.reducer;
